// src/components/PermissionWrapper.jsx

import React from "react";
import { getUserPermissions, hasPermission } from "../utils/advance_permissions";

const PermissionWrapper = ({ title, action, children }) => {
    const userPermissions = getUserPermissions();

    // Check for the action's permission (e.g., create, update, delete, view)
    const isAllowed = userPermissions.some((role) =>
        hasPermission(role.permissions, title, action)
    );

    return isAllowed ? <>{children}</> : null; // Render children if allowed, otherwise render nothing
};

export default PermissionWrapper;
