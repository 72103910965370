import React, { useEffect, useState } from "react";
import ProgressComponent from "../../components/general/ProgressComponent";
import StatusUpdated from "../../components/general/StatusUpdated";
import { GET_ALL_STAFF_OVERVIEW_URL, ADMIN_ID } from "../../env";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import {
  getRecords,
  isSuccess,
  catchAsync,
  filterRecordsByCurrentMonthAndLabel,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import OverviewTaskLists from "../../pages/staff_records/OverviewTaskLists";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";

function StaffOverviewBlocks() {
  const [userData, setUserData] = useState([]);
  const [overviewTaskData, setOverviewTaskData] = useState();

  const [upComing, setUpComing] = useState([]);
  const [due, setDue] = useState([]);
  const [urgent, setUrgent] = useState([]);

  // Fetch all records
  const fetchAllRecords = catchAsync(async () => {
    const apiQuery = new URLSearchParams({
      staffUserId: ADMIN_ID
    }).toString();
    // console.log("🚀 ~ fetchAllRecords ~ apiQuery:", apiQuery)
    const response = await fetch(`${GET_ALL_STAFF_OVERVIEW_URL}?${apiQuery}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhIjp0cnVlLCJlIjoiamFtc2hhaWRzYWJpcjQxMTk4MEBnbWFpbC5jb20iLCJkIjoiNjM5ZDk2NTQwYjg5YzIxZDU0MjFkZmMxIiwicCI6Ii91cGxvYWRzL2RwLzE2NzEyNzIwMjA2OTEtMy5qcGciLCJyIjoiX2EiLCJpYXQiOjE2NzE2NDgxMDd9.woISb4KszBypN8cLGRWIdvPzBpA-R_M6gemg8r4FSh4',  // replace this with your actual token
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    // console.log("🚀 ~ fetchAllRecords ~ response data:", data);
    setUserData(data?.data || []);  // Adjust based on the structure of the response
  });


  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    if (userData) {
      // Calculate totals
      const totalUpcoming = Object.values(userData).reduce(
        (sum, section) => sum + (section.upcoming?.length || 0),
        0
      );

      const totalDue = Object.values(userData).reduce(
        (sum, section) => sum + (section.due?.length || 0),
        0
      );

      const totalUrgent = Object.values(userData).reduce(
        (sum, section) => sum + (section.urgent?.length || 0),
        0
      );

      // Set state
      setUpComing(totalUpcoming || "0");
      setDue(totalDue || "0");
      setUrgent(totalUrgent || "0");
    }
    // console.log("🚀 ~ StaffOverviewBlocks ~ userData:", userData);
  }, [userData]);



  const [showAddMedication, setShowAddMedication] = useState(false);
  const [taskType, setTaskType] = useState();
  // console.log("🚀 ~ ClaimedShifts ~ claimedShiftData:", claimedShiftData)
  const subModalAddMedicine = (taskTypeData) => {
    setShowAddMedication(!showAddMedication);
    setTaskType(taskTypeData)
    setOverviewTaskData(userData)

  };
  return (
    <>
      <div className="row">
        <div className="col-md-4 col-sm-4">
          <div className="stafOverviewBlocksContent greenContent cursor-pointer" onClick={() => subModalAddMedicine("Upcoming Tasks")}>
            <div className="description">
              <h4>
                <small>This Month</small>
                Upcoming Tasks
              </h4>
            </div>
            <div className="contentNumber">
              {upComing}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-4">
          <div className="stafOverviewBlocksContent warningContent cursor-pointer" onClick={() => subModalAddMedicine("Due Tasks")}>
            <div className="description">
              <h4>
                <small>This Month</small>
                Due Tasks
              </h4>
            </div>
            <div className="contentNumber">
              {due}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-4">
          <div className="stafOverviewBlocksContent dangerContent cursor-pointer" onClick={() => subModalAddMedicine("Urgent Tasks")}>
            <div className="description">
              <h4>
                <small>This Month</small>
                Urgent Tasks
              </h4>
            </div>
            <div className="contentNumber">
              {urgent}
            </div>
          </div>
        </div>
      </div>

      {/* Display Task Lists */}
      <Modal
        show={showAddMedication}
        onHide={subModalAddMedicine}
        className="ftcModalPopup medicineInventoryStock"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{taskType}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
          <OverviewTaskLists overviewTaskData={overviewTaskData} taskType={taskType} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StaffOverviewBlocks;
