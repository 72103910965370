import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import Layout from "../../components/layouts/Layout";
import editBlock from "../../assets/images/icons/editBlock.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import duplicateIcon from "../../assets/images/icons/duplicateIcon.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import useColumnsWithPermission from "../../permissions/useColumnsWithPermission";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_ALL_ROTA_TEMPLATES_URL,
  PUT_UPDATE_ROTA_TEMPLATE_URL,
  ADMIN_ID,
} from "../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
  updateRecords
} from "../../helper/helper";
import EditRotaTemplate from "./EditRotaTemplate";
import AddRotaTemplate from "./AddRotaTemplate";
import moment from "moment";
import DuplicateRotaTemplate from "./DuplicateRotaTemplate";
import { useNavigate } from "react-router-dom";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";
import { hasAnyPermissions } from "../../permission_advance/PermissionColumn";

function RotaTemplateList() {

  // Use Navigation
  const navigate = useNavigate();
  const redirectToUserScheduling = (row) => {
    localStorage.setItem(
      "RotaTemplateSchedule",
      JSON.stringify(row)
    );
    navigate(("/rota-users-schedule"))
  }

  const [userData, setUserData] = useState([]);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { isDelete: false },
    },
  };
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [tempIdData, setTempIdData] = useState({
    tempId: "",
    isDelete: true
  });
  const [rotaStockDetail, setRotaStockDetail] = useState();
  const [isRotaTemplateStock, setIsRotaTemplateStock] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [todayDate, setTodayDate] = useState(null);

  useEffect(() => {
    setTodayDate(moment().valueOf());
  }, []);

  // Modal Popup
  const [showAddRota, setShowAddRota] = useState(false);
  const subModalAddRotaTemplate = () => {
    setShowAddRota(!showAddRota);
    fetchAllRecords();
  };

  const [showDeleteRota, setShowDeleteRota] = useState(false);
  const subModalDeleteRota = (delTempId) => {
    setTempIdData({ tempId: delTempId, isDelete: true });
    setShowDeleteRota(!showDeleteRota);
    fetchAllRecords();
  };

  const [showEditRota, setShowEditRota] = useState(false);
  const subModalEditRota = (emarItem) => {
    setRotaStockDetail(emarItem);
    setShowEditRota(!showEditRota);
    fetchAllRecords();
  };

  const [showDuplicateRota, setShowDuplicateRota] = useState(false);
  const subModalDuplicateRota = (emarItem) => {
    setRotaStockDetail(emarItem);
    setShowDuplicateRota(!showDuplicateRota);
    fetchAllRecords();
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const rotaStock = data?.data?.scheduleTemplates?.map((item) => item);
      console.log("🚀 ~ processFetchRecords ~ datadatadatadatadata:", data)
      setUserData(rotaStock);
      setFilterSearch(rotaStock);
      setIsLoading(false);
      // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsRotaTemplateStock(false);
      }, 2000);
    }
    // else {
    //   console.log({ data });
    //   toastErrorMessages(toast.error, data?.message, 1000);
    // }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_ROTA_TEMPLATES_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ apiQuery:", apiQuery)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Table Columns

  const columns = [
    {
      name: "#",
      selector: (row, index) => (
        <div onClick={() => { redirectToUserScheduling(row) }} className="curSorPointer pointerEvent-none text-center">{index + 1}</div>
      ),
      width: "5%",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => {
        return (
          <div onClick={() => { redirectToUserScheduling(row) }} className="curSorPointer">
            {row?.rotaTemplateTitle}
          </div>
        );
      },
      // width: "25%",
    },
    {
      name: "Weeks",
      selector: (row) => {
        return (
          <div
            onClick={() => { redirectToUserScheduling(row) }}
            className="curSorPointer text-center"
          >
            {row?.templateWeeks}
          </div>
        );
      },
      width: "10%",
      center: true,
    },
    {
      name: "Start Date",
      selector: (row) => {
        return (
          <div
            onClick={() => { redirectToUserScheduling(row) }}
            className="curSorPointer text-center"
          >
            {
              (row?.startDate === 0 || row?.startDate === null) ? (
                "-"
              ) : (
                moment(row?.startDate).format("DD/MM/YYYY")
              )
            }

          </div>
        );
      },
      width: "15%",
      center: true,
    },
    {
      name: "End Date",
      selector: (row) => {
        return (
          <div
            onClick={() => { redirectToUserScheduling(row) }}
            className="curSorPointer text-center"
          >
            {
              (row?.endDate === 0 || row?.endDate === null) ? (
                "-"
              ) : (
                moment(row?.endDate).format("DD/MM/YYYY")
              )
            }

          </div>
        );
      },
      width: "15%",
      center: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div
            onClick={() => { redirectToUserScheduling(row) }}
            className={`curSorPointer statusButton ${row?.status}`}
          >
            <span className="statusText">
              {row?.status &&
                row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1)}
            </span>
          </div>
        );
      },
      width: "15%",
      center: true,
    },

    // Conditionally render the "Action" column
    ...(hasAnyPermissions(["create", "update", "delete"], "Roster")
      ? [
        {
          name: "Action",
          selector: (row) => {
            return (
              <div className="actionColumns">
                <PermissionWrapper title="Roster" action="update">
                  <span
                    className="edit"
                    onClick={() => {
                      subModalEditRota(row);
                    }}
                  >
                    <img src={editBlock} alt="Edit" title="Edit" />
                  </span>

                </PermissionWrapper>
                <PermissionWrapper title="Roster" action="create">
                  <span
                    className="duplicate"
                    onClick={() => {
                      subModalDuplicateRota(row);
                    }}
                  >
                    <img src={duplicateIcon} alt="Duplicate" title="Duplicate" />
                  </span>
                </PermissionWrapper>
                <PermissionWrapper title="Roster" action="delete">
                  <span
                    className="delete"
                    onClick={() => {
                      subModalDeleteRota(row?._id);
                    }}
                  >
                    <img src={eventDel} alt="Delete" title="Delete" />
                  </span>
                </PermissionWrapper>
              </div>
            );
          },
          width: "15%",
          center: true,
        },
      ]
      : []),


  ];
  // Search Filter
  useEffect(() => {
    const result = userData.filter((user) => {
      return user.rotaTemplateTitle.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, userData]);

  // Delete RotaTemplate records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (tempIdData !== "") {
      const response = await deleteRecords(
        PUT_UPDATE_ROTA_TEMPLATE_URL,
        tempIdData
      );
      console.log("🚀 ~ deleteRecord ~ tempIdData:", tempIdData)
      console.log("🚀 ~ deleteRecord ~ response:", response)
      setIsRotaTemplateStock(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteRota(!showDeleteRota);
    } else {
      setShowDeleteRota(!showDeleteRota);
      setIsSubmitButtonClicked(false);
      setIsRotaTemplateStock(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }

  });
  useEffect(() => {

    setTimeout(() => {
      setIsRotaTemplateStock(false);
    }, 2000);
  }, [isRotaTemplateStock])


  // Update Record from Future to Active if start date is equal to today date
  const updateFutureStatusRecord = catchAsync(async (templateId, status) => {
    const updateValues = {
      tempId: templateId,
      status: status
    };
    const response = await updateRecords(PUT_UPDATE_ROTA_TEMPLATE_URL, updateValues);
    console.log("🚀 ~ updateFutureStatusRecord ~ response:", response)

    // Optional: Handle the response or errors if needed
    if (response?.status === isSuccess) {
      fetchAllRecords();
    }
  });

  // Status update from future to active
  useEffect(() => {
    // Ensure updateFutureStatusRecord is called only once when the component mounts
    if (todayDate && userData.length > 0) {
      userData.forEach((row) => {
        if (row?.startDate <= todayDate && row?.status === "future") {
          updateFutureStatusRecord(row?._id, "active");
        }
      });
    }
  }, [todayDate, userData]);

  // Status Update from active to inactive
  useEffect(() => {
    // Ensure updateFutureStatusRecord is called only once when the component mounts
    if (todayDate && userData.length > 0) {
      userData.forEach((row) => {
        if ((row?.endDate < todayDate && row?.endDate !== 0 && row?.endDate !== null) && row?.status === "active") {
          updateFutureStatusRecord(row?._id, "Inactive");
        }
      });
    }
  }, [todayDate, userData]);

  return (
    <>
      <Layout pageTitleText="Rota Templates">
        <div className="rowClickUnset">
          <div className="mainContent">

            <div className="dataTableWrapper">
              <DataTable
                className="maxWidthTable"
                columns={columns}
                data={filterSearch}
                pagination
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationPerPage={10}
                fixedHeader
                fixedHeaderScrollHeight="520px"
                highlightOnHover
                actions={
                  <>
                    <PermissionWrapper title="Roster" action="create">
                      <span
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          subModalAddRotaTemplate(filterSearch);
                        }}
                      >
                        Add Template
                      </span>
                    </PermissionWrapper>
                  </>
                }
                responsive={true}
                subHeader={true}
                subHeaderComponent={
                  <div className="searchContainer">
                    <div className="searchTextBox">
                      <input
                        type="text"
                        placeholder="Type your search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                }
                subHeaderAlign="left"
                subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                progressPending={isLoading}
                progressComponent={<ProgressComponent />}
                onRowClicked={redirectToUserScheduling}
              />
            </div>
          </div>
        </div>
      </Layout>

      {/* Add Rota Stock */}

      <Modal
        show={showAddRota}
        onHide={subModalAddRotaTemplate}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Template - Rota</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <AddRotaTemplate
            subModalAddRotaTemplate={subModalAddRotaTemplate}
          />
        </Modal.Body>
      </Modal>


      {/* Edit Rota Stock */}

      <Modal
        show={showEditRota}
        onHide={subModalEditRota}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Template - Rota</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <EditRotaTemplate
            subModalEditRota={subModalEditRota}
            rotaStockDetail={rotaStockDetail}
          />
        </Modal.Body>
      </Modal>


      {/* Duplicate Rota Stock */}

      <Modal
        show={showDuplicateRota}
        onHide={subModalDuplicateRota}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Template - Rota</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <DuplicateRotaTemplate
            subModalDuplicateRota={subModalDuplicateRota}
            rotaStockDetail={rotaStockDetail}
          />
        </Modal.Body>
      </Modal>


      {/* Delete Rota Stock */}

      <Modal
        show={showDeleteRota}
        onHide={subModalDeleteRota}
        className="ftcModalPopup confirmationPopup deletionConfirmation"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="editBlockContent text-center">
            <img src={eventDel} alt={eventDel} />
            <p>Are you sure you want to delete this template?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSubmitButtonClicked ? (
            <Button className="btn-sm" variant="danger" disabled>
              Loading...
            </Button>
          ) : (
            <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
              Yes
            </Button>
          )}

          <Button
            className="btn-sm"
            variant="outline-secondary"
            onClick={subModalDeleteRota}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>


      {isRotaTemplateStock && <ToastContainer />}
    </>
  );
}


export default RotaTemplateList