import React, { useState } from "react";
import Layout from "../../../components/layouts/Layout";
import OptionsBlocks from "../../../components/general/OptionsBlocks";
import CommonOptionsBlocks from "../../../components/general/CommonOptionsBlocks";
import Activities from "../../../assets/images/icons/careplan-options/Activities.svg";
import BathorShower from "../../../assets/images/icons/careplan-options/BathorShower.svg";
import BowelMovements from "../../../assets/images/icons/careplan-options/BowelMovements.svg";
import Creams from "../../../assets/images/icons/careplan-options/Creams.svg";
import FluidIntake from "../../../assets/images/icons/careplan-options/FluidIntake.svg";
import FoodIntake from "../../../assets/images/icons/careplan-options/FoodIntake.svg";
import GeneralNote from "../../../assets/images/icons/careplan-options/GeneralNote.svg";
import MedicalVisit from "../../../assets/images/icons/careplan-options/MedicalVisit.svg";
import MoreOption from "../../../assets/images/icons/careplan-options/MoreOption.svg";
import otherIcons from "../../../assets/images/icons/careplan-options/otherIcons.svg";
import RecordanIncident from "../../../assets/images/icons/careplan-options/RecordanIncident.svg";

import health01 from "../../../assets/images/icons/careplan-options/health01.svg";
import health02 from "../../../assets/images/icons/careplan-options/health02.svg";
import health03 from "../../../assets/images/icons/careplan-options/health03.svg";
import health04 from "../../../assets/images/icons/careplan-options/health04.svg";

import general01 from "../../../assets/images/icons/careplan-options/general01.svg";
import general02 from "../../../assets/images/icons/careplan-options/general02.svg";
import general03 from "../../../assets/images/icons/careplan-options/general03.svg";
import general04 from "../../../assets/images/icons/careplan-options/general04.svg";

import mental01 from "../../../assets/images/icons/careplan-options/mental01.svg";
import mental02 from "../../../assets/images/icons/careplan-options/mental02.svg";
import mental03 from "../../../assets/images/icons/careplan-options/mental03.svg";
import mental04 from "../../../assets/images/icons/careplan-options/mental04.svg";

import professional01 from "../../../assets/images/icons/careplan-options/professional01.svg";
import professional02 from "../../../assets/images/icons/careplan-options/professional02.svg";
import professional03 from "../../../assets/images/icons/careplan-options/professional03.svg";
import professional04 from "../../../assets/images/icons/careplan-options/professional04.svg";
import professional05 from "../../../assets/images/icons/careplan-options/professional05.svg";

import independant01 from "../../../assets/images/icons/careplan-options/independant01.svg";
import independant02 from "../../../assets/images/icons/careplan-options/independant02.svg";
import independant03 from "../../../assets/images/icons/careplan-options/independant03.svg";
import independant04 from "../../../assets/images/icons/careplan-options/independant04.svg";
import independant05 from "../../../assets/images/icons/careplan-options/independant05.svg";
import independant06 from "../../../assets/images/icons/careplan-options/independant06.svg";
import independant07 from "../../../assets/images/icons/careplan-options/independant07.svg";

import personalCare01 from "../../../assets/images/icons/careplan-options/personalCare01.svg";
import personalCare02 from "../../../assets/images/icons/careplan-options/personalCare02.svg";
import personalCare03 from "../../../assets/images/icons/careplan-options/personalCare03.svg";

import PersonalCare from "../../../assets/images/icons/careplan-options/PersonalCare.svg";
import mentalWellbeingIcon from "../../../assets/images/icons/careplan-options/mentalWellbeingIcon.svg";
import generalIcon from "../../../assets/images/icons/careplan-options/generalIcon.svg";
import healthIcon from "../../../assets/images/icons/careplan-options/healthIcon.svg";
import independentLivingIcon from "../../../assets/images/icons/careplan-options/independentLivingIcon.svg";
import Weight from "../../../assets/images/icons/careplan-options/Weight.svg";
import WellbeingCheck from "../../../assets/images/icons/careplan-options/WellbeingCheck.svg";
import { useNavigate } from "react-router-dom";

import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
// Health
//  - InjuryHealthIssue
//  - WeightHeight
//  - HealthAppointments
//  - Observations
// General
//  - GeneralNote
//  - Activities
//  - Sleep
//  - Safeguarding
// PersonalCare
//  - Toileting
//  - ShoweringBath
//  - MouthHygiene
// MentalWellbeing
//  - MoodRating
//  - ABC
//  - PhysicalIntervention
//  - ConsentCapacityMCADOLS
// ProfessionalFamilyInteractions
//  - Meetings
//  - TelephoneCalls
//  - Reviews
//  - Emails
//  - AllOtherInteractions
// IndependentLiving
//  - Laundry
//  - Cooking
//  - NutritionHydration
//  - Cleaning
//  - Finance
//  - PublicInteraction
//  - Education
// MedicationAdministration

function CommonOptions({ getUserRecord }) {
  const [commonSelections, setCommonSelections] = useState(true);
  const [healthSelections, setHealthSelections] = useState(false);
  const [generalSelections, setGeneralSelections] = useState(false);
  const [personalCareSelections, setPersonalCareSelections] = useState(false);
  const [mentalWellbeingSelections, setMentalWellbeingSelections] = useState(false);
  const [professionalFamilyInteractionsSelections, setProfessionalFamilyInteractionsSelections] = useState(false);
  const [independentLivingSelections, setIndependentLivingSelections] = useState(false);
  const [medicationAdministrationSelections, setMedicationAdministrationSelections] = useState(false);

  const navigate = useNavigate();
  const handleRowClickStaffMember = (url) => {
    navigate(url);
  };

  const handleSelection = (category) => {
    setCommonSelections(category === 'common');
    setHealthSelections(category === 'health');
    setGeneralSelections(category === 'general');
    setPersonalCareSelections(category === 'personalCare');
    setMentalWellbeingSelections(category === 'mentalWellbeing');
    setProfessionalFamilyInteractionsSelections(category === 'professionalFamilyInteractions');
    setIndependentLivingSelections(category === 'independentLiving');
    setMedicationAdministrationSelections(category === 'medicationAdministration');
  };

  const backToCommonOptions = (
    <>
      <span className="btn btn-sm btn-outline-primary" onClick={() => handleSelection('common')}>
        Back to Common Options
      </span>
    </>
  );

  const commonOptions = [
    { blockIcon: healthIcon, heading: "Health", linkUrl: "health" },
    { blockIcon: generalIcon, heading: "General", linkUrl: "general" },
    { blockIcon: PersonalCare, heading: "Personal Care", linkUrl: "personalCare" },
    { blockIcon: mentalWellbeingIcon, heading: "Mental Wellbeing", linkUrl: "mentalWellbeing" },
    { blockIcon: WellbeingCheck, heading: "Professional Family Interactions", linkUrl: "professionalFamilyInteractions" },
    { blockIcon: independentLivingIcon, heading: "Independent Living", linkUrl: "independentLiving" },
  ]

  const healthSelectionsOptions = [
    { blockIcon: health01, heading: "Injury / Health Issue", linkedComponent: "/care-plan/add-injury-health-issue" },
    { blockIcon: health02, heading: "Weight / Height", linkedComponent: "/care-plan/add-weight-height" },
    { blockIcon: health03, heading: "Health appointments", linkedComponent: "/care-plan/add-health-appointments" },
    { blockIcon: health04, heading: "Observations", linkedComponent: "/care-plan/add-observations" },
    { blockIcon: otherIcons, heading: "Others", linkedComponent: "/care-plan/add-health-other" }
  ]

  const generalSelectionsOptions = [
    { blockIcon: general01, heading: "General Note", linkedComponent: "/care-plan/add-general-note" },
    { blockIcon: general02, heading: "Activities", linkedComponent: "/care-plan/add-activities-notes" },
    { blockIcon: general03, heading: "Sleep", linkedComponent: "/care-plan/add-sleep-notes" },
    { blockIcon: general04, heading: "Safeguarding", linkedComponent: "/care-plan/add-safeguarding" },
    { blockIcon: otherIcons, heading: "Others", linkedComponent: "/care-plan/add-general-other" }
  ]

  const personalCareSelectionsOptions = [
    { blockIcon: personalCare01, heading: "Toileting", linkedComponent: "/care-plan/add-toileting" },
    { blockIcon: personalCare02, heading: "Showering / Bath", linkedComponent: "/care-plan/add-showering-bath" },
    { blockIcon: personalCare03, heading: "Mouth Hygiene", linkedComponent: "/care-plan/add-mouth-hygiene" },
    { blockIcon: otherIcons, heading: "Others", linkedComponent: "/care-plan/add-personal-care-other" },
  ]

  const mentalWellbeingSelectionsOptions = [
    { blockIcon: mental01, heading: "Mood Rating", linkedComponent: "/care-plan/add-mood-rating" },
    { blockIcon: mental02, heading: "ABC", linkedComponent: "/care-plan/add-abc" },
    { blockIcon: mental03, heading: "Physical Intervention", linkedComponent: "/care-plan/add-physical-intervention" },
    { blockIcon: mental04, heading: "Consent, Capacity, MCA & DOLS", linkedComponent: "/care-plan/add-consent-capacity-mca-dols" },
    { blockIcon: otherIcons, heading: "Others", linkedComponent: "/care-plan/add-mental-wellbeing-other" },
  ]


  const professionalFamilyInteractionsSelectionsOptions = [
    { blockIcon: professional01, heading: "Meetings", linkedComponent: "/care-plan/add-meetings" },
    { blockIcon: professional02, heading: "Telephone Calls", linkedComponent: "/care-plan/add-telephone-calls" },
    { blockIcon: professional03, heading: "Reviews", linkedComponent: "/care-plan/add-reviews" },
    { blockIcon: professional04, heading: "Emails", linkedComponent: "/care-plan/add-emails" },
    { blockIcon: professional05, heading: "All other interactions", linkedComponent: "/care-plan/add-all-other-interactions" },
    { blockIcon: otherIcons, heading: "Others", linkedComponent: "/care-plan/add-professional-family-interactions-other" },
  ]


  const independentLivingSelectionsOptions = [
    { blockIcon: independant01, heading: "Laundry", linkedComponent: "/care-plan/add-laundry" },
    { blockIcon: independant02, heading: "Cooking", linkedComponent: "/care-plan/add-cooking" },
    { blockIcon: independant03, heading: "Nutrition / Hydration", linkedComponent: "/care-plan/add-nutrition-hydration" },
    { blockIcon: independant04, heading: "Cleaning", linkedComponent: "/care-plan/add-cleaning" },
    { blockIcon: independant05, heading: "Finance", linkedComponent: "/care-plan/add-finance" },
    { blockIcon: independant06, heading: "Public interaction", linkedComponent: "/care-plan/add-public-interaction" },
    { blockIcon: independant07, heading: "Education", linkedComponent: "/care-plan/add-education" },
    { blockIcon: otherIcons, heading: "Others", linkedComponent: "/care-plan/add-independent-living-other" },

  ]
  return (
    <>
      <Layout pageTitleText="Care Plans">
        <div className="scheduleBlocks careplanOptions">

          {
            commonSelections && (
              <>
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <div className="cards" onClick={() => { handleRowClickStaffMember("/care-plan/daily-summary") }}>
                      <div className="cardContent">
                        <span className="contentIcon">
                          <img src={GeneralNote} alt={"General Note"} />
                        </span>
                        <h3>{"Daily Summary"}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="cards" onClick={() => { handleRowClickStaffMember("/care-plan/general-notes") }}>
                      <div className="cardContent">
                        <span className="contentIcon">
                          <img src={GeneralNote} alt={"General Note"} />
                        </span>
                        <h3>{"All Care Notes"}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                
                  <h3 className="headingTitle">Common Options</h3>

                  <div className="row">
                    {
                      commonOptions?.map((options, index) => (
                        <div className="col-md-3 col-sm-6" key={index}>
                          <CommonOptionsBlocks
                            blockIcon={options?.blockIcon}
                            heading={options?.heading}
                            onClick={() => handleSelection(options?.linkUrl)}
                          />
                        </div>
                      ))
                    }
                    <div className="col-md-3 col-sm-6">
                      <div className="cards" onClick={() => navigate("/recent-incidents")}>
                        <div className="cardContent">
                          <span className="contentIcon">
                            <img src={RecordanIncident} alt="Record an Incident" />
                          </span>
                          <h3>Record an Incident</h3>
                        </div>
                      </div>
                    </div>

                  </div>
                
              </>
            )
          }

          {
            healthSelections ? (
              <>
                <header className="commonOptionsHeaders">
                  <h4>
                    Health
                  </h4>
                  <div className="actionButtons">
                    {backToCommonOptions}
                  </div>
                </header>
                <div className="row">
                  {
                    healthSelectionsOptions?.map((options, index) => (
                      <div className="col-md-3 col-sm-6" key={index}>
                        <OptionsBlocks
                          blockIcon={options?.blockIcon}
                          heading={options?.heading}
                          linkedComponent={() => { handleRowClickStaffMember(options?.linkedComponent) }}
                        />
                      </div>
                    ))
                  }
                </div>
              </>
            ) : generalSelections ? (
              <>
                <header className="commonOptionsHeaders">
                  <h4>
                    General
                  </h4>
                  <div className="actionButtons">
                    {backToCommonOptions}
                  </div>
                </header>
                <div className="row">
                  {
                    generalSelectionsOptions?.map((options, index) => (
                      <div className="col-md-3 col-sm-6" key={index}>
                        <OptionsBlocks
                          blockIcon={options?.blockIcon}
                          heading={options?.heading}
                          linkedComponent={() => { handleRowClickStaffMember(options?.linkedComponent) }}
                        />
                      </div>
                    ))
                  }
                </div>
              </>
            )
              : personalCareSelections ? (
                <>
                  <header className="commonOptionsHeaders">
                    <h4>
                      Personal Care
                    </h4>
                    <div className="actionButtons">
                      {backToCommonOptions}
                    </div>
                  </header>
                  <div className="row">
                    {
                      personalCareSelectionsOptions?.map((options, index) => (
                        <div className="col-md-3 col-sm-6" key={index}>
                          <OptionsBlocks
                            blockIcon={options?.blockIcon}
                            heading={options?.heading}
                            linkedComponent={() => { handleRowClickStaffMember(options?.linkedComponent) }}
                          />
                        </div>
                      ))
                    }
                  </div>
                </>
              )
                : mentalWellbeingSelections ? (
                  <>
                    <header className="commonOptionsHeaders">
                      <h4>
                        Mental Wellbeing
                      </h4>
                      <div className="actionButtons">
                        {backToCommonOptions}
                      </div>
                    </header>
                    <div className="row">
                      {
                        mentalWellbeingSelectionsOptions?.map((options, index) => (
                          <div className="col-md-3 col-sm-6" key={index}>
                            <OptionsBlocks
                              blockIcon={options?.blockIcon}
                              heading={options?.heading}
                              linkedComponent={() => { handleRowClickStaffMember(options?.linkedComponent) }}
                            />
                          </div>
                        ))
                      }
                    </div>
                  </>
                )
                  : professionalFamilyInteractionsSelections ? (
                    <>
                      <header className="commonOptionsHeaders">
                        <h4>
                          Professional Family Interactions
                        </h4>
                        <div className="actionButtons">
                          {backToCommonOptions}
                        </div>
                      </header>
                      <div className="row">
                        {
                          professionalFamilyInteractionsSelectionsOptions?.map((options, index) => (
                            <div className="col-md-3 col-sm-6" key={index}>
                              <OptionsBlocks
                                blockIcon={options?.blockIcon}
                                heading={options?.heading}
                                linkedComponent={() => { handleRowClickStaffMember(options?.linkedComponent) }}
                              />
                            </div>
                          ))
                        }
                      </div>
                    </>
                  )
                    : independentLivingSelections ? (
                      <>
                        <header className="commonOptionsHeaders">
                          <h4>
                            Independent Living
                          </h4>
                          <div className="actionButtons">
                            {backToCommonOptions}
                          </div>
                        </header>
                        <div className="row">
                          {
                            independentLivingSelectionsOptions?.map((options, index) => (
                              <div className="col-md-3 col-sm-6" key={index}>
                                <OptionsBlocks
                                  blockIcon={options?.blockIcon}
                                  heading={options?.heading}
                                  linkedComponent={() => { handleRowClickStaffMember(options?.linkedComponent) }}
                                />
                              </div>
                            ))
                          }
                        </div>
                      </>
                    ) : null
          }




        </div>
      </Layout >
    </>
  );
}

export default CommonOptions;
