import React, { useState, useEffect } from "react";
import Layout from "../../../components/layouts/Layout";
import eventClose from "../../../assets/images/icons/eventClose.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import eventDel from "../../../assets/images/icons/eventDel.svg";
import {
  GET_ALL_SERVICE_USERS_URL_ENQUIRY,
  REMOVE_SERVICE_USER_BY_ID_URL,
} from "../../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import ProgressComponent from "../../../components/general/ProgressComponent";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from 'moment';
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';

function OutStadingRequest() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [dataID, setDataID] = useState({ id: null });
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const handleModalEvent = () => setShow(!show);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    limit: 50,
    query: {
      critarion: { active: true },
      usersFields: "profile_picture_url",
      addedby: "_id email first_name",
      lastModifiedBy: "_id email first_name",
    },
  };
  const [isLoading, setIsLoading] = useState(true);
  const [allRecords, setRecords] = useState([]);
  const [pendingRecords, setPendingRecords] = useState([]);
  const [rejectedRecords, setRejectedRecords] = useState([]);
  const [noPendingRecordFound, setNoPendingRecordFound] = useState(false);
  const [noRejectedRecordFound, setNoRejectedRecordFound] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false);

  const [reviewEnquiryListData, setReviewEnquiryListData] = useState([]);

  const handleRemoveModalEvent = (enquiryId) => {
    setShow(!show);
    setDataID({ id: enquiryId });
  };

  const processEnquiryData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allPendingData = data?.data?.suEnquirys?.filter((item) => item?.suEnqStatus === "pending");
      const allRejectedData = data?.data?.suEnquirys?.filter((item) => item?.suEnqStatus === "rejected");
      const allData = data?.data?.suEnquirys;
      console.log("my outstanding enq list: ", allData);
      if (allPendingData.length === 0) {
        setNoPendingRecordFound(true)
      }
      else {
        setNoPendingRecordFound(false)
        setPendingRecords(allPendingData)
      }
      if (allRejectedData.length === 0) {
        setNoRejectedRecordFound(true)
      }
      else {
        setNoRejectedRecordFound(false)
        setRejectedRecords(allRejectedData)
      }
      if (allData && allData.length === 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
        setIsLoading(false);
        setRecords(allData);
        setReviewEnquiryListData(allData);
      }
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    const getEnquiry = await getRecords(
      GET_ALL_SERVICE_USERS_URL_ENQUIRY,
      apiQuery
    );
    processEnquiryData("get", getEnquiry);
    console.log("All Enquiry:", getEnquiry);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const statusClassMapping = {
    accepted: "background-success",
    pending: "background-warning",
    rejected: "background-danger",
  };

  // Remove Enquiry

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      console.log({ data });
      toastErrorMessages(toast?.error, data?.message, 3000);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
    }
  };
  // data?.message,
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (dataID !== "") {
      const response = await deleteRecords(
        REMOVE_SERVICE_USER_BY_ID_URL,
        dataID
      );
      setShow(!show);
      processFetchRecords("delete", response);
      setIsSubmitButtonClicked(false);
      fetchAllRecords();
    } else {
      setShow(!show);
      toastErrorMessages(toast.error, "Unable to remove", "", 500);
      setIsSubmitButtonClicked(false);
    }
  });

  const editSuEnquiry = (item) => {
    navigate("/service-user-enquiries", { state: { userDetails: [item] } });
  };

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Service User <small>Outstanding Requests</small>
          </>
        }
      >
        <div className="addNewRequestSU">
          <div className="mainContent p-0">
            <header className="mainContentHeader">
              <h3>Outstanding Requests</h3>
              <div className="actionButtons">
                {/* <div className="searchContainer">
                  <div className="searchTextBox">
                    <input type="text" placeholder="Type your search" />
                  </div>
                </div> */}
                
                  <Link to="/assign-staff" className="btn btn-sm btn-primary">
                    Assign Staff
                  </Link>
                

              </div>
            </header>
          </div>
          <Tabs
            defaultActiveKey="pending"
            id="enquiry-tab"
            className="tabContainer"
          >
            <Tab eventKey="pending" title="Pending">
              {noPendingRecordFound ? (
                <>
                  <div className="noRecordFound">
                    <p>No records found.</p>
                  </div>
                </>
              ) : isLoading ? (
                <ProgressComponent />
              ) : (
                <>
                  <div className="requestContentCards">
                    {pendingRecords.map((item, index) => (
                      <>
                        <div className="cards">
                          <div className="cardsUserDetail">
                            <span
                              className={`statusButton ${statusClassMapping[item?.suEnqStatus]
                                }`}
                            >
                              {item?.suEnqStatus}
                            </span>

                            
                              <span
                                onClick={() => {
                                  editSuEnquiry(item);
                                }}
                              >
                                <span className="editIcon">&nbsp;</span>
                              </span>
                            


                           
                              <span
                                className="delIcon"
                                onClick={() => {
                                  handleRemoveModalEvent(item?._id);
                                }}
                              >
                                <img src={eventClose} alt="Event Del" />
                              </span>
                            
                          </div>
                          <p className="textTransformCapitalize">
                            Request Type: <strong>{item?.requestType}</strong>
                          </p>
                          <div className="commentBox">
                            <p>
                              <strong>Ref ID:</strong> {item?.referenceId}
                            </p>
                            <p>
                              <strong>
                                {item?.serviceUser?.modelId?.name}&nbsp;
                                {item?.serviceUser?.modelId?.suLastName}
                              </strong>
                            </p>
                            {/* {item?.serviceUser?.modelId?.name} - {item?.serviceUser?.modelId?.suLastName} */}
                            <p>
                              {item.suEnqComments
                                ? item.suEnqComments.slice(0, 70) + "..."
                                : ""}
                            </p>
                          </div>
                          <p>
                            <small>
                              Initial Contact Date:{" "}
                              {item?.initialContactDate
                                ?
                                moment(item?.initialContactDate).format("DD/MM/YYYY")
                                : ""}
                            </small>
                          </p>
                        </div>
                      </>
                    )
                    )}
                  </div>
                </>
              )}
            </Tab>
            <Tab eventKey="rejected" title="Rejected">

              {noRejectedRecordFound ? (
                <>
                  <div className="noRecordFound">
                    <p>No records found.</p>
                  </div>
                </>
              ) : isLoading ? (
                <ProgressComponent />
              ) : (
                <>
                  <div className="requestContentCards">
                    {
                      rejectedRecords.map((item, index) => (
                        <>
                          <div className="cards">
                            <div className="cardsUserDetail">
                              <span
                                className={`statusButton ${statusClassMapping[item?.suEnqStatus]
                                  }`}
                              >
                                {item?.suEnqStatus}
                              </span>

                              
                                <span
                                  onClick={() => {
                                    editSuEnquiry(item);
                                  }}
                                >
                                  <span className="editIcon">&nbsp;</span>
                                </span>
                              


                             
                                <span
                                  className="delIcon"
                                  onClick={() => {
                                    handleRemoveModalEvent(item?._id);
                                  }}
                                >
                                  <img src={eventClose} alt="Event Del" />
                                </span>
                              

                            </div>
                            <p className="textTransformCapitalize">
                              Request Type: <strong>{item?.requestType}</strong>
                            </p>
                            <div className="commentBox">
                              <p>
                                <strong>Ref ID:</strong> {item?.referenceId}
                              </p>
                              <p>
                                <strong>
                                  {item?.serviceUser?.modelId?.name}&nbsp;
                                  {item?.serviceUser?.modelId?.suLastName}
                                </strong>
                              </p>
                              {/* {item?.serviceUser?.modelId?.name} - {item?.serviceUser?.modelId?.suLastName} */}
                              <p>
                                {item.suEnqComments
                                  ? item.suEnqComments.slice(0, 70) + "..."
                                  : ""}
                              </p>
                            </div>
                            <p>
                              <small>
                                Initial Contact Date:{" "}
                                {item?.initialContactDate
                                  ? moment(item?.initialContactDate).format("DD/MM/YYYY")
                                  : ""}
                              </small>
                            </p>
                          </div>
                        </>

                      ))}
                  </div>
                </>
              )}

            </Tab>
          </Tabs>
        </div>
      </Layout>
     
        <Modal
          show={show}
          onHide={handleModalEvent}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this user request?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}
            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={handleModalEvent}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      
      <ToastContainer />
    </>
  );
}

export default OutStadingRequest;
