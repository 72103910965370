import React, { useState, useEffects } from "react";
import { Link } from "react-router-dom";

function StaffRecordHeader({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUser] = useState(initialUserData);
  console.log("🚀 ~ StaffRecordHeader ~ userData:", userData)
  return (
    <>
      <header className="headerContainerSearchAction">
        <div className="actionButtons">
         &nbsp;
        </div>
        <div className="searchContainer">
          <p className="currentStatus">
            {userData?.active ? (
              <>
                <strong>Current Status:</strong> Active <span>&nbsp;</span>
              </>
            ) : (
              <>
                <strong>Current Status:</strong> Inactive{" "}
                <span style={{ background: "red" }}>&nbsp;</span>
              </>
            )}
          </p>
        </div>
      </header>
    </>
  );
}

export default StaffRecordHeader;
