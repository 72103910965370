import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'html-react-parser';
import moment from 'moment';
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL, GET_QUALITY_SURVEY_URL } from "../../../env";
import {
  getRecords,
  catchAsync,
  isSuccess,
} from "../../../helper/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import UserDetailReview from '../../../components/user-detail/UserDetailReview';
import ProgressComponent from '../../../components/general/ProgressComponent';
// Emoji Icons
import Inadequate from "../../../assets/images/quality-survey/Inadequate.svg";
import RequiresImprovement from "../../../assets/images/quality-survey/RequiresImprovement.svg";
import Good from "../../../assets/images/quality-survey/Good.svg";
import Outstanding from "../../../assets/images/quality-survey/Outstanding.svg";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import PermissionWrapper from '../../../permission_advance/PermissionWrapper';
function ViewQualitySurvey() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userData = JSON.parse(storedUserDetails);
  const navigate = useNavigate();
  const [qualitySurveyData, setQualitySurveyData] = useState([])
  // console.log("🚀 ~ ViewQualitySurvey ~ qualitySurveyData:", qualitySurveyData)
  const [qualitySurveyId, setQualitySurveyId] = useState(
    {
      query: {
        critarion: {
          userId: userData?._id
        }
      }
    });
  const [isLoading, setIsLoading] = useState(true);

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      setQualitySurveyData(data?.data?.qualitySurvey.reverse()[0]?.question)
      setIsLoading(false);
    }
  };
  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_QUALITY_SURVEY_URL, qualitySurveyId);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const uniqueCategoryTitle = new Set();

  qualitySurveyData?.forEach((question) => {
    uniqueCategoryTitle.add(question?.categoryId?.categoryName)
  })
  console.log("🚀 ~ ViewQualitySurvey ~ uniqueCategoryTitle:", uniqueCategoryTitle)
  return (
    <>
      <Layout
        pageTitleText={
          <>
            Care Plan
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
        <div className="viewEditorWrapper">
          <div className="mainContent mt-3">

            {isLoading ? (
              <ProgressComponent />
            ) : (
              <>
                <div className="viewEditorContent qualitySurveyEditorContent">

                  <div className="personalDetail">
                    <header className="mainContentHeader">
                      {/* <h3>Personal Detail</h3> */}
                      <div></div>
                      <div>
                        <span className="btn btn-sm btn-primary" onClick={() => {
                          navigate("/care-notes");
                        }}>Back to Care Plan</span>
                        &nbsp;
                        <PermissionWrapper title="Quality Survey" action="create">
                          <span className="btn btn-sm btn-secondary" onClick={() => {
                            navigate("/add-quality-survey");
                          }}>Add Quality Survey</span>
                        </PermissionWrapper>
                      </div>
                    </header>
                  </div>

                  <div className="emojiInstructions">
                    <div className="instructionsContent">
                      <div className="item">
                        <img src={Inadequate} alt={Inadequate} className='Inadequate' />
                        <p><strong>Inadequate</strong></p>
                      </div>
                      <div className="item">
                        <img src={RequiresImprovement} alt={RequiresImprovement} className='RequiresImprovement' />
                        <p><strong>Requires Improvement</strong></p>
                      </div>
                      <div className="item">
                        <img src={Good} alt={Good} className='Good' />
                        <p><strong>Good</strong></p>
                      </div>
                      <div className="item">
                        <img src={Outstanding} alt={Outstanding} className='Outstanding' />
                        <p><strong>Outstanding</strong></p>
                      </div>
                    </div>
                  </div>
                  <div className="questionsWrapper">

                    {/* 01 */}
                    {
                      Array.from(uniqueCategoryTitle)?.map((categoryName, categoryIndex) => (
                        <>
                          <div key={categoryIndex} className="questions">
                            <div className="table-responsive">
                              <table
                                className="dynamicRows table table-striped table-bordered table-hover"
                              >

                                <thead>
                                  <tr>
                                    <th>{categoryName}</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    qualitySurveyData?.map((question, index) => (
                                      question?.categoryId?.categoryName.includes(categoryName) && (

                                        question?.isTextAreaEnabled === false ? (
                                          <tr key={index}>
                                            <td>{question?.question}</td>
                                            <td>
                                              <div className={
                                                `feedBackIcons
                                        ${question?.score === 1 ? 'Inadequate' :
                                                  question?.score === 2 ? 'RequiresImprovement' :
                                                    question?.score === 3 ? 'Good' :
                                                      question?.score === 4 ? 'Outstanding' :
                                                        ''
                                                }`}>

                                                <OverlayTrigger placement='top' overlay={
                                                  <Tooltip>
                                                    {
                                                      question?.score === 1 ? 'Inadequate' :
                                                        question?.score === 2 ? 'Requires Improvement' :
                                                          question?.score === 3 ? 'Good' :
                                                            question?.score === 4 ? 'Outstanding' :
                                                              ''
                                                    }
                                                  </Tooltip>
                                                }
                                                >
                                                  <img
                                                    src={
                                                      `
                                            ${question?.score === 1 ? Inadequate :
                                                        question?.score === 2 ? RequiresImprovement :
                                                          question?.score === 3 ? Good :
                                                            question?.score === 4 ? Outstanding :
                                                              RequiresImprovement
                                                      }`}
                                                    alt="FeedBack Icon"
                                                  />
                                                </OverlayTrigger>

                                              </div>
                                            </td>
                                          </tr>
                                        ) : (
                                          <>
                                            <tr key={index}>
                                              <th colSpan={2}>
                                                <p className='mt-2 mb-2'><strong>Comments</strong></p>
                                              </th>
                                            </tr>
                                            <tr>
                                              <td colSpan={2}>
                                                <p>{question?.textAreaDetail}</p>
                                              </td>
                                            </tr>
                                          </>
                                        )

                                      )
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>

                        </>
                      ))
                    }


                    {/* 07 */}
                    {/* <div className="questions">
                      <div className="table-responsive">
                        <table
                          className="dynamicRows table table-striped table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Comments</th>
                            </tr>

                          </thead>
                          <tbody>
                            <tr>
                              <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}

                    {/* 08 */}
                    {/* <div className="forOfficeUseOnly">
                      <div className="table-responsive">
                        <table
                          className="dynamicRows table table-striped table-bordered table-hover officeUse"
                        >
                          <thead>
                            <tr>
                              <th colSpan={3}>
                                FOR OFFICE USE ONLY
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Date returned: Nov/01/2023</td>
                              <td>Total score: 1003</td>
                              <td>Tallied By: (initials)</td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}

                  </div>





                </div>
              </>
            )}
          </div>

        </div>
      </Layout >
    </>
  );
}

export default ViewQualitySurvey