import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import infoIcon from "../../assets/images/icons/infoIcon.svg";
function TextArea({
  id,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  required,
  labelText,
  rows,
  cols,
  disabled,
  readOnly,
  limitCounter,
  errorClass,
  customError,
  errorMessage,
  requiredStar,
  disabledStatus,
  extraClass,
  hiddenDiv,
  tooltipInfo,
  tooltipText,
}) {
  const maxLength = 3000;
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipText}
    </Tooltip>
  );
  return (
    <>
      {
        !hiddenDiv ? (
          <div
            className={`form-group ${errorClass && "validationError"} ${extraClass && extraClass
              }`}
          >
            <textarea
              id={id}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              cols={cols}
              rows={rows}
              maxLength={maxLength}
              readOnly={readOnly}
              onBlur={onBlur}
              disabled={disabledStatus}
              required={required}
            // readOnly={readOnly}
            ></textarea>
            <label htmlFor={id} className="floatingLabel">
              {labelText}
              {requiredStar ? <span className="text-danger">*</span> : null}

            </label>
            {
              tooltipInfo && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <span className="textareaTooltip infoBlock">
                    &nbsp;
                    <img
                      className="curSorPointer"
                      src={infoIcon}
                      alt="Settings"
                    />
                  </span>
                </OverlayTrigger>)
            }
            {customError ? <div className="text-danger">{errorMessage}</div> : null}
            {limitCounter ? (
              <p>
                <small className="m-0">
                  Characters Remaining: {maxLength - value?.length || maxLength}{" "}
                </small>
              </p>
            ) : (
              ""
            )}
          </div>
        ) : null
      }

    </>
  );
}

export default TextArea;
