import * as Yup from "yup";
import moment from "moment";
export const addSUSignUp = Yup.object({
  // Required Fields
  referenceId: Yup.string()
    .required("Please enter Reference ID")
    .label("Reference ID"),
  suFirstMiddleName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your first name")
    .label("First Name"),
  suEmailHome: Yup.string()
    .email()
    .required("Please enter email address")
    .label("Home Email"),
  suMobileHomeNo: Yup.number()
    .typeError("Please provide a valid No")
    .required("Please enter Mobile Home")
    .label("Mobile Home No"),

  // Fields validation
  suHomePhone: Yup.number()
    .typeError("Please provide a valid No")
    .label("Home Phone No"),
  suWorkPhone: Yup.number()
    .typeError("Please provide a valid No")
    .label("Work Phone No"),
  suMobileWorkNo: Yup.number()
    .typeError("Please provide a valid No")
    .label("Mobile Work No"),
  // suMobileWorkNo: Yup.number()
  //   .typeError("Please provide a valid No")
  //   .label("Mobile Work No"),
  suPrefHomeNo: Yup.number()
    .typeError("Please provide a valid No")
    .label("Pref. Home No"),
  suPrefWorkNo: Yup.number()
    .typeError("Please provide a valid No")
    .label("Pref. Work No"),
  suEmergencyContact: Yup.number()
    .typeError("Please provide a valid No")
    .label("Emergency Contact"),
  suEmailWork: Yup.string().email().label("Email Work"),
});

export const addReview = Yup.object().shape({
  review: Yup.string().required("Review is required").label("Review"),

});
export const addConsentTemplate = Yup.object().shape({
  description: Yup.string().required("Description is required").label("Description"),

});
export const addRiskAssessment = Yup.object().shape({
  hazard: Yup.string().required("Hazard is required").label("Hazard"),
  personsExposedToHazard: Yup.string().required("Personal (s) Exposed to hazard is required").label("Personal (s) Exposed to hazard"),
  riskIdentified: Yup.string().required("Risk Identified is required").label("Risk Identified"),
  coldMeasureRequired: Yup.string().required("Control Measures is required").label("Control Measures"),

});
export const addHealthIssue = Yup.object().shape({
  category: Yup.string().required("Category is required").label("Category"),
  subcategory: Yup.string().required("Subcategory is required").label("Subcategory"),
  healthNote: Yup.string().required("Health Note is required").label("Health Note"),
  complaint: Yup.string().required("Complaint is required").label("Complaint"),
});
export const addMedicationValidation = Yup.object().shape({
  days: Yup.array().required("Days are required").min(1, 'Select at least one day').label("Days"),
  medicationStartDate: Yup.number()
    .required("Medication start date is required")
    .label("Medication start date"),

  medicationEndDate: Yup.number()
    .label("Medication end date")
    .test('is-greater', 'End date must be greater than or equal to start date', function (value) {
      const { medicationStartDate } = this.parent;

      // If there's no end date, return true (valid case)
      if (!value) {
        return true;
      }

      // Check if start date exists before comparing
      if (!medicationStartDate) {
        return this.createError({ path: 'medicationStartDate', message: 'Start date is required before the end date' });
      }

      // Parse the long values back into moment objects
      const startDate = moment(medicationStartDate);
      const endDate = moment(value);

      // If dates are invalid, return false (invalid case)
      if (!startDate.isValid() || !endDate.isValid()) {
        return false;
      }

      // Return true if end date is same or after the start date
      return endDate.isSameOrAfter(startDate);
    }),
  note: Yup.string().required("Note is required").label("Note"),
  prescriberName: Yup.string().required("Prescriber Name is required").label("Prescriber Name"),
  reasonForMedication: Yup.string().required("Reason is required").label("Reason"),
  prescriptionText: Yup.string().required("Prescription is required").label("Prescription"),
  addedby: Yup.string().required("Please select Staff Member").label("Staff Member"),
  // medicationName: Yup.string().required("Please select Medication Name").label("Medication Name"),

  // dose: Yup.array().of(
  //   Yup.object().shape({
  //     dose: Yup.string().required('Dose is required'),
  //     doseTime: Yup.string().required('Dose Time is required'),
  //   })
  // ).min(1, 'At least one dose field is required'),
});
export const addAppointmentValidation = Yup.object().shape({
  appointmentTitle: Yup.string().required("Title is required").label("Appointment Ttitle"),
  appointmentDate: Yup.string()
    .required("Appointment Date is required")
    .nullable()  // Allow null values
    // .test("is-valid", "Invalid date", value => {
    //   // Check if the value is a valid date
    //   return value ? !isNaN(new Date(value).getTime()) : true;
    // })
    .label("Appointment Date"),
  // days: Yup.array().required("Days are required").min(1, 'Select at least one day').label("Days"),
  // staff: Yup.string().required("Staff is required").label("Staff"),
  appointmentStartTime: Yup.string().required("Start Time is required").label("Appointment Start Time"),
  appointmentEndTime: Yup.string()
    .required('End Time is required')
    .label('Appointment End Time')
    .test('is-greater', 'End Time must be greater than Start Time', function (value) {
      const { appointmentStartTime } = this.parent;
      if (appointmentStartTime && value) {
        // Convert times to moments for comparison
        const startTime = moment(appointmentStartTime, 'HH:mm');
        const endTime = moment(value, 'HH:mm');
        return endTime.isAfter(startTime);
      }
      return true; // Validation passes if either field is empty
    }),
  appointmentDetails: Yup.string().required("Details is required").label("Appointment Details"),
});


export const addRotaTemplate = Yup.object().shape({
  rotaTemplateTitle: Yup.string().required("Template name is required").label("Template name"),
  // startDate: Yup.string().required("Start Date is required").label("Start Date"),
});


export const editRotaTemplate = Yup.object().shape({
  rotaTemplateTitle: Yup.string().required("Template name is required").label("Template name"),
  startDate: Yup.number()
    .typeError("Start Date is required")
    .required("Start Date is required")
    .label("Start Date"),
  endDate: Yup.number()
    .typeError("End Date must be a valid date")
    .when("status", {
      is: "Inactive",
      then: schema => schema.required("End date is required when status is In Active"),
      otherwise: schema => schema.nullable(true),
    })
    .test('is-greater', 'End Date must be greater than or equal to Start Date', function (value) {
      const { startDate } = this.parent;
      if (value == null) return true; // If endDate is null, skip the validation
      return value >= startDate;
    })
    .label("End Date"),
});


export const duplicateRotaTemplate = Yup.object().shape({
  rotaTemplateTitle: Yup.string().required("Template name is required").label("Template name"),
  startDate: Yup.number()
    .typeError("Start Date is required")
    .required("Start Date is required")
    .label("Start Date"),
  endDate: Yup.number()
    .typeError("End Date must be a valid date")
    .when("status", {
      is: "Inactive",
      then: schema => schema.required("End date is required when status is In Active"),
      otherwise: schema => schema.nullable(true),
    })
    .test('is-greater', 'End Date must be greater than or equal to Start Date', function (value) {
      const { startDate } = this.parent;
      if (value == null) return true; // If endDate is null, skip the validation
      return value >= startDate;
    })
    .label("End Date"),
});


export const addScheduleWeekDays = Yup.object().shape({
  dayName: Yup.string().required("Days are required").min(1, 'Select at least one day').label("Days"),
  weekNo: Yup.string().required("Please select week").label("Week"),
  shiftStartTime: Yup.string()
    .required("Start Time is required")
    .label("Appointment Start Time"),
  shiftEndTime: Yup.string()
    .required('End Time is required')
    .label('Appointment End Time')
    .test('is-greater', 'End Time must be greater than Start Time', function (value) {
      const { shiftStartTime } = this.parent;
      if (shiftStartTime && value) {
        const startTime = moment(shiftStartTime, 'HH:mm');
        const endTime = moment(value, 'HH:mm');

        // Check if the times are 20:00 and 08:00 respectively
        if (
          (shiftStartTime === '20:00' || shiftStartTime === '08:00 PM') &&
          (value === '08:00' || value === '08:00 AM')
        ) {
          return true; // Waive the condition if the special case is met
        }

        // Regular validation for other times
        return endTime.isAfter(startTime);
      }
      return true; // Validation passes if either field is empty
    }),
});

export const addMedicationStock = Yup.object().shape({
  // medicationType: Yup.string().required("Medication Type is required").label("Medication Type"),
  medicationName: Yup.string().required("Please select medicine").label("Medicine name"),
  inventory: Yup.number().required("Inventory is required").min(1).label("Inventory"),
});

export const addMedicineList = Yup.object().shape({
  medicineType: Yup.string().required("Medicine Type is required").label("Medicine Type"),
  unitType: Yup.string().required("Unit Type is required").label("Unit Type"),
  strength: Yup.string().required("Strength is required").label("Strength"),
  medicationName: Yup.string().required("Medicine name is required").label("Medicine name"),
  description: Yup.string().required("Description is required").label("Description"),
  // inventory: Yup.number().required("Inventory is required").min(1).label("Inventory"),
});

export const addPbsPlansStock = Yup.object().shape({
  staffId: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  // aboutPlanEditor: Yup.string().required("About Plan is required").label("About Plan"),
  // managementOfBehaviorPlanEditor: Yup.string().required("Management of behavioural presentation is required").label("Management of behavioural presentation"),
  // secondaryPreventionEditor: Yup.string().required("Secondary Prevention is required").label("Secondary Prevention"),
  // reactiveStartegiesEditor: Yup.string().required("Reactive Strategies is required").label("Reactive Strategies"),
  // postIncidentSupportEditor: Yup.string().required("Post Incident Support- Recovery is required").label("Post Incident Support- Recovery"),
});

export const addRecentIncidents = Yup.object().shape({
  incidentTitle: Yup.string().required("Incident Title is required").label("Incident Title"),
  incidentDate: Yup.string().required("Incident Date is required").label("Incident Date"),
  note: Yup.string().required("Note is required").label("Note"),
});
export const addRecCandidate = Yup.object().shape({
  dp: Yup.string().required("Profile Image is required").label("Profile Image"),
  firstName: Yup.string().required("First Name is required").label("First Name"),
  lastName: Yup.string().required("Last Name is required").label("Last Name"),
  email: Yup.string().required("Email is required").label("Email"),
  dob: Yup.string().required("DOB is required").label("DOB"),
  phone: Yup.string().required("Phone is required").label("Phone"),
  otherDetails: Yup.string().required("Other Detail is required").label("Other Detail"),
});
export const modifySupportPlan = Yup.object().shape({
  title: Yup.string().required("Title is required").label("Title"),
  // note: Yup.string().required("Note is required").label("Note"),
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
});
export const addCarePlanDocument = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
});
export const editCarePlanDocument = Yup.object().shape({
  // modifiedUser: Yup.string()
  //   .required("Please select Staff Member")
  //   .label("Staff Member"),
});

export const addCarePlanNotes = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  noteDetails: Yup.string()
    .required("Note Details is required")
    .label("Note Details"),
  title: Yup.string()
    .required("Title is required")
    .label("Title"),
  eventDateTime: Yup.string()
    .required("Event Date Time is required")
    .label("Event Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),
});

export const addHealthInjury = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  // category: Yup.string().required("Category is required").label("Category"),
  // subcategory: Yup.string().required("Subcategory is required").label("Subcategory"),
  // nameOfWitnesses: Yup.string().required("Name of witnesses is required").label("Name of witnesses"),
  // placeOfAccident: Yup.string().required("Place is required").label("Place"),
  // accidentDescription: Yup.string().required("Description is required").label("Description"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),
})
export const addSafeguarding = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  category: Yup.string().required("Category is required").label("Category"),
  subcategory: Yup.string().required("Subcategory is required").label("Subcategory"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),
})
export const editSafeguarding = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  // category: Yup.string().required("Category is required").label("Category"),
  // subcategory: Yup.string().required("Subcategory is required").label("Subcategory"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),
})

export const addHealthWeightHeight = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  weight: Yup.number()
    .required("Weight is required")
    .label("Weight"),
  height: Yup.number()
    .required("Height is required")
    .label("Height"),
  comments: Yup.string()
    .required("Comments is required")
    .label("Comments"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),

})

export const addHealthConsentCapacityMCADOLS = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  // weight: Yup.number()
  //   .required("Weight is required")
  //   .label("Weight"),
  // height: Yup.number()
  //   .required("Height is required")
  //   .label("Height"),
  // comments: Yup.string()
  //   .required("Comments is required")
  //   .label("Comments"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),

})
export const addHealthPhysicalIntervention = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  // weight: Yup.number()
  //   .required("Weight is required")
  //   .label("Weight"),
  // height: Yup.number()
  //   .required("Height is required")
  //   .label("Height"),
  // comments: Yup.string()
  //   .required("Comments is required")
  //   .label("Comments"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),
  category: Yup.string().required("Category is required").label("Category"),
  subcategory: Yup.string().required("Subcategory is required").label("Subcategory"),

})
export const addToileting = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  // assistanceType: Yup.string()
  //   .required("Assistance Type is required")
  //   .label("Assistance type"),
  comments: Yup.string()
    .required("Comments is required")
    .label("Comments"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),

})
export const addShoweringBath = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  // assistanceType: Yup.string()
  //   .required("Assistance Type is required")
  //   .label("Assistance type"),
  comments: Yup.string()
    .required("Comments is required")
    .label("Comments"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),

})
export const addNutritionHydration = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  // assistanceType: Yup.string()
  //   .required("Assistance Type is required")
  //   .label("Assistance type"),
  comments: Yup.string()
    .required("Comments is required")
    .label("Comments"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),

})

export const addHealthAppointments = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  appointmentWith: Yup.string()
    .required("Appointment With is required")
    .label("Appointment With"),
  reasonForAppointment: Yup.string()
    .required("Reason for appointment is required")
    .label("Reason for appointment"),
  comments: Yup.string()
    .required("Comments is required")
    .label("Comments"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),

})
export const addHealthObservations = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  heartRate: Yup.number()
    .required("Heart Rate is required")
    .label("Heart Rate"),
  bloodPressure: Yup.number()
    .required("Blood Pressure is required")
    .label("Blood Pressure"),
  respiratoryRate: Yup.number()
    .required("Respiratory Rate is required")
    .label("Respiratory Rate"),
  oxygen: Yup.number()
    .required("Oxygen is required")
    .label("Oxygen"),
  temperature: Yup.number()
    .required("Temperature is required")
    .label("Temperature"),
  bloodSugar: Yup.number()
    .required("Blood Sugar is required")
    .label("Blood Sugar"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),

})

export const addABC = Yup.object().shape({
  addedby: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  antecedentEvents: Yup.string()
    .required("Antecedent Events is required")
    .label("Antecedent Events"),
  behaviour: Yup.string()
    .required("Behaviour is required")
    .label("Behaviour"),
  consequenceEvents: Yup.string()
    .required("Consequence Events is required")
    .label("Consequence Events"),
  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),

})
export const addMoodRating = Yup.object().shape({
  // addedby: Yup.string()
  //   .required("Please select Staff Member")
  //   .label("Staff Member"),

  eventDateTime: Yup.string()
    .required("Date Time is required")
    .label("Date Time")
    .test('is-selected', 'Please select an Event Date Time', function (value) {

      return value !== undefined && value !== null && value !== '';
    }),

})

export const addMemoryBox = Yup.object().shape({
  addedBy: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  note: Yup.string()
    .required("Note is required")
    .label("Note"),
});

export const interviewStageValidation = Yup.object().shape({
  preScreen: Yup.string().required("Pre Screen is required").label("Pre Screen"),
  cv: Yup.string().required("cv is required").label("cv"),
  interviewForm: Yup.string().required("Interview Form is required").label("Interview Form"),
  offerLetter: Yup.string().required("Offer Letter is required").label("Offer Letter"),
  applicationForm: Yup.string().required("Application Form is required").label("Application Form"),
});
export const documentSubmitStageValidation = Yup.object().shape({
  photographicIdentification: Yup.string().required("Photographic Identification is required").label("Photographic Identification"),
  proofOfAddress: Yup.string().required("Proof of Address is required").label("Proof of Address"),
  rightToWork: Yup.string().required("Right to work is required").label("Right to work"),
  proofOfNationalInsurance: Yup.string().required("Proof of National Insurance is required").label("Proof of National Insurance"),
});
export const DBSCStageValidation = Yup.object().shape({
  DBSCertificate: Yup.string().required("DBS Certificate is required").label("DBS Certificate"),
  isDbscChecked: Yup.boolean()
    .oneOf([true], "Please tick this box if training has been sent.")
    .required("Please tick this box if training has been sent.")
    .label("Checkbox"),
});


export const complianceStageValidation = Yup.object().shape({
  references: Yup.string().required("references is required").label("references"),
  bankDetailsForm: Yup.string().required("bankDetailsForm is required").label("bankDetailsForm"),
  personalDetailsForm: Yup.string().required("personalDetailsForm is required").label("personalDetailsForm"),
  hourOptOutForm: Yup.string().required("hourOptOutForm is required").label("hourOptOutForm"),
  healthDeclaration: Yup.string().required("healthDeclaration is required").label("healthDeclaration"),
  equalOpportunities: Yup.string().required("equalOpportunities is required").label("equalOpportunities"),
  BYODSignedDeclaration: Yup.string().required("BYODSignedDeclaration is required").label("BYODSignedDeclaration"),
  trainingCompletedPercent: Yup.boolean()
    .oneOf([true], "Please tick this box if 50% of online training has been completed ")
    .required("Please tick this box if 50% of online training has been completed ")
    .label("Checkbox"),
});

export const onBoardingstageValidation = Yup.object().shape({
  // inductionDate: Yup.string().required("Induction date is required").label("Induction date"),
  trainingCompletion: Yup.boolean()
    .oneOf([true], "Please tick this box if online training has been completed.")
    .required("Please tick this box if online training has been completed.")
    .label("Checkbox"),
});

export const addDocument = Yup.object().shape({
  addedBy: Yup.string()
    .required("Please select Staff Member")
    .label("Staff Member"),
  title: Yup.string()
    .required("Title is required")
    .label("Title"),
  details: Yup.string()
    .required("Details is required")
    .label("Details"),
  docPath: Yup.mixed()
    .required("Document is required")
    .label("Document"),
});

export const assignRoleToUser = Yup.object().shape({

})


export const addServiceUserReview = Yup.object().shape({
  // personalDetails: Yup.object().shape({
  //   name: Yup.string().required("Name is required").label("Name"),
  //   locality: Yup.string().required("Locality is required").label("Locality"),
  //   liquidLogic: Yup.string().required("Liquid Logic is required").label("Liquic Logic"),
  // }),
});

export const updateHealthIssueValidation = Yup.object().shape({
  healthNote: Yup.string().required("Health Note is required").label("Health Note"),
  complaint: Yup.string().required("Complaint is required").label("Complaint"),
});

export const addEditSUEnquiry = Yup.object({
  // Required Field
  referenceId: Yup.string().required("Ref ID is required"),
  name: Yup.string().required("First Name is required"),
  suEnqContactNo: Yup.number().required("Contact Number is required").typeError("Please provide a valid No")
    .label("Contact No"),
  suEnqEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required").typeError("Please provide a valid Email").label("Email Address"),
});


export const addQuizzesValidationSchema = Yup.object().shape({
  quizName: Yup.string().required("Quiz Name is required").label("Quiz Name"),
  passMarksPercent: Yup.string().required("Passing Marks is required").label("Passing Marks"),
  questionData: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string().required("Question is required").label("Question"),
        questionOptions: Yup.array()
          .of(
            Yup.object().shape({
              option: Yup.string().required("Option is required").label("Option"),
              isAnswer: Yup.boolean(),
            })
          )
          .min(2, "At least two options are required")
          .test("one-correct-answer", "One option must be marked as the correct answer", (questionOptions) => {
            return questionOptions.some((option) => option.isAnswer === true);
          })
      })
    )
    .min(1, "At least one question is required")
});

export const setChatGroupTiming = Yup.object().shape({
  // groupWorkingScheduleTime: Yup.object().shape({
  //   startTime: Yup.number().required("Start Time is required."),
  //   endTime: Yup.number()
  //     .required("End Time is required")
  //     .min(
  //       Yup.ref("groupWorkingScheduleTime.startTime"),
  //       "End Time must be greater than Start Time"
  //     ),
  //   totalWorkHours: Yup.string().required("Total Work Hours is required."),
  // }),
});

export const addStaffHoliday = Yup.object().shape({
  hldRqStartDate: Yup.string()
    .required("Holiday start date is required")
    .label("Holiday start date"),

  hldRqEndDate: Yup.string()
    .label("Holiday end date")
    .required("Holiday end date is required")
    .test('is-greater', 'End date must be greater than or equal to start date', function (value) {
      const { hldRqStartDate } = this.parent;

      // If there's no end date, return true (valid case)
      if (!value) {
        return true;
      }

      // Check if start date exists before comparing
      if (!hldRqStartDate) {
        return this.createError({ path: 'hldRqStartDate', message: 'Start date is required before the end date' });
      }

      // Parse dates using moment
      const startDate = moment(hldRqStartDate, "YYYY-MM-DD", true);
      const endDate = moment(value, "YYYY-MM-DD", true);

      // If dates are invalid, return false (invalid case)
      if (!startDate.isValid() || !endDate.isValid()) {
        return false;
      }

      // Return true if end date is same or after the start date
      return endDate.isSameOrAfter(startDate);
    }),
  hldRqTotalDays: Yup.string().required("Total day(s) required").label("Total Day(s)"),
  hldRqTotalHours: Yup.string().required("Total hour(s) required").label("Total Hour(s)"),
  // hldRqReason: Yup.string().required("Reason is required").label("Reason"),
  staffRequester: Yup.string().required("Please select Staff Requester").label("Staff Requester"),

});

export const addStaffWorkLoad = Yup.object().shape({
  staffMember: Yup.string().required("Please select Staff member").label("Staff member"),
  holidayAlwnNoOfDys: Yup.number().required("Allowed Days is required"),
  holidayAlwnNoOfHours: Yup.number().required("Allowed Hours is required"),
  holidayHoursRemaining: Yup.number().required("Remaining Hours is required"),
});


export const addStaffTraining = Yup.object().shape({
  prpsName: Yup.string().required("Training title is required").label("Training Title"),
  prpsTrgStartDate: Yup.string()
  .required("Training start date is required")
  .label("Training start date"),

  prpsTrgEndDate: Yup.string()
  .label("Training end date")
  .required("Training end date is required")
  .test(
    'is-greater',
    'End date must be greater than or equal to the start date',
    function (value) {
      const { prpsTrgStartDate } = this.parent;

      console.log("Raw Start Date:", prpsTrgStartDate);
      console.log("Raw End Date:", value);

      // Convert to moment objects
      const startDate = moment(prpsTrgStartDate, "YYYY-MM-DD", true);
      const endDate = moment(value, "YYYY-MM-DD", true);

      console.log("Formatted Start Date:", startDate.format("YYYY-MM-DD"));
      console.log("Formatted End Date:", endDate.format("YYYY-MM-DD"));

      // Validate both dates
      if (!startDate.isValid() || !endDate.isValid()) {
        return this.createError({
          path: this.path,
          message: "End date must be greater than or equal to start date",
        });
      }

      return endDate.isSameOrAfter(startDate);
    }
  ),
  trainingHours: Yup.number().required("Training hours is required").label("Training Hour(s)"),
  prpsDescription: Yup.string().required("Training description is required").label("Training Description"),

  // hldRqReason: Yup.string().required("Reason is required").label("Reason"),
  // staffRequester: Yup.string().required("Please select Staff Requester").label("Staff Requester"),

});