import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Input from "../../../components/forms-fields/Input";
import { Modal, Card, Button } from "react-bootstrap";
import menuToggleVertical from "../../../assets/images/icons/menuToggleVertical.svg";
import submit from "../../../assets/images/icons/message-chat/submit.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import actionButtonDel from "../../../assets/images/icons/actionButtonDel.svg";
import actionButtonTick from "../../../assets/images/icons/actionButtonTick.svg";

import {
  GET_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
  POST_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
  DELETE_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
  PUT_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
  IS_SUPERADMIN,
} from "../../../env";
import {
  getRecords,
  postRecords,
  catchAsync,
  toastErrorMessages,
  toastSuccessMessages,
  isSuccess,
  updateFormFields,
} from "../../../helper/helper";
import TextArea from "../../../components/forms-fields/TextArea";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';

function CommentsBoxCompliance({ userDataRecord }) {
  const initialUserData = userDataRecord;
  const userId = userDataRecord?._id;
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    commentIn: "complianceDoc",
    staffId: userId && userId,
  };
  const [comments, setComments] = useState([]);
  const [isToastCompliances, setIsToastCompliances] = useState(false);
  const [isEditableComment, setIsEditableComment] = useState(false);

  const commentSchema = Yup.object().shape({
    comment: Yup.string().required("Comment is required"),
  });

  const addNewCommentFormData = useFormik({
    initialValues: {
      staffId: userId && userId,
      commentIn: "complianceDoc",
      comment: "",
    },
    validationSchema: commentSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const trimmedValues = {
          staffId: values?.staffId?.trim(),
          commentIn: values?.commentIn?.trim(),
          comment: values?.comment?.trim(),
        };
        await addComment(trimmedValues);
        resetForm();
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error adding comment. Please try again.",
          "",
          1000,
          1000
        );
      }
    },
  });

  const updateCommentFormData = useFormik({
    initialValues: {
      commentId: "",
      comment: "",
    },
    validationSchema: commentSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const trimmedValues = {
          commentId: values?.commentId?.trim(),
          comment: values?.comment?.trim(),
        };
        await updateComment(trimmedValues);
        setIsEditableComment(false);
        resetForm();
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error adding comment. Please try again.",
          "",
          1000,
          1000
        );
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = updateCommentFormData;

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
    } else if (method === "get" && data?.status === isSuccess) {
      setComments(data?.data);
    } else if (method === "put" && data?.status === isSuccess) {
      setIsToastCompliances(true);
      fetchAllRecords();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    } else if (method === "delete" && data?.status === isSuccess) {
      setIsToastCompliances(true);
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
      fetchAllRecords();
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(
      GET_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
      apiQuery
    );
    processFetchRecords("get", response);
  });

  const updateComment = catchAsync(async (req, res) => {
    const response = await postRecords(
      PUT_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
      req
    );
    processFetchRecords("put", response);
  });

  const addComment = catchAsync(async (req, res) => {
    const response = await postRecords(
      POST_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
      req
    );
    processFetchRecords("put", response);
  });

  const handleDeleteComments = catchAsync(async (item) => {
    const response = await postRecords(
      DELETE_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
      {
        commentId: item?._id,
      }
    );
    processFetchRecords("delete", response);
  });

  const handleEditComment = (item) => {
    setFieldValue("commentId", item?._id);
    setFieldValue("comment", item?.comment);
    setIsEditableComment(true);
  };

  const handleCloseEditableComment = async (e) => {
    e.preventDefault();
    setIsEditableComment(false);
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  return (
    <>
      <div className="messageWrapper">
        <div className="messageContainer">
          <div className="roomSpace">
            <div className="messagesPanel">
              {comments?.map((item) => (
                <div
                  key={item?.id}
                  className="messageBoxWrapper receiverWrapper"
                >
                  <div className="recieverMessageBox">
                    <div className="receiverMessage">
                      <div className="textMessage">
                        {isEditableComment &&
                          values?.commentId === item?._id ? (
                          <>
                            <TextArea
                              name="comment"
                              value={values?.comment}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="editableTextArea form-control"
                              placeholder="Save Comment"
                              customError={touched?.comment && errors?.comment}
                              errorMessage={errors?.comment}
                              errorClass={
                                touched?.comment && errors?.comment
                                  ? "validationError"
                                  : ""
                              }
                            />
                            <div className="editableActionButton float-end cursor-pointer">
                              <span
                                className="editQuestion"
                                onClick={handleSubmit}
                              >
                                <img src={actionButtonTick} alt="Tick Answer" />
                              </span>
                              <span
                                className="addQuestion"
                                onClick={handleCloseEditableComment}
                              >
                                <img src={actionButtonDel} alt="Close" />
                              </span>
                            </div>
                          </>
                        ) : (
                          item?.comment
                        )}
                      </div>
                      

                        <Dropdown className="messageDropDownButton">
                          <Dropdown.Toggle>
                            <img
                              src={menuToggleVertical}
                              alt="Message Drop Down"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            
                              <Dropdown.Item
                                onClick={() => handleEditComment(item)}
                              >
                                Edit
                              </Dropdown.Item>
                            
                           
                              <Dropdown.Item
                                onClick={() => handleDeleteComments(item)}
                              >
                                Delete
                              </Dropdown.Item>
                            
                          </Dropdown.Menu>
                        </Dropdown>

                      
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="messagesActions">
              <footer>
                <div className="textMessage ">
                  <TextArea
                    name="comment"
                    value={addNewCommentFormData?.values?.comment}
                    onChange={addNewCommentFormData?.handleChange}
                    onBlur={addNewCommentFormData?.handleBlur}
                    className="editableTextArea form-control"
                    placeholder="Save Comment"
                    rows={1}
                    customError={
                      addNewCommentFormData?.touched?.comment &&
                      addNewCommentFormData?.errors?.comment
                    }
                    errorMessage={addNewCommentFormData?.errors?.comment}
                    errorClass={
                      addNewCommentFormData?.touched?.comment &&
                        addNewCommentFormData?.errors?.comment
                        ? "validationError"
                        : ""
                    }
                  />
                </div>
                <div className="submit">
                  <button onClick={addNewCommentFormData?.handleSubmit}>
                    <img src={submit} alt="" />
                  </button>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
      {isToastCompliances && <ToastContainer />}
    </>
  );
}

export default CommentsBoxCompliance;
