import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Layout from "../../../../../../components/layouts/Layout";
import ProgressComponent from "../../../../../../components/general/ProgressComponent";
import editBlock from "../../../../../../assets/images/icons/editBlock.svg";
import eventDel from "../../../../../../assets/images/icons/eventDel.svg";
import downloadIcon from "../../../../../../assets/images/icons/downloadIcon.svg";
import password_eye_icon_circle from "../../../../../../assets/images/icons/password_eye_icon_circle.svg";
import humanBody from "../../../../../../assets/images/humanBody.svg";
import circleHealthIssue from "../../../../../../assets/images/icons/circleHealthIssue.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser from 'html-react-parser';
import moment from 'moment';
import {
  GET_CARE_PLAN_URL,
  DELETE_CARE_PLAN_URL,
} from "../../../../../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
  ExportButton,
  pdfStyles
} from "../../../../../../helper/helper";
import ProtectedComponent from '../../../../../../permissions/ProtectedComponent';
import { permissions } from '../../../../../../permissions/roles';
import UserDetailWithStatus from "../../../../../../components/user-detail/UserDetailWithStatus";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from 'react-bootstrap/Dropdown';
// BlobProvider, Canvas, Circle, ClipPath, Defs, Document, Ellipse, Font, G, Image, Line, LinearGradient, Link, Note, PDFDownloadLink, PDFViewer, Page, Path, Polygon, Polyline, RadialGradient, Rect, Stop, StyleSheet, Svg, Text, TextInstance, Tspan, View, createRenderer, default, pdf, render, renderToBuffer, renderToFile, renderToStream, renderToString, usePDF, version
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Line, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import CarePlanHealthIssues from "../../../../../health-issue/CarePlanHealthIssues";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PermissionWrapper from "../../../../../../permission_advance/PermissionWrapper";
import IsSafeguardingReviewed from "./IsSafeguardingReviewed";
function Safeguarding() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isReviewed, setIsReviewed] = useState(false);
  const [userData, setUserData] = useState([]);
  const apiQuery = {
    query: {
      critarion: {
        userId: userDetails?._id,
      },
    },
    sortproperty: "eventDateTime",
    sortorder: -1
  };
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [careNoteIdData, setCareNoteIdData] = useState({
    id: null,
  });
  const [carePlanDetail, setCarePlansDetail] = useState();
  console.log("🚀 ~ carePlanDetail:", carePlanDetail)
  const [isCarePlan, setIsCarePlan] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [pdfDocumentTitle, setPdfDocumentTitle] = useState("");
  const navigate = useNavigate();

  // View Care Plan Detail Modal Popup
  const [showViewCarePlan, setShowViewCarePlan] = useState(false);
  const subModalViewCarePlans = (details) => {
    setCarePlansDetail(details);
    setShowViewCarePlan(!showViewCarePlan);
    fetchAllRecords();
  };

  // Modal Popup

  const [showDeleteCarePlan, setShowDeleteCarePlan] = useState(false);
  const subModalDeleteCareNotes = (careNoteDelId) => {
    setCareNoteIdData({ id: careNoteDelId });
    setShowDeleteCarePlan(!showDeleteCarePlan);
    fetchAllRecords();
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const careNoteData = data?.data?.carePlans?.filter((item) => item.active === true && item.noteType === "Safeguarding");
      console.log("🚀 ~ processFetchRecords ~ careNoteData:", careNoteData)
      setUserData(careNoteData);
      setFilterSearch(careNoteData);
      setIsLoading(false);
      setPdfDocumentTitle("Safeguarding")
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsCarePlan(false);
      }, 2000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_CARE_PLAN_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Table Columns
  const columns = [
    {
      name: "Event Date / Time",
      selector: (row) => {
        return <p>{moment(row.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</p>;
      },
      width: "20%",
      wrap: true
    },
    {
      name: "Entered By",
      selector: (row) => {
        return (
          <p>
            <strong>{row?.addedby?.name}</strong>
            <br />
            {moment(row.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}
          </p>
        );
      },
      width: "30%",
      wrap: true,
    },
    {
      name: "Title",
      selector: (row) => {
        return (

          <p>
            {row?.title}<br />
            {
              row?.isReviewed ? (
                <small className="text-success"> Reviewed</small>
              ) : (
                <small className="text-danger"> Not Reviewed</small>
              )
            }
          </p>
        );
      },
      width: "35%",
      wrap: true
    },

    {
      name: "",
      selector: (row) => {
        return (
          <div className="actionColumns individualReviewsActions">
            <span className="edit" onClick={() => {
              subModalViewCarePlans(row);
            }}>
              <img src={password_eye_icon_circle} alt="View" title="View" />
            </span>

            <span
              className="edit"
              onClick={() => {
                editGeneralNotes(row);
              }}
            >
              <img src={editBlock} alt="Edit" title="Edit" />
            </span>


            <span
              className="delete"
              onClick={() => {
                subModalDeleteCareNotes(row?._id);
              }}
            >
              <img src={eventDel} alt="Delete" title="Delete" />
            </span>

            <span className="delete">
              <IndividualDocumentDownload rowData={row} />
            </span>
          </div>
        );

      },
      center: true,
      width: "15%",
      wrap: true,
    },

  ];


  // Delete Care Notes
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (careNoteIdData !== "") {
      const response = await deleteRecords(
        DELETE_CARE_PLAN_URL,
        careNoteIdData
      );
      console.log("🚀 ~ deleteRecord ~ response:", response)
      setIsCarePlan(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteCarePlan(!showDeleteCarePlan);
    } else {
      setShowDeleteCarePlan(!showDeleteCarePlan);
      setIsSubmitButtonClicked(false);
      setIsCarePlan(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setIsCarePlan(false);
    }, 2000);
  }, [isCarePlan])


  useEffect(() => {
    const result = userData.filter((user) => {
      const eventDatTimeLive = moment(user?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")
      return eventDatTimeLive.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, userData]);

  const editGeneralNotes = (item) => {
    navigate("/care-plan/edit-safeguarding-notes", { state: { editCarePlanDetail: [item] } });
  };



  const MyDocument = () => (
    <Document>
      <Page size="A4" orientation="landscape" style={pdfStyles.pages}>
        <View>
          <Text style={pdfStyles.sectionTitle}>Care Plan Note - {pdfDocumentTitle.replace(/([a-z])([A-Z])/g, '$1 $2')}</Text>
          <Text style={pdfStyles.sectionSubTitle}>Service User - {userDetails?.modelId?.suFirstMiddleName} {userDetails?.modelId?.suLastName}</Text>
          <View style={pdfStyles.tableHead}>
            <Text style={{ width: "25%" }}>Event Date / Time</Text>
            <Text style={{ width: "25%" }}>Entered By</Text>
            <Text style={{ width: "50%" }}>Note Details</Text>
          </View>
          {filterSearch.map((row, index) => (
            <View key={index} style={pdfStyles.tabBody}>
              <Text style={{ width: "25%" }}>{moment(row.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</Text>
              <Text style={{ width: "25%" }}>
                {row.addedby.name}
                {"\n"}
                {moment(row.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}
              </Text>
              <Text style={[pdfStyles.wordwrap, { width: "50%" }]}>
                {row.noteDetails.replace(/<\/p>/g, '\n\n').replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]+>/g, '')}
                {"\n"}
                Effected Body Part: {row?.healthIssueId?.category?.name}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );



  const IndividualPDFDocument = ({ rowData }) => (
    <>
      <Document>
        <Page size="A4" orientation="landscape" style={pdfStyles.pages}>
          <View>
            <Text style={pdfStyles.sectionTitle}>Individual Care Plan Note - {pdfDocumentTitle.replace(/([a-z])([A-Z])/g, '$1 $2')}</Text>
            <Text style={pdfStyles.sectionSubTitle}>Service User - {userDetails?.modelId?.suFirstMiddleName} {userDetails?.modelId?.suLastName}</Text>

            <>
              <View style={pdfStyles.singleDataBody}>
                <Text>
                  <Text style={pdfStyles.singleDataBodyHeding}>Create Date:</Text>
                  {moment(rowData.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}
                </Text>
                <Line style={pdfStyles.line} />
                <Text>
                  {rowData.addedby.name}
                  {"\n"}
                  {moment(rowData.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}
                </Text>
                <Text>
                  {"\n"}
                  {rowData.noteDetails.replace(/<\/p>/g, '\n\n').replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]+>/g, '')}
                  {"\n"}
                  Effected Body Part: {rowData?.healthIssueId?.category?.name}
                </Text>
              </View>
              {/* <View>
                <Image src={"https://i.stack.imgur.com/0eNu5.png"} style={pdfStyles.imageHumanBody} />
              </View> */}
            </>

          </View>
        </Page>
      </Document>
    </>
  );

  const IndividualDocumentDownload = ({ rowData }) => (
    <PDFDownloadLink document={<IndividualPDFDocument rowData={rowData} />} fileName={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName + " - Safeguarding Care Notes"}>
      {({ loading }) => (loading ? 'Loading...' : <img src={downloadIcon} alt="Download" />)}
    </PDFDownloadLink>
  );

  return (
    <>
      <Layout pageTitleText="Safeguarding Notes">
        <UserDetailWithStatus careNotesOptions={true} />
        {/* <PDFViewer style={{ width: "100%", height: "300px" }}>
          <MyDocument />
        </PDFViewer> */}
        <div className="careNotes">
          <div className="dataTableWrapper exportDataTable">
            <DataTable
              className="maxWidthTable minWidthTable"
              columns={columns}
              data={filterSearch}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationPerPage={10}
              fixedHeader
              fixedHeaderScrollHeight="600px"
              highlightOnHover
              actions={
                <>
                  <PermissionWrapper title="Safeguarding" action="create">
                    <Link
                      to={"/care-plan/add-safeguarding"}
                      className="btn btn-sm btn-primary mt-2"
                    >
                      Add Care Note
                    </Link>
                  </PermissionWrapper>
                  {
                    filterSearch.length > 0 && (
                      <Dropdown>
                        <Dropdown.Toggle variant="outline-success" size="sm" id="dropdown-basic">
                          Export Data
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="exportDataList">
                          <>
                            <span className="">
                              <PDFDownloadLink document={<MyDocument columns={columns} data={filterSearch} />} fileName={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName + " - Safeguarding Care Notes"}>
                                {({ loading }) =>
                                  loading ? '...' : 'Export as PDF'
                                }
                              </PDFDownloadLink>
                            </span>
                            <span className="">
                              <ExportButton data={filterSearch} patientName={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName + " - " + pdfDocumentTitle.replace(/([a-z])([A-Z])/g, '$1 $2') + " - Care Notes"} />
                            </span>
                          </>

                        </Dropdown.Menu>
                      </Dropdown>
                    )
                  }
                </>
              }
              responsive={true}
              subHeader={true}
              subHeaderComponent={
                <div className="searchContainer">
                  <div className="searchTextBox">
                    <input
                      type="text"
                      placeholder="Search by Date"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              }
              subHeaderAlign="left"
              subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
              progressPending={isLoading}
              progressComponent={<ProgressComponent />}
            />
          </div>
        </div>
      </Layout>

      {/* View Care Notes */}
      <Modal
        show={showViewCarePlan}
        onHide={subModalViewCarePlans}
        className="ftcModalPopup medicineInventoryStock"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Care Note</Modal.Title>
        </Modal.Header>
        <Modal.Body className="careNotesPopup">
          <div className="row">
            <div className="col-md-12 mt-3">
              <span className='isReviewed'>
                <IsSafeguardingReviewed
                  isReviewed={carePlanDetail?.isReviewed === true}
                  setIsReviewed={setIsReviewed}
                  carePlanData={carePlanDetail} />
              </span>
            </div>
            <div className="col-md-4">
              <p>
                <strong>{carePlanDetail?.addedby?.name}</strong>
                <small>{moment(carePlanDetail?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</small></p>
            </div>
            <div className="col-md-8">
              {
                carePlanDetail?.title !== '' && (
                  <p><strong>Title:</strong> {carePlanDetail?.title}</p>
                )
              }

            </div>
            {ReactHtmlParser(String(carePlanDetail?.noteDetails))}
            <div className="col-md-12">
              <p>
                <strong>Effected Body Part: </strong>
                {carePlanDetail?.healthIssueId?.category?.name}
              </p>
            </div>
            <div className="profileDetailWrapper currentHealthIssuesWrapper">
              <div className="userDetail currentHealthIssues carePlanCurrentHealthIssues">
                <div className="partsOfBodyContent p-0">
                  <div className="humanBody">
                    {/* <ClickableHealthCategories addHealthIssueOnClick={addHealthIssueOnClick} /> */}
                    {/* ${item?.enum} */}
                    {/* ${item?.status ? "activeCirlce" : "notActiveCirlce"} */}
                    <div className={`targetPoint ${carePlanDetail?.healthIssueId?.category?.enum}`}>

                      <OverlayTrigger
                        overlay={<Tooltip className="careNoteToolTip">{carePlanDetail?.healthIssueId?.category?.name}</Tooltip>}
                        placement="top"
                        delayShow={300}
                        delayHide={150}

                      >
                        <img
                          className={`target`}
                          src={circleHealthIssue}
                          alt=""
                        />
                      </OverlayTrigger>

                    </div>
                    <img src={humanBody} alt="Human Body" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      {/* Delete Care Note */}

      <Modal
        show={showDeleteCarePlan}
        onHide={subModalDeleteCareNotes}
        className="ftcModalPopup confirmationPopup deletionConfirmation"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="editBlockContent text-center">
            <img src={eventDel} alt={eventDel} />
            <p>Are you sure you want to delete this record?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSubmitButtonClicked ? (
            <Button className="btn-sm" variant="danger" disabled>
              Loading...
            </Button>
          ) : (
            <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
              Yes
            </Button>
          )}

          <Button
            className="btn-sm"
            variant="outline-secondary"
            onClick={subModalDeleteCareNotes}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {isCarePlan && <ToastContainer />}
    </>
  );
}

export default Safeguarding