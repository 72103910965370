import React, { useState } from "react";
import ServiceUsersOptionsBlocks from "./ServiceUsersOptionsBlocks";
import Appointments from "../../assets/images/icons/user-profile/Appointments.svg";
import CarePlans from "../../assets/images/icons/user-profile/CarePlans.svg";
import ConsentCapacity from "../../assets/images/icons/user-profile/ConsentCapacity.svg";
import CurrentHealthIssues from "../../assets/images/icons/user-profile/CurrentHealthIssues.svg";
import documents from "../../assets/images/icons/user-profile/documents.svg";
import eMAR from "../../assets/images/icons/user-profile/eMAR.svg";
// import LifeHistoryGoals from "../../assets/images/icons/user-profile/LifeHistoryGoals.svg";
import MedicineStock from "../../assets/images/icons/user-profile/MedicineStock.svg";
import RiskAssessments from "../../assets/images/icons/careplan-options/RiskAssessments.png";
import PersonalCare from "../../assets/images/icons/careplan-options/PersonalCare.svg";
import RecordanIncident from "../../assets/images/icons/careplan-options/RecordanIncident.svg";
import PatientReview from "../../assets/images/icons/careplan-options/PatientReview.svg";
import { useNavigate } from "react-router-dom";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";
function PatientOptions({ getUserRecord, viewUserProfile }) {
  // console.log("viewUserProfile function:", viewUserProfile);
  // const getUserRecord = getUserRecord;
  const navigate = useNavigate();
  const handleRowClickStaffMember = (url, item) => {
    navigate(url, { state: { userDetails: [item] } });
  };
  return (
    <>
      <div className="scheduleBlocks careplanOptions">
        <div className="row">
          <PermissionWrapper title="Consent & Capacity" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={ConsentCapacity}
                heading={"Consent & Capacity"}
                linkedComponent={() => { handleRowClickStaffMember("/consent-capacity", getUserRecord) }}
              />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="Health Issues" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={CurrentHealthIssues}
                heading={"Current Health Issues"}
                linkedComponent={() => { handleRowClickStaffMember("/current-health-issues", getUserRecord) }}
              />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="Appointments" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={Appointments}
                heading={"Appointments"}
                linkedComponent={() => { handleRowClickStaffMember("/appointments", getUserRecord) }}
              />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="Risk Assessment" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={RiskAssessments}
                heading={"Risk Assessments"}
                linkedComponent={() => { handleRowClickStaffMember("/risk-assessments", getUserRecord) }}
              />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="Care Plan Review" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={CarePlans}
                heading={"Care Plans"}
                linkedComponent={() => { handleRowClickStaffMember("/care-notes", getUserRecord) }} />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="Medicine Stock" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={MedicineStock}
                heading={"Medicine Stock"}
                linkedComponent={() => { handleRowClickStaffMember("/medicine-stock", getUserRecord) }} />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="Emar" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={eMAR}
                heading={"eMAR"}
                linkedComponent={() => { handleRowClickStaffMember("/emar", getUserRecord) }} />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="Documents" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={documents}
                heading={"Documents"}
                linkedComponent={() => { handleRowClickStaffMember("/documents", getUserRecord) }} />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="PBS Review" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={PersonalCare}
                heading={"PBS Plans"}
                linkedComponent={() => { handleRowClickStaffMember("/pbs-plans", getUserRecord) }} />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="Recent Incidents" action="view">
            <div className="col-md-3 col-sm-6">
              <ServiceUsersOptionsBlocks
                blockIcon={RecordanIncident}
                heading={"Record an Incident"}
                linkedComponent={() => { handleRowClickStaffMember("/recent-incidents", getUserRecord) }} />
            </div>
          </PermissionWrapper>
          <PermissionWrapper title="Internal Reviews" action="view">
            <div className="col-md-3 col-sm-6 patientReviewCard">
              <ServiceUsersOptionsBlocks
                blockIcon={PatientReview}
                heading={"Internal Reviews"}
                linkedComponent={() => { handleRowClickStaffMember("/individual-reviews", getUserRecord) }} />
            </div>
          </PermissionWrapper>
        </div>
      </div>
    </>
  );
}

export default PatientOptions;
