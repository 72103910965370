import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import { permissions } from '../../permissions/roles';
import useColumnsWithPermission from "../../permissions/useColumnsWithPermission";
import {
  GET_PAYROLL_LIST,
  BASE_URL,
  ADMIN_ID,
} from "../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  catchAsync,
  PayrollExportButton,
} from "../../helper/helper";
import Dropdown from 'react-bootstrap/Dropdown';
import PayrollBlocks from "./components/PayrollBlocks";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import '@progress/kendo-theme-default/dist/all.css';
import moment from "moment";
import Input from "../../components/forms-fields/Input";
import infoIcon from "../../assets/images/icons/infoIcon.svg";
import RateInfo from "./components/RateInfo";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import AddPayroll from "./finalization/AddPayroll";
import { Link } from "react-router-dom";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";
function PayrollGroup() {

  const [payrollStartDate, setPayrollStartDate] = useState(moment().startOf("isoWeek").valueOf());
  const [payrollEndDate, setPayrollEndDate] = useState(moment().endOf("isoWeek").valueOf());
  const [viewPayrollTemp, setViewPayrollTemp] = useState(false);
  const [payrollViewData, setPayrollViewData] = useState();

  const [payrollInfo, setPayrollInfo] = useState(false)
  const [payrollInfoPopup, setPayrollInfoPopup] = useState(null)
  // console.log("🚀 ~ PayrollGroup ~ payrollInfoPopup:", payrollInfoPopup)

  const handlePayrollInfo = (item) => {
    setPayrollInfo(!payrollInfo)
    setPayrollInfoPopup(item)
  }



  const [payrollDataList, setPayrollDataList] = useState([]);
  // console.log("🚀 ~ PayrollGroup ~ payrollDataList:", payrollDataList)

  const [apiQuery, setApiQuery] = useState({
    startDate: moment(payrollStartDate).valueOf(),
    endDate: moment(payrollEndDate).valueOf()
  })

  useEffect(() => {
    setApiQuery({
      startDate: payrollStartDate,
      endDate: payrollEndDate
    })
  }, [payrollStartDate, payrollEndDate])
  // console.log("🚀 ~ PayrollGroup ~ apiQuery:", apiQuery)
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);


  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const payrollData = data?.data?.totalDocumentsResult?.map((item) => item);
      setPayrollDataList(payrollData);
      setFilterSearch(payrollData);
      setIsLoading(false);
      // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    } else if (method === "delete" && data?.status === isSuccess) {
      console.log(data?.message)

    }

  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_PAYROLL_LIST, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, [apiQuery]);
  // console.log("🚀 ~ PayrollGroup ~ apiQuery:", apiQuery)

  const [overTimeHours, setOverTimeHours] = useState(null)
  // Table Columns

  const columns = [
    {
      name: "Name",
      // selector: (row) => row?.staffMemberName || "Unknown",
      selector: (row) => {
        return (
          <span className="usersNameInfo mt-2 mb-2">
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.user?.profile_picture_url}`}
                alt={row?.user?.name}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row?.user?.name}`}</span>
              <span className="nameBlock"><small>{`${row?.user?.email}`}</small></span>
            </span>
            <div className="infoBlock">
              <img
                className="curSorPointer"
                onClick={() => handlePayrollViewClick(row)}
                src={infoIcon}
                alt="Settings"
              />
            </div>
          </span>
        );
      },
      sortable: true,
      id: "staffMemberName",
      sortFunction: (a, b) => a.staffMemberName.toLowerCase().localeCompare(b.staffMemberName.toLowerCase()),
    },
    {
      name: "Contracted (Hrs)",
      selector: (row) => {
        return row?.payrollData?.contractedHours;
      },
      width: "150px",
      center: true
    },

    {
      name: "Training (Hrs)",
      selector: (row) => {
        return row?.payrollData?.trainingHours;
      },
      width: "150px",
      center: true
    },
    {
      name: "Completed (Hrs)",
      selector: (row) => {
        return row?.assignedtotal;
      },
      width: "150px",
      center: true
    },
    {
      name: "Holiday (Hrs)",
      selector: (row) => {
        return row?.holidayHours;
      },
      width: "150px",
      center: true
    },
    {
      name: "Sick (Hrs)",
      selector: (row) => {
        return row?.holidaySickLeaves;
      },
      width: "150px",
      center: true
    },
    {
      name: "Overtime (Hrs)",
      selector: (row) => {
        return row?.payrollData?.overTimeMonthlyHours;
      },
      width: "150px",
      center: true
    },
    {
      name: (
        <div className="text-center">
          Total Hours <br /> <small>Excluding Sleeping Shifts</small>
        </div>
      ),
      selector: (row) => {
        return row?.payrollData?.totalHours;
      },
      width: "180px",
      center: true
    },
    {
      name: "Sleeping Shifts",
      selector: (row) => {
        return row?.sleepingCount;
      },
      width: "150px",
      center: true
    },
    {
      name: "Grand Total (£)",
      selector: (row) => {
        return (
          <strong>
            {(row?.payrollData?.grandTotal) || 0}
          </strong>
        )
      },
      width: "150px",
      center: true
    },
  ];


  const exportColumns = [
    {
      name: "Name",
      selector: (row) => {
        return row?.staffMemberName;
      },
      sortable: true,
      id: "staffMemberName",
      sortFunction: (a, b) => a.staffMemberName.toLowerCase().localeCompare(b.staffMemberName.toLowerCase()),
    },
    {
      name: "Email",
      selector: (row) => {
        return row?.user?.email;
      },
      sortable: true,
      id: "staffMemberName",
      sortFunction: (a, b) => a.staffMemberName.toLowerCase().localeCompare(b.staffMemberName.toLowerCase()),
    },
    {
      name: "Contracted (Hrs)",
      selector: (row) => {
        return row?.payrollData?.contractedHours;
      },
      width: "150px",
      center: true
    },

    {
      name: "Training (Hrs)",
      selector: (row) => {
        return row?.payrollData?.trainingHours;
      },
      width: "150px",
      center: true
    },
    {
      name: "Completed (Hrs)",
      selector: (row) => {
        return row?.assignedtotal;
      },
      width: "150px",
      center: true
    },
    {
      name: "Holiday (Hrs)",
      selector: (row) => {
        return row?.holidayHours;
      },
      width: "150px",
      center: true
    },
    {
      name: "Sick (Hrs)",
      selector: (row) => {
        return row?.holidaySickLeaves;
      },
      width: "150px",
      center: true
    },
    {
      name: "Overtime (Hrs)",
      selector: (row) => {
        return row?.payrollData?.overTimeMonthlyHours;
      },
      width: "150px",
      center: true
    },
    {
      name: "Total Hours Excluding Sleeping Shifts",
      selector: (row) => {
        return row?.payrollData?.totalHours;
      },
      width: "180px",
      center: true
    },
    {
      name: "Sleeping Shifts",
      selector: (row) => {
        return row?.sleepingCount;
      },
      width: "150px",
      center: true
    },
    {
      name: "Grand Total (£)",
      selector: (row) => {
        return (
          row?.payrollData?.grandTotal || 0
        )
      },
      width: "150px",
      center: true
    },
  ];

  // Search Filter
  useEffect(() => {
    const result = payrollDataList.filter((user) => {
      return user.staffMemberName.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, payrollDataList]);


  const pdfExportComponent = useRef(null);
  const [showInPrint, setShowInPrint] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const exportPDF = () => {
    if (pdfExportComponent.current) {
      setShowInPrint(true);
      setIsSubmitButtonClicked(true)
      setTimeout(() => {
        pdfExportComponent.current.save(() => {
          // Callback function after save
          setTimeout(() => {
            setShowInPrint(false);
            setIsSubmitButtonClicked(false)
          }, 100);
        });
      }, 100);
    }
  };

  const handlePayrollViewClick = (row) => {
    setViewPayrollTemp(!viewPayrollTemp);
    setPayrollViewData(row)
  };


  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  useEffect(() => {
    generatePayrollDates(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);

  const generatePayrollDates = (year, month) => {
    const startDate = moment(`${year}-${month}-15`);
    const endDate = moment(startDate).add(1, 'month').date(14);

    setPayrollStartDate(startDate.valueOf());
    setPayrollEndDate(endDate.valueOf());
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const months = moment.months(); // Array of month names
  const [startDate, setStartDate] = useState(new Date());


  // Adding Payroll 
  const [selectedPayrollUsers, setSelectedPayrollUsers] = useState([]);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [addPayrollTemp, setAddPayrollTemp] = useState(false);


  const handlePayrollAddClick = () => {
    setAddPayrollTemp(!addPayrollTemp);
    setToggleClearRows(addPayrollTemp ? true : false)
  };


  // Update this function to use `selectedRows` instead of `selectedUsers`
  const handleSelectedPayrollUsersChange = ({ selectedRows }) => {
    setSelectedPayrollUsers(selectedRows);
  };

  const [listOfSelectedUser, setListOfSelectedUser] = useState([])

  // Update listOfSelectedUser when selectedPayrollUsers changes
  useEffect(() => {
    if (selectedPayrollUsers.length !== 0) {
      setListOfSelectedUser(
        selectedPayrollUsers.map(staff => ({
          userId: staff?._id || "", // Provide default values
          contractHours: staff.contractedHours?.contractedHours || 0,
          trainingHours: staff.trainingHours || 0,
          assignedHours: staff.assignedtotal || 0,
          holidayHours: staff.holidayHours || 0,
          sickHours: staff.holidaySickLeaves || 0,
          sleepHours: staff?.sleepingHours,
          sleepCount: staff?.sleepingCount,
          role: staff.role || "unknown", // Assuming you have a `role` field or provide a default
          dateFinalized: moment().valueOf(),
          fromDate: payrollStartDate || moment().valueOf(),
          toDate: payrollEndDate || moment().valueOf(),
          addedBy: ADMIN_ID || "unknown"
        }))
      );
    }
  }, [selectedPayrollUsers, payrollStartDate, payrollEndDate]);

  return (
    <>
      {/* <PayrollBlocks /> */}
      <div className="rowClickUnset">
        <div class="subTabsWrapper payrollSubHeader">
          <div class="subTabsHeader radioTabsHeader">
            <div class="tabsBlocks">
              <div class="payrollDateFilter">
                <div className={`customSelectBox form-group`}>
                  <select value={selectedMonth} onChange={handleMonthChange}>
                    {months.map((month, index) => (
                      <option key={index} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <label className="floatingLabel">
                    Month
                  </label>
                </div>
                <div className={`customSelectBox form-group`}>
                  <select value={selectedYear} onChange={handleYearChange}>
                    {[...Array(3)].map((_, i) => (
                      <option key={i} value={currentYear - i}>
                        {currentYear - i}
                      </option>
                    ))}
                  </select>
                  <label className="floatingLabel">
                    Year
                  </label>
                </div>

                <Input
                  type="date"
                  name="startDate"
                  selected={payrollStartDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(e) => setPayrollStartDate(moment(e.target.value).valueOf())}
                  disabledStatus={true}
                  datePicker={true}
                />

                <Input
                  type="date"
                  name="endDate"
                  selected={payrollEndDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(e) => setPayrollEndDate(moment(e.target.value).valueOf())}
                  disabledStatus={true}
                  datePicker={true}
                />

                <div className="payrollActionButtons">
                  <span>
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-primary" size="sm" id="dropdown-basic">
                        Export Data
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="exportDataList">
                        <>
                          {isSubmitButtonClicked ? (
                            <span className="curSorPointer">Exporting...</span>
                          ) : (
                            <span className="curSorPointer" onClick={exportPDF}>Export to PDF</span>
                          )}
                          <span>
                            <PayrollExportButton
                              data={filterSearch}
                              patientName={`Payroll From  ${moment(payrollStartDate).format("DD/MM/YYYY")} to  ${moment(payrollEndDate).format("DD/MM/YYYY")}`}
                              columns={exportColumns}
                            />
                          </span>
                        </>

                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                  <PermissionWrapper title="Payroll" action="create">
                    <button
                      className={`btn btn-sm ${selectedPayrollUsers.length !== 0 ? "btn-primary" : "btn-secondary"} `}
                      onClick={handlePayrollAddClick} disabled={selectedPayrollUsers.length !== 0 ? false : true}>
                      Finalize Payroll
                    </button>{" "}
                  </PermissionWrapper>
                  <PermissionWrapper title="Payroll" action="view">
                    <Link to="/final-payroll" className="btn btn-outline-success btn-sm">Final Payroll</Link>
                  </PermissionWrapper>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="mainContent">
          <div className="dataTableWrapper payrolDataTableWrapper">
            <DataTable
              className="maxWidthTable"
              columns={columns}
              data={filterSearch}
              fixedHeader
              fixedHeaderScrollHeight="450px"
              highlightOnHover
              defaultSortFieldId="staffMemberName"
              defaultSortAsc={true}
              selectableRows={true}
              onSelectedRowsChange={handleSelectedPayrollUsersChange}
              clearSelectedRows={toggleClearRows}
              responsive={true}
              subHeader={true}
              subHeaderComponent={
                <div className="searchContainer">
                  <div className="searchTextBox">
                    <input
                      type="text"
                      placeholder="Type your search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              }
              subHeaderAlign="left"
              subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
              progressPending={isLoading}
              progressComponent={<ProgressComponent />}
            />
          </div>
          <div className="exportPdfContainer">
            <PDFExport
              ref={pdfExportComponent}
              paperSize={["1440pt", "900pt"]}
              landscape={true}
              scale={0.8}
              margin={40}
              fileName={`Payroll From  ${moment(payrollStartDate).format("DD/MM/YYYY")} to  ${moment(payrollEndDate).format("DD/MM/YYYY")}`}

            >
              {
                showInPrint && (
                  <>
                    <header><h3>Payroll From  {moment(payrollStartDate).format("DD/MM/YYYY")} to  {moment(payrollEndDate).format("DD/MM/YYYY")}</h3></header><br />
                    <div className="dataTableWrapper payrolDataTableWrapper exportPdfPayroll">
                      <DataTable
                        className="maxWidthTable"
                        columns={columns}
                        data={filterSearch}
                        responsive={true}
                        subHeader={false}
                        progressPending={isLoading}
                        progressComponent={<ProgressComponent />}
                      />
                    </div>
                  </>
                )
              }

            </PDFExport>
          </div>
        </div>
      </div>
      {viewPayrollTemp && (
        <RateInfo
          payrollViewData={payrollViewData}
          onClose={handlePayrollViewClick}
          item={viewPayrollTemp}
        />
      )}
      {addPayrollTemp && selectedPayrollUsers.length !== 0 && (
        <AddPayroll
          selectedPayrollUsers={listOfSelectedUser}
          onClose={handlePayrollAddClick}
        />
      )}
    </>
  );
}

export default PayrollGroup;