import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import menuToggle from "../../assets/images/icons/menuToggle.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import PermissionWrapper from "../../permission_advance/PermissionWrapper";

function HeaderMenu() {
  const [secondaryMenu, setSecondaryMenu] = useState(false);
  return (
    <>
      <IconButton
        className="secondaryMenuToggleIcon"
        color="inherit"
        onClick={() => {
          setSecondaryMenu(!secondaryMenu);
        }}
      >
        <img src={menuToggle} alt="sub menu" />
      </IconButton>
      <ul
        className={`headerMenu ${secondaryMenu ? "mobileSecondaryMenu" : ""}`}
      >

        <PermissionWrapper title="Super Admin" action="view">
          <li>
            <Link to="/advanced">Advanced</Link>
          </li>
        </PermissionWrapper>
        <PermissionWrapper title="Staff" action="view">
        <li>
          <Link to="/staff-records">Staff Members</Link>
        </li>
        </PermissionWrapper>
        <li>
          <Link to="/service-users">Service Users</Link>
        </li>
      </ul>
    </>
  );
}

export default HeaderMenu;
