import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Field, useFormik } from "formik";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import schedulePlus from "../../assets/images/icons/schedulePlus.svg";
import editBlock from "../../assets/images/icons/editBlock.svg";
import deleteBasket from "../../assets/images/icons/eventDel.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import overNightStay from "../../assets/images/icons/overNightStay.svg";
import sleepOverIcon from "../../assets/images/icons/sleepOverIcon.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import menuToggleVertical from "../../assets/images/icons/menuToggleVertical.svg";
import rotaPlus from "../../assets/images/icons/rotaPlus.svg";
import rotaLocation from "../../assets/images/icons/rotaLocation.svg";
import EditRotaScheduleShifts from "./popup/EditRotaScheduleShifts";
import ModifyStaffOpenShift from "./popup/ModifyStaffOpenShift";
import CreateStaffRotaSchedule from "./popup/CreateStaffRotaSchedule";
import DeletePopup from "../../components/modals/DeletePopup";
import {
  ADMIN_ID,
  BASE_URL,
  DELETE_ROTA_SCHEDULE_SHIFT_DAY_URL,
  DELETE_ROTA_SCHEDULE_OPEN_SHIFT_URL,
} from "../../env";
import { openLocationToGoogleMaps, postRecords } from "../../helper/helper";
import {
  handleReload,
  formatTimeRange,
  rotaSchedulesStatusClassMap,
  renderOverlayHours,
} from "../../helper/helperJsx";
import ProgressComponent from "../../components/general/ProgressComponent";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";

function WeeklyTabsStaffView({
  selectedLocationId,
  scheduleTemplate,
  filteredSchedule,
  activeWeekTabNum,
  allStaffRecordsState,
  allRotaTemplates,
  getAllRecords,
}) {
  const [individualUpdateModel, setIndividualUpdateModel] = useState(false);
  const [addSecondIndividualShift, setAddSecondIndividualShift] =
    useState(false);
  const [addNewIndividualDayShift, setAddNewIndividualDayShift] =
    useState(false);
  const [selectedDay, setSelectedDay] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddNewShiftFromStaffListing, setAddNewShiftFromListing] =
    useState(false);
  const [selectedDayFromListing, setSelectedDayFromListing] = useState(false);
  const [isToastErrorWeeklyTabs, setIsToastErrorWeeklyTabs] = useState(false);
  const [isDeleteShiftDayModal, setIsDeleteShiftDayModel] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [generalUpdateState, setGeneralUpdateState] = useState(false);
  const [isDeleteCompleteShiftModel, setIsDeleteCompleteShiftModel] =
    useState(false);

  const deleteCompleteSchedule = async () => {
    setIsDeleteLoading(true);
    const req = {
      tempId: scheduleTemplate?._id,
      shiftId: isDeleteCompleteShiftModel?._id,
      locationId: selectedLocationId,
      lastModifiedBy: ADMIN_ID,
    };
    await postRecords(DELETE_ROTA_SCHEDULE_OPEN_SHIFT_URL, req);
    await getAllRecords();
    setIsDeleteCompleteShiftModel(false);
    setIsDeleteLoading(false);
  };

  const deleteScheduleShiftDay = async () => {
    setIsDeleteLoading(true);
    const data = {
      tempId: scheduleTemplate?._id,
      locationId: selectedLocationId,
      shiftId: isDeleteShiftDayModal?._id,
      dayId: selectedDay?._id,
      lastModifiedBy: ADMIN_ID,
    };
    await postRecords(DELETE_ROTA_SCHEDULE_SHIFT_DAY_URL, data);
    await getAllRecords();
    setIsDeleteLoading(false);
    handleCloseIsDeleteShiftDayModel();
  };

  const handleAddNewShiftFromStaffListing = async (
    e,
    shiftArrayItem,
    dayName
  ) => {
    e?.preventDefault();
    setSelectedDayFromListing(dayName);
    setAddNewShiftFromListing(shiftArrayItem);
  };

  const handleIndividualUpdate = async (e, shiftArrayItem, dayItem) => {
    e?.preventDefault();
    setSelectedDay(dayItem);
    setIndividualUpdateModel(shiftArrayItem);
  };

  const handleAddNewIndividualShift = async (e, shiftArrayItem, dayName) => {
    e?.preventDefault();
    setSelectedDay(dayName);
    setAddNewIndividualDayShift(shiftArrayItem);
  };

  const handleAddIndividual2ndShift = async (e, shiftArrayItem, dayItem) => {
    e?.preventDefault();
    setSelectedDay(dayItem);
    setAddSecondIndividualShift(shiftArrayItem);
  };

  const handleDeleteIndShiftDay = async (e, shiftArrayItem, dayItem) => {
    e?.preventDefault();
    setSelectedDay(dayItem);
    setIsDeleteShiftDayModel(shiftArrayItem);
  };

  const handleOpenLocationToMap = async (e, user) => {
    const suAddress1 = user?.modelId?.suAddress1;
    const suAddress2 = user?.modelId?.suAddress2;
    openLocationToGoogleMaps(suAddress1 || suAddress2);
  };

  const handleGeneralUpdate = async (e, item) => {
    e?.preventDefault();
    setGeneralUpdateState(item);
  };

  const handleDeleteOpenScheduleShift = async (e, item) => {
    e?.preventDefault();
    setIsDeleteCompleteShiftModel(item);
  };

  const handleCloseIndividualUpdateModel = async () => {
    setIndividualUpdateModel(false);
  };

  const handleCloseIndividual2ndShiftModel = async () => {
    setAddSecondIndividualShift(false);
  };

  const handleCloseAddNewIndividualShiftModel = async () => {
    setAddNewIndividualDayShift(false);
  };

  const handleCloseAddStaffSiftDayModal = async () => {
    setAddNewShiftFromListing(false);
  };

  const handleCloseIsDeleteShiftDayModel = async () => {
    setIsDeleteShiftDayModel(false);
  };

  const handleCloseDeleteCompleteShiftModel = async () => {
    setIsDeleteCompleteShiftModel(false);
  };

  const handleCloseGeneralUpdateModel = async () => {
    setGeneralUpdateState(false);
  };

  const renderToolTipShifts = (filteredDays, dayName) => {
    const filterDay = filteredDays?.filter((item) => item?.day === dayName);
    return filterDay?.map((filteredDay) => (
      <div
        className={`updatedRotaContentCellWrapper contentcell blur ${rotaSchedulesStatusClassMap[scheduleTemplate?.status]
          }`}
      >
        <div className="rotaContentCell updatedRotaContentCell">
          <span className="time darkBlack">
            {formatTimeRange(
              filteredDay?.shiftStartTime,
              filteredDay?.shiftEndTime
            )}
            {filteredDay?.isSleepOver ? (
              <span className="sleepOver-icon">
                &nbsp;
                <img src={sleepOverIcon} alt="" />
              </span>
            ) : filteredDay?.isOverNightStay ? (
              <span className="sleepOver-icon">
                &nbsp;
                <img src={overNightStay} alt="" />
              </span>
            ) : null}
          </span>
          <span className="patientName fs-patientName">
            {filteredDay?.patientId?.modelId ? (
              filteredDay?.patientId?.modelId?.suFirstMiddleName +
              "  " +
              "  " +
              filteredDay?.patientId?.modelId?.suLastName
            ) : (
              <span className="patientName fs-patientName c-red">
                Unassigned Shift
              </span>
            )}
          </span>
          <span className="plusIcon cursor-default"></span>
        </div>
        <div className="updateCellIcon">
          <span className="icons">
            <img
              src={rotaLocation}
              alt="Location"
              className="addNewDay cursor-pointer rotaImageWidth"
            />
            <img src={rotaPlus} alt="update" className="cursor-default" />
          </span>
        </div>
      </div>
    ));
  };

  const renderOtherLocationsStaff = (shiftLocations, staffUserId, dayName) => {
    const filterDataByStaffUserId = shiftLocations
      ?.map((item) =>
        item.shiftArray
          ?.filter(
            (shiftObj) => shiftObj?.staffUserId?._id?.toString() === staffUserId
          )
          .map((shiftObj) => ({
            ...item,
            shiftArray: [shiftObj],
          }))
      )
      .flat()
      .filter(Boolean);

    return (
      filterDataByStaffUserId?.length > 0 && (
        <div className="scheduleTableGap dayCell">
          {filterDataByStaffUserId?.map((item) => (
            <OverlayTrigger
              placement="top"

              overlay={
                <Tooltip>
                  <strong className="color-white">
                    {item?.locationId?.shiftLocationName}
                  </strong>
                </Tooltip>
              }
            >
              <div>
                {item?.shiftArray?.map((item) =>
                  renderToolTipShifts(item?.days, dayName)
                )}
              </div>
            </OverlayTrigger>
          ))}
        </div>
      )
    );
  };
  const consoleLog = (item) => {
    // return (console.log("this is stestd", item))
  }
  const renderTableCells = (item) => {
    return [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ]?.map((day) => (
      <td>
        {item?.days?.some((obj) => obj?.day === day) ? (
          <div className="scheduleTableGap dayCell">
            {item?.days
              ?.filter((obj) => obj?.day === day)
              ?.map((filteredDay) => (
                <div
                  className={`updatedRotaContentCellWrapper contentcell ${rotaSchedulesStatusClassMap[scheduleTemplate?.status]
                    }`}
                >
                  <div className="rotaContentCell updatedRotaContentCell">
                    <span className="time darkBlack">
                      TEEEE<br />
                      {formatTimeRange(
                        filteredDay?.shiftStartTime,
                        filteredDay?.shiftEndTime
                      )}
                      {filteredDay?.isSleepOver ? (
                        <span className="sleepOver-icon">
                          &nbsp;
                          <img src={sleepOverIcon} alt="" />
                        </span>
                      ) : filteredDay?.isOverNightStay ? (
                        <span className="sleepOver-icon">
                          &nbsp;
                          <img src={overNightStay} alt="" />
                        </span>
                      ) : null}
                    </span>
                    <span className="patientName fs-patientName">
                      {consoleLog(filteredDay)}
                      {

                        filteredDay?.patientId?.modelId ? (
                          filteredDay?.patientId?.modelId?.suFirstMiddleName +
                          "  " +
                          "  " +
                          filteredDay?.patientId?.modelId?.suLastName
                        ) : (
                          <span className="patientName fs-patientName c-red">
                            Unassigned Shift
                          </span>
                        )}
                    </span>
                    <span className="plusIcon cursor-default">
                      {scheduleTemplate?.status !== "active" ? (
                        <>
                          <PermissionWrapper title="Roster" action="update">
                            <img
                              src={editBlock}
                              alt="Edit"
                              className="edit"
                              onClick={(e) =>
                                handleIndividualUpdate(e, item, filteredDay)
                              }
                            /></PermissionWrapper>
                          <PermissionWrapper title="Roster" action="delete">
                            <img
                              src={deleteBasket}
                              alt="Edit"
                              className="edit"
                              onClick={(e) =>
                                handleDeleteIndShiftDay(e, item, filteredDay)
                              }
                            /></PermissionWrapper>
                        </>
                      ) : null}
                    </span>
                  </div>
                  <div className="updateCellIcon">
                    <span className="icons">
                      <img
                        src={rotaLocation}
                        alt="Location"
                        className="addNewDay cursor-pointer rotaImageWidth"
                        onClick={(e) =>
                          handleOpenLocationToMap(e, item?.patientId)
                        }
                      />
                      {scheduleTemplate?.status !== "active" ? (
                        <img
                          src={rotaPlus}
                          alt="update"
                          className="edit cursor-pointer"
                          onClick={(e) =>
                            handleAddIndividual2ndShift(e, item, filteredDay)
                          }
                        />
                      ) : (
                        <div></div>
                      )}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <span>
            {scheduleTemplate?.status !== "active" ? (
              <PermissionWrapper title="Roster" action="create">
                <img
                  src={schedulePlus}
                  alt="update"
                  className="addNewDay cursor-pointer rotaImageWidth"
                  onClick={(e) => handleAddNewIndividualShift(e, item, day)}
                /></PermissionWrapper>
            ) : null}
          </span>
        )}
        {renderOtherLocationsStaff(
          allRotaTemplates,
          item?.staffUserId?._id,
          day
        )}
      </td>
    ));
  };

  const renderTableHeadingTd = (
    item,
    imageSection,
    nameSection,
    contractHoursHeading,
    contractHours,
    totalHoursHeading,
    totalHours
  ) => {
    return (
      <div className="userInfoWrapper">
        <div className="usersNameInfo pointerEvent-none">
          <div className="userImage">
            <img src={BASE_URL + imageSection} alt={nameSection} />
          </div>
          <div className="userInfo">
            <span className="nameBlock f-s13">{nameSection}</span>
            <span>
              <small className="fs-12">
                <strong>{contractHoursHeading}</strong>
                {contractHours}
                &nbsp;&nbsp;
                <strong>{totalHoursHeading}</strong>
                {totalHours}
              </small>
            </span>
          </div>
        </div>
        {renderOverlayHours(item, menuToggleVertical)}
      </div>
    );
  };

  const renderOpenTableHeadingTd = (item) => {
    return (
      <td className="unAssignedShiftaDay">
        <div className="actionRow">
          <PermissionWrapper title="Roster" action="update">
            <span className="edit" onClick={(e) => handleGeneralUpdate(e, item)}>
              <img src={editBlock} alt="Edit" title="Edit" />
            </span>
          </PermissionWrapper>
          <PermissionWrapper title="Roster" action="delete">
            <span
              className="delete"
              onClick={(e) => handleDeleteOpenScheduleShift(e, item)}
            >
              <img src={eventDel} alt="Delete" title="Delete" />
            </span>
          </PermissionWrapper>
        </div>
        <div className="clearfix"></div>
        <div
          className="usersNameInfo pointerEvent-none text-danger mt-2"
          style={{
            justifyContent: "center",
            padding: "12px",
          }}
        >
          <div className="userImage">&nbsp;</div>
          <div className="userInfo">
            <span className="nameBlock fs-smaller">Unassigned Shift 3</span>
            <span className="actionRows d-flex-start"></span>
          </div>
        </div>
      </td>
    );
  };

  const renderOpenStaffShiftTableCell = (item) => {
    return (
      <tr>
        {renderOpenTableHeadingTd(item)}
        {renderTableCells(item)}
      </tr>
    );
  };

  const renderShiftTableCell = (item) => {
    return (
      <tr>
        <td>
          {item &&
            renderTableHeadingTd(
              item,
              item?.staffUserId?.user?.profile_picture_url,
              item?.staffUserId?.user?.name ||
              item?.staffUserId?.staffMemberName,
              "C:",
              item?.staffUserId?.contractedHours?.contractedHours + "hrs" ||
              0 + "hrs",
              "T:",
              item?.workHrs + "hrs"
            )}
        </td>
        {renderTableCells(item)}
      </tr>
    );
  };

  const renderAllStaffTableRow = (item) => {
    return (
      <tr>
        <td>
          {item &&
            renderTableHeadingTd(
              item,
              item?.user?.profile_picture_url,
              item?.user?.name || item?.staffMemberName,
              "C:",
              "0hrs",
              "T:",
              "0hrs"
            )}
        </td>
        {[
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ]?.map((day) => (
          <td>
            <div className="scheduleTableGap">
              <span>
                {scheduleTemplate?.status !== "active" ? (
                  <PermissionWrapper title="Roster" action="create">
                    <img
                      src={schedulePlus}
                      alt="update"
                      className="addNewDay cursor-pointer rotaImageWidth"
                      onClick={(e) =>
                        handleAddNewShiftFromStaffListing(e, item, day)
                      }
                    /></PermissionWrapper>
                ) : null}
              </span>
            </div>
            {renderOtherLocationsStaff(allRotaTemplates, item?._id, day)}
          </td>
        ))}
      </tr>
    );
  };

  useEffect(() => {
    if (filteredSchedule && allStaffRecordsState) {
      setIsLoading(false);
    }
  }, [
    filteredSchedule,
    scheduleTemplate,
    allStaffRecordsState,
    activeWeekTabNum,
    selectedLocationId,
  ]);

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        filteredSchedule &&
        allStaffRecordsState &&
        (() => {
          const filteredOpenStaffShifts = filteredSchedule?.map((item) => {
            if (!item?.staffUserId) {
              return renderOpenStaffShiftTableCell(item);
            }
            // console.log("🚀 ~ filteredOpenStaffShifts ~ item:", item)
          });

          const filteredShifts = filteredSchedule?.map((item) => {
            if (item?.staffUserId) {
              return renderShiftTableCell(item);
            }
          });

          const filteredStaffIds = new Set(
            filteredSchedule
              ?.map((item) => item?.staffUserId?._id)
              ?.filter(Boolean)
          );

          const unfilteredStaff = allStaffRecordsState?.filter(
            (item) => !filteredStaffIds?.has(item?._id)
          );

          const filteredStaffJsx = unfilteredStaff?.map((item) => {
            return renderAllStaffTableRow(item);
          });

          return (
            <>
              {filteredOpenStaffShifts}
              {filteredShifts}
              {filteredStaffJsx}
            </>
          );
        })()
      )}

      {individualUpdateModel &&
        selectedDay &&
        scheduleTemplate &&
        activeWeekTabNum && (
          <EditRotaScheduleShifts
            handleShowModel={individualUpdateModel}
            handleCloseModel={handleCloseIndividualUpdateModel}
            templateData={scheduleTemplate}
            shiftArrayItem={individualUpdateModel}
            dayItem={selectedDay}
            templateWeekNum={scheduleTemplate?.templateWeeks}
            activeWeekTabNum={activeWeekTabNum}
            selectedLocationId={selectedLocationId}
            fetchAllRecords={getAllRecords}
            isIndividualUpdate={true}
            isAddNewIndividualShift={false}
            isAddSecondIndividualShift={false}
            scheduleTitle={"Update Shift"}
          />
        )}

      {addNewIndividualDayShift &&
        scheduleTemplate &&
        selectedDay &&
        activeWeekTabNum && (
          <EditRotaScheduleShifts
            handleShowModel={addNewIndividualDayShift}
            handleCloseModel={handleCloseAddNewIndividualShiftModel}
            templateData={scheduleTemplate}
            shiftArrayItem={addNewIndividualDayShift}
            dayItem={selectedDay}
            templateWeekNum={scheduleTemplate?.templateWeeks}
            activeWeekTabNum={activeWeekTabNum}
            selectedLocationId={selectedLocationId}
            fetchAllRecords={getAllRecords}
            isIndividualUpdate={false}
            isAddNewIndividualShift={true}
            isAddSecondIndividualShift={false}
            scheduleTitle={"Add New Schedule"}
          />
        )}

      {addSecondIndividualShift &&
        scheduleTemplate &&
        selectedDay &&
        activeWeekTabNum && (
          <EditRotaScheduleShifts
            handleShowModel={addSecondIndividualShift}
            handleCloseModel={handleCloseIndividual2ndShiftModel}
            templateData={scheduleTemplate}
            shiftArrayItem={addSecondIndividualShift}
            dayItem={selectedDay}
            templateWeekNum={scheduleTemplate?.templateWeeks}
            activeWeekTabNum={activeWeekTabNum}
            selectedLocationId={selectedLocationId}
            fetchAllRecords={getAllRecords}
            isIndividualUpdate={false}
            isAddNewIndividualShift={false}
            isAddSecondIndividualShift={true}
            scheduleTitle={"Add New Schedule"}
          />
        )}

      {generalUpdateState &&
        scheduleTemplate &&
        selectedLocationId &&
        activeWeekTabNum && (
          <ModifyStaffOpenShift
            handleShowModel={generalUpdateState}
            handleCloseModel={handleCloseGeneralUpdateModel}
            tempId={scheduleTemplate?._id}
            templateWeekNum={scheduleTemplate?.templateWeeks}
            activeWeekTabNum={activeWeekTabNum}
            shiftArrayItem={generalUpdateState}
            selectedLocationId={selectedLocationId}
            fetchAllRecords={getAllRecords}
            scheduleTitle={"Update Unassigned shiftArrayItem"}
          />
        )}

      {isAddNewShiftFromStaffListing &&
        selectedDayFromListing &&
        scheduleTemplate &&
        activeWeekTabNum && (
          <CreateStaffRotaSchedule
            handleShowModel={isAddNewShiftFromStaffListing}
            handleCloseModel={handleCloseAddStaffSiftDayModal}
            templateData={scheduleTemplate}
            tempId={scheduleTemplate?._id}
            staffMemberObj={isAddNewShiftFromStaffListing}
            dayItem={selectedDayFromListing}
            templateWeekNum={scheduleTemplate?.templateWeeks}
            activeWeekTabNum={activeWeekTabNum}
            selectedLocationId={selectedLocationId}
            fetchAllRecords={getAllRecords}
            scheduleTitle={"Add New Schedule"}
          />
        )}

      {isDeleteShiftDayModal && (

        <DeletePopup
          show={isDeleteShiftDayModal}
          onHide={handleCloseIsDeleteShiftDayModel}
          title="Confirmation"
          content="Are you sure you want to delete this shift?"
          onConfirm={deleteScheduleShiftDay}
          onCancel={handleCloseIsDeleteShiftDayModel}
          isConfirmDeleteLoading={isDeleteLoading}
        />

      )}

      {isDeleteCompleteShiftModel && (

        <DeletePopup
          show={isDeleteCompleteShiftModel}
          onHide={handleCloseDeleteCompleteShiftModel}
          title="Confirmation"
          content="Are you sure you want to delete this Unassigned Shift?"
          onConfirm={deleteCompleteSchedule}
          onCancel={handleCloseDeleteCompleteShiftModel}
          isConfirmDeleteLoading={isDeleteLoading}
        />

      )}

      {isToastErrorWeeklyTabs && <ToastContainer />}
    </>
  );
}

export default WeeklyTabsStaffView;
