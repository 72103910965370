// src/utils/permissions.js

export const getUserPermissions = () => {
    const staffRoles = JSON.parse(localStorage.getItem('staffRoles')) || [];
    return staffRoles; // Return the permissions array from local storage
};

export const hasPermission = (permissions, title, action) => {
    const permission = permissions.find((perm) => perm.title === title);
    return permission ? permission[action] : false;
};
