import React from "react";
import { getUserPermissions, hasPermission } from "../utils/advance_permissions";
// Mock function to simulate fetching user permissions

// Function to check if any action from a list is allowed for a title
export const hasAnyPermissions = (actions, title) => {
  const userPermissions = getUserPermissions();
  return actions.some((action) =>
    userPermissions.some((role) =>
      hasPermission(role.permissions, title, action)
    )
  );
};

// Component to wrap content based on permissions
const PermissionColumn = ({ title, actions, children }) => {
  const isAllowed = hasAnyPermissions(actions, title);

  return isAllowed ? <>{children}</> : null;
};

export default PermissionColumn;


export const filterCarePlans = (carePlans, hasPermissions) => {
  return hasPermissions
    ? carePlans.filter(
      (item) => item.active === true
    )
    : carePlans.filter((item) => item.active === true && item?.noteType !== "Safeguarding");
};

{/* <PermissionUtils title="Roster" actions={["create", "update", "delete"]}></PermissionUtils> */ }