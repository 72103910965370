import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addHealthObservations } from "../../../../../../schemas";
import Observation from "../../../../../../assets/images/Observation.png";
import { useFormik } from "formik";
import ReactDOMServer from 'react-dom/server';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_CARE_PLAN_URL, BASE_URL_PROD } from "../../../../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";

function EditObservations() {
  const location = useLocation();
  const carePlanDetail = location?.state ? location?.state?.editCarePlanDetail[0] : null;
  // console.log("🚀 ~ EditCareNotes ~ carePlanDetail:", carePlanDetail)
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(carePlanDetail?.addedby);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
  };


  const heartRateRef = useRef(null);
  const bloodPressureRef = useRef(null);
  const respiratoryRateRef = useRef(null);
  const oxygenRef = useRef(null);
  const temperatureRef = useRef(null);
  const bloodSugarRef = useRef(null);
  useEffect(() => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = carePlanDetail?.noteDetails || '';

    heartRateRef.current.innerText = tempDiv.querySelector('#heartRateData')?.innerText || '';
    bloodPressureRef.current.innerText = tempDiv.querySelector('#bloodPressureData')?.innerText || '';
    respiratoryRateRef.current.innerText = tempDiv.querySelector('#respiratoryRateData')?.innerText || '';
    oxygenRef.current.innerText = tempDiv.querySelector('#oxygenData')?.innerText || '';
    temperatureRef.current.innerText = tempDiv.querySelector('#temperatureData')?.innerText || '';
    bloodSugarRef.current.innerText = tempDiv.querySelector('#bloodSugarData')?.innerText || '';
  }, [carePlanDetail?.noteDetails]);

  // heartRate: "",
  // bloodPressure: "",
  // respiratoryRate: "",
  // oxygen: "",
  // temperature: "",
  // bloodSugar: "",

  const [suFormData, setSuFormData] = useState({

    addedby: carePlanDetail?.addedby?._id,
    heartRate: "",
    bloodPressure: "",
    respiratoryRate: "",
    oxygen: "",
    temperature: "",
    bloodSugar: "",
    eventDateTime: carePlanDetail?.eventDateTime,
  });
  useEffect(() => {

    setValues(prevState => ({
      ...prevState,
      heartRate: heartRateRef.current?.innerText || '',
      bloodPressure: bloodPressureRef.current?.innerText || '',
      respiratoryRate: respiratoryRateRef.current?.innerText || '',
      oxygen: oxygenRef.current?.innerText || '',
      temperature: temperatureRef.current?.innerText || '',
      bloodSugar: bloodSugarRef.current?.innerText || '',
    }));
    console.log("🚀 ~ EditObservations ~ suFormData:", suFormData)
  }, [carePlanDetail?.noteDetails]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addHealthObservations,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);

        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      carePlanid: carePlanDetail?._id,
      userId: carePlanDetail?.userId,
      noteType: carePlanDetail?.noteType,
      title: carePlanDetail?.title,
      flag: carePlanDetail?.flag,
      noteDetails: carePlanDetail?.noteDetails,
      eventDateTime: moment(values.eventDateTime).valueOf(),

      addedby: selectedUserId,
      isHTML: true,
      noteDetails: noteDetailsString,
    }

    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(UPDTE_CARE_PLAN_URL, updateValues);
    console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setSuFormData({
      ...suFormData,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };


  const [htmlData, setHtmlData] = useState("")
  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-6">
          <p><strong>Heart Rate (BPM): </strong><span id="heartRateData">{values?.heartRate} </span></p>
        </div>
        <div className="col-md-6">
          <p><strong>Blood Pressure (/MMHG): </strong><span id="bloodPressureData">{values?.bloodPressure}</span></p>
        </div>
        <div className="col-md-6">
          <p><strong>Respiratory Rate: </strong><span id="respiratoryRateData">{values?.respiratoryRate} </span></p>
        </div>
        <div className="col-md-6">
          <p><strong>Oxygen (%): </strong><span id="oxygenData">{values?.oxygen}</span></p>
        </div>
        <div className="col-md-6">
          <p><strong>Temperature (˚C): </strong><span id="temperatureData">{values?.temperature} </span></p>
        </div>
        <div className="col-md-6">
          <p><strong>Blood Sugar (MMOL/L): </strong><span id="bloodSugarData">{values?.bloodSugar}</span></p>
        </div>
        <div className="col-md-12">
          <img className="observationImg" src={BASE_URL_PROD + Observation} alt="Observation" />
        </div>
      </>
    );
  }, [values]);

  return (
    <>

      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit Health Observations note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={values?.addedby}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Date / Time"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">
                      <Input
                        type="number"
                        id="heartRate"
                        name="heartRate"
                        value={values?.heartRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Heart Rate (BPM)"
                        customError={touched?.heartRate && errors?.heartRate}
                        errorMessage={errors?.heartRate}
                        erroClass={
                          touched?.heartRate && errors?.heartRate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">
                      <Input
                        type="number"
                        id="bloodPressure"
                        name="bloodPressure"
                        value={values?.bloodPressure}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Blood Pressure (/MMHG)"
                        customError={touched?.bloodPressure && errors?.bloodPressure}
                        errorMessage={errors?.bloodPressure}
                        erroClass={
                          touched?.bloodPressure && errors?.bloodPressure
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">
                      <Input
                        type="number"
                        id="respiratoryRate"
                        name="respiratoryRate"
                        value={values?.respiratoryRate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Respiratory Rate"
                        customError={touched?.respiratoryRate && errors?.respiratoryRate}
                        errorMessage={errors?.respiratoryRate}
                        erroClass={
                          touched?.respiratoryRate && errors?.respiratoryRate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">
                      <Input
                        type="number"
                        id="oxygen"
                        name="oxygen"
                        value={values?.oxygen}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Oxygen (%)"
                        customError={touched?.oxygen && errors?.oxygen}
                        errorMessage={errors?.oxygen}
                        erroClass={
                          touched?.oxygen && errors?.oxygen
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">
                      <Input
                        type="number"
                        id="temperature"
                        name="temperature"
                        value={values?.temperature}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Temperature (˚C)"
                        customError={touched?.temperature && errors?.temperature}
                        errorMessage={errors?.temperature}
                        erroClass={
                          touched?.temperature && errors?.temperature
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">
                      <Input
                        type="number"
                        id="bloodSugar"
                        name="bloodSugar"
                        value={values?.bloodSugar}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Blood Sugar (MMOL/L)"
                        customError={touched?.bloodSugar && errors?.bloodSugar}
                        errorMessage={errors?.bloodSugar}
                        erroClass={
                          touched?.bloodSugar && errors?.bloodSugar
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      // onClick={subModalAddCarePlans}
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>
              <div className="referenceDiv">
                <div ref={heartRateRef}></div>
                <div ref={bloodPressureRef}></div>
                <div ref={respiratoryRateRef}></div>
                <div ref={oxygenRef}></div>
                <div ref={temperatureRef}></div>
                <div ref={bloodSugarRef}></div>
              </div>
              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EditObservations