import moment from "moment";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
    BASE_URL,
} from "../../env";

const ViewTrainingModel = ({ onClose, item, trainingData }) => {
    console.log("🚀 ~ ViewTrainingModel ~ trainingData:", trainingData)


    return (
        <>
            <Modal
                show={onClose}
                onHide={onClose}
                className="ftcModalPopup"
                size={"md"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Training Detail</Modal.Title>
                </Modal.Header>

                <Modal.Body className="careNotesPopup">
                    <div className="row">
                        {
                            trainingData?.prpsName !== '' && (
                                <>
                                    <div className="col-md-8">
                                        <p><strong>Title:</strong>
                                            {trainingData?.prpsName}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>Estimate Time:</strong>
                                            {trainingData?.trainingHours} Hour(s)</p>
                                    </div>

                                    <div className="col-md-4">
                                        <p><strong>Start Date:</strong>
                                            {moment(trainingData?.prpsTrgStartDate).format("DD/MM/YYYY")}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>End Date:</strong>
                                            {moment(trainingData?.prpsTrgEndDate).format("DD/MM/YYYY")}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>Registration Date:</strong>
                                            {moment(trainingData?.prpsTrgRegisterationDate).format("DD/MM/YYYY")}</p>
                                    </div>

                                    <div className="col-md-4">
                                        <p><strong>Status:</strong>
                                            {trainingData?.prpsTrgStatus}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>Type:</strong>
                                            {trainingData?.prpsTrgType}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p><strong>Class :</strong>
                                            {trainingData?.prpsTrgClass}</p>
                                    </div>

                                    <div className="col-md-12">
                                        <Link target="_blank" to={BASE_URL + trainingData?.content[0]?.filePath}>View Additional Content</Link>
                                        {/* <br/>
                                        {BASE_URL + trainingData?.content[0]?.filePath} */}
                                    </div>

                                    <div className="col-md-12">
                                        <p><strong>Description :</strong>
                                            {trainingData?.prpsDescription}</p>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </Modal.Body>

            </Modal>

        </>
    );
};

export default ViewTrainingModel;