import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "../auth/PrivateRoute";
import ProtectedRoute from "../permissions/ProtectedRoute";
import { permissions } from "../permissions/roles";

// Pages
// import Dashboard from "../pages/dashboard/Dashboard";
import Advanced from "../pages/advanced/Advanced";
import BradfordScore from "../pages/bradford_score/BradfordScore";
import Holiday from "../pages/holiday/Holiday";
import MessagingSystem from "../pages/messaging_system/MessagingSystem";
import MessagingSystemAdvanced from "../pages/messaging_system_advanced/MessagingSystemAdvanced";
import Payroll from "../pages/payroll/Payroll";
import PayrollIndividual from "../pages/payroll/PayrollIndividual";
import Training from "../pages/training/Training";
import Supervisions from "../pages/supervisions/Supervisions";
import StaffRecords from "../pages/staff_records/StaffRecords";
import AddNewStaff from "../pages/staff_records/AddNewStaff";
import LiveRosterGroups from "../pages/roster/LiveRosterGroups";
// import PublishRosterGroups from "../pages/roster/PublishRosterGroups";
import StaffOverview from "../pages/staff_overview/StaffOverview";
import StaffMemberProfile from "../pages/staff_records/StaffMemberProfile";
import ServiceUsers from "../pages/service_users/profile/ServiceUsers";
import ServiceProfile from "../pages/service_users/profile/ServiceProfile";
import MedicineStock from "../pages/medicine_stock/MedicineStock";
import Documents from "../pages/documents/Documents";
import RecentIncidents from "../pages/incidents/RecentIncidents";
import PbsPlans from "../pages/pbs/PbsPlans";
import RiskAssessments from "../pages/risk_assessments/RiskAssessments";
import AddTraining from "../pages/training/AddTraining";
import Recruitment from "../pages/recruitment/Recruitment";
import AddCandidates from "../pages/recruitment/AddNewCandidates";
import RecruitmentApplicationProcess from "../pages/recruitment/RecruitmentApplicationProcess";
import ConsentCapacity from "../pages/consent_capacity/ConsentCapacity";
import CCAddNewForm from "../pages/consent_capacity/CCAddNewForm";
import CurrentHealthIssues from "../pages/health-issue/CurrentHealthIssues";
import AddHealthIssues from "../pages/health-issue/AddHealthIssues";
import Appointments from "../pages/appointments/Appointments";
// import LifeHistoryGoals from "../pages/life_history_goals/LifeHistoryGoals";
import Emar from "../pages/emar/Emar";
// import AddNewShift from "../pages/roster/AddNewShift";
import CarePlans from "../pages/care-plans/care-plan-docs/CarePlans";
import CommonOptions from "../pages/care-plans/common-options/CommonOptions";
import YoungInformation from "../pages/young-information/YoungInformation";
import OutStadingRequest from "../pages/service_users/enquiry/OutStadingRequest";
import InternalReviewsList from "../pages/service_users/reviews/InternalReviewsList";
import IndividualReviews from "../pages/service_users/reviews/IndividualReviews";
import ServiceUserEnquiries from "../pages/service_users/enquiry/ServiceUserEnquiries";
import Rota from "../pages/rota/RotaSchedule";
import RotaTemplateGroups from "../pages/rota/RotaTemplateGroups";
import RotaSchedule from "../pages/rota/RotaSchedule";
import AddNewServiceUser from "../pages/service_users/AddNewServiceUser";
import AddReview from "../pages/service_users/reviews/AddReview";
import AddMedicineStock from "../pages/medicine_stock/AddMedicineStock";
import AppointmentDummy from "../pages/appointments/AppointmentDummy";
import AddRiskAssessments from "../pages/risk_assessments/AddRiskAssessments";
import EditAssessments from "../pages/risk_assessments/EditAssessments";
import AddMedicationSchedule from "../pages/emar/AddMedicationSchedule";
import EditMedicationSchedule from "../pages/emar/EditMedicationSchedule";
import ViewIndividualReviews from "../pages/service_users/reviews/ViewIndividualReviews";
import EditReview from "../pages/service_users/reviews/EditReview";
import MemoryBox from "../pages/care-plans/memory_box/MemoryBox";
import AddPbsPlans from "../pages/pbs/AddPbsPlans";
import EditPbsPlans from "../pages/pbs/EditPbsPlans";
import ViewPbsPlan from "../pages/pbs/ViewPbsPlan";
import AddRecentIncidents from "../pages/incidents/AddRecentIncidents";
import ViewRecentIncident from "../pages/incidents/ViewRecentIncident";
import EditRecentIncidents from "../pages/incidents/EditRecentIncidents";
import CareNotes from "../pages/care-plans/care-notes/CareNotes";
import AddCarePlan from "../pages/care-plans/care-plan-docs/AddCarePlan";
import ViewCarePlan from "../pages/care-plans/care-plan-docs/ViewCarePlan";
import ViewQualitySurvey from "../pages/care-plans/quality-survey/ViewQualitySurvey";
import AddQualitySurvey from "../pages/care-plans/quality-survey/AddQualitySurvey";
import AllGeneralCareNotes from "../pages/care-plans/common-options/general-note/AllGeneralCareNotes";
// import AddGeneralCareNotes from "../pages/care-plans/common-options/general-note/AddGeneralCareNotes";
// import EditGeneralCareNotes from "../pages/care-plans/common-options/general-note/EditGeneralCareNotes";
import AssignUser from "../pages/service_users/assign-user/AssignUser";
import HealthAppointments from "../pages/care-plans/common-options/category/health/health-appointments/HealthAppointments";
import AddHealthAppointments from "../pages/care-plans/common-options/category/health/health-appointments/AddHealthAppointments";
import EditHealthAppointments from "../pages/care-plans/common-options/category/health/health-appointments/EditHealthAppointments";
import AddInjuryHealthIssue from "../pages/care-plans/common-options/category/health/injury-health-issue/AddInjuryHealthIssue";
import EditInjuryHealthIssue from "../pages/care-plans/common-options/category/health/injury-health-issue/EditInjuryHealthIssue";
import AddObservations from "../pages/care-plans/common-options/category/health/observations/AddObservations";
import EditObservations from "../pages/care-plans/common-options/category/health/observations/EditObservations";
import AddWeightHeight from "../pages/care-plans/common-options/category/health/weight-height/AddWeightHeight";
import EditWeightHeight from "../pages/care-plans/common-options/category/health/weight-height/EditWeightHeight";
import AddHealthOthers from "../pages/care-plans/common-options/category/health/others/AddHealthOthers";
import EditHealthOthers from "../pages/care-plans/common-options/category/health/others/EditHealthOthers";
import EditToileting from "../pages/care-plans/common-options/category/personal-care/toileting/EditToileting";
import AddToileting from "../pages/care-plans/common-options/category/personal-care/toileting/AddToileting";
import ShoweringBath from "../pages/care-plans/common-options/category/personal-care/showering-bath/ShoweringBath";
import EditShoweringBath from "../pages/care-plans/common-options/category/personal-care/showering-bath/EditShoweringBath";
import AddShoweringBath from "../pages/care-plans/common-options/category/personal-care/showering-bath/AddShoweringBath";
import EditPersonalCareOthers from "../pages/care-plans/common-options/category/personal-care/others/EditPersonalCareOthers";
import AddPersonalCareOthers from "../pages/care-plans/common-options/category/personal-care/others/AddPersonalCareOthers";
import EditMouthHygiene from "../pages/care-plans/common-options/category/personal-care/mouth-hygiene/EditMouthHygiene";
import AddMouthHygiene from "../pages/care-plans/common-options/category/personal-care/mouth-hygiene/AddMouthHygiene";
import AddActivities from "../pages/care-plans/common-options/category/general/activities/AddActivities";
import EditActivities from "../pages/care-plans/common-options/category/general/activities/EditActivities";
import AddGeneralNote from "../pages/care-plans/common-options/category/general/general-note/AddGeneralNote";
import EditGeneralNote from "../pages/care-plans/common-options/category/general/general-note/EditGeneralNote";
import AddGeneralOthers from "../pages/care-plans/common-options/category/general/others/AddGeneralOthers";
import EditGeneralOthers from "../pages/care-plans/common-options/category/general/others/EditGeneralOthers";
import Safeguarding from "../pages/care-plans/common-options/category/general/safeguarding/Safeguarding";
import AddSafeguarding from "../pages/care-plans/common-options/category/general/safeguarding/AddSafeguarding";
import EditSafeguarding from "../pages/care-plans/common-options/category/general/safeguarding/EditSafeguarding";
import AddSleepInformation from "../pages/care-plans/common-options/category/general/sleep-information/AddSleepInformation";
import EditSleepInformation from "../pages/care-plans/common-options/category/general/sleep-information/EditSleepInformation";
import AddABC from "../pages/care-plans/common-options/category/mental-wellbeing/abc/AddABC";
import EditABC from "../pages/care-plans/common-options/category/mental-wellbeing/abc/EditABC";
import AddConsentCapacityMCADOLS from "../pages/care-plans/common-options/category/mental-wellbeing/consent-capacity-mca-dols/AddConsentCapacityMCADOLS";
import EditConsentCapacityMCADOLS from "../pages/care-plans/common-options/category/mental-wellbeing/consent-capacity-mca-dols/EditConsentCapacityMCADOLS";
import AddMoodRating from "../pages/care-plans/common-options/category/mental-wellbeing/mood-rating/AddMoodRating";
import EditMoodRating from "../pages/care-plans/common-options/category/mental-wellbeing/mood-rating/EditMoodRating";
import AddMentalWellBeingOthers from "../pages/care-plans/common-options/category/mental-wellbeing/others/AddMentalWellBeingOthers";
import EditMentalWellBeingOthers from "../pages/care-plans/common-options/category/mental-wellbeing/others/EditMentalWellBeingOthers";
import AddPhysicalIntervention from "../pages/care-plans/common-options/category/mental-wellbeing/physical-intervention/AddPhysicalIntervention";
import EditPhysicalIntervention from "../pages/care-plans/common-options/category/mental-wellbeing/physical-intervention/EditPhysicalIntervention";
import AddAllOtherInteractions from "../pages/care-plans/common-options/category/professional-family-interactions/all-other-interactions/AddAllOtherInteractions";
import EditAllOtherInteractions from "../pages/care-plans/common-options/category/professional-family-interactions/all-other-interactions/EditAllOtherInteractions";
import AddEmails from "../pages/care-plans/common-options/category/professional-family-interactions/emails/AddEmails";
import EditEmails from "../pages/care-plans/common-options/category/professional-family-interactions/emails/EditEmails";
import AddMeetings from "../pages/care-plans/common-options/category/professional-family-interactions/meetings/AddMeetings";
import EditMeetings from "../pages/care-plans/common-options/category/professional-family-interactions/meetings/EditMeetings";
import AddProfessionalFamilyInteractionsOthers from "../pages/care-plans/common-options/category/professional-family-interactions/others/AddProfessionalFamilyInteractionsOthers";
import EditProfessionalFamilyInteractionsOthers from "../pages/care-plans/common-options/category/professional-family-interactions/others/EditProfessionalFamilyInteractionsOthers";
import AddReviews from "../pages/care-plans/common-options/category/professional-family-interactions/reviews/AddReviews";
import EditReviews from "../pages/care-plans/common-options/category/professional-family-interactions/reviews/EditReviews";
import AddTelephoneCalls from "../pages/care-plans/common-options/category/professional-family-interactions/telephone-calls/AddTelephoneCalls";
import EditTelephoneCalls from "../pages/care-plans/common-options/category/professional-family-interactions/telephone-calls/EditTelephoneCalls";
import AddCleaning from "../pages/care-plans/common-options/category/independent-living/cleaning/AddCleaning";
import EditCleaning from "../pages/care-plans/common-options/category/independent-living/cleaning/EditCleaning";
import AddCooking from "../pages/care-plans/common-options/category/independent-living/cooking/AddCooking";
import EditCooking from "../pages/care-plans/common-options/category/independent-living/cooking/EditCooking";
import AddFinance from "../pages/care-plans/common-options/category/independent-living/finance/AddFinance";
import EditFinance from "../pages/care-plans/common-options/category/independent-living/finance/EditFinance";
import AddEducation from "../pages/care-plans/common-options/category/independent-living/education/AddEducation";
import EditEducation from "../pages/care-plans/common-options/category/independent-living/education/EditEducation";
import AddLaundry from "../pages/care-plans/common-options/category/independent-living/laundry/AddLaundry";
import EditLaundry from "../pages/care-plans/common-options/category/independent-living/laundry/EditLaundry";
import AddNutritionHydration from "../pages/care-plans/common-options/category/independent-living/nutrition-hydration/AddNutritionHydration";
import EditNutritionHydration from "../pages/care-plans/common-options/category/independent-living/nutrition-hydration/EditNutritionHydration";
import AddIndependentLivingOthers from "../pages/care-plans/common-options/category/independent-living/others/AddIndependentLivingOthers";
import EditIndependentLivingOthers from "../pages/care-plans/common-options/category/independent-living/others/EditIndependentLivingOthers";
import AddPublicInteraction from "../pages/care-plans/common-options/category/independent-living/public-interaction/AddPublicInteraction";
import EditPublicInteraction from "../pages/care-plans/common-options/category/independent-living/public-interaction/EditPublicInteraction";
import CrisisManagement from "../pages/support-plan/crisis-management/CrisisManagement";
import ModifyCrisisManagement from "../pages/support-plan/crisis-management/ModifyCrisisManagement";
import AddCrisisManagement from "../pages/support-plan/crisis-management/AddCrisisManagement";
import InjuryHealthIssue from "../pages/care-plans/common-options/category/health/injury-health-issue/InjuryHealthIssue";
import WeightHeight from "../pages/care-plans/common-options/category/health/weight-height/WeightHeight";
import Toileting from "../pages/care-plans/common-options/category/personal-care/toileting/Toileting";
import MoodRating from "../pages/care-plans/common-options/category/mental-wellbeing/mood-rating/MoodRating";
import ABC from "../pages/care-plans/common-options/category/mental-wellbeing/abc/ABC";
import PhysicalIntervention from "../pages/care-plans/common-options/category/mental-wellbeing/physical-intervention/PhysicalIntervention";
import ConsentCapacityMCADOLS from "../pages/care-plans/common-options/category/mental-wellbeing/consent-capacity-mca-dols/ConsentCapacityMCADOLS";
import NutritionHydration from "../pages/care-plans/common-options/category/independent-living/nutrition-hydration/NutritionHydration";
import Observations from "../pages/care-plans/common-options/category/health/observations/Observations";
import DailySummary from "../pages/care-plans/common-options/daily-summary/DailySummary";
import MedicineList from "../pages/medicine_list/MedicineList";
import AddMedicineList from "../pages/medicine_list/AddMedicineList";
import EditMedicineList from "../pages/medicine_list/EditMedicineList";
import EditCandidates from "../pages/recruitment/EditCandidates";
import RotaTemplateList from "../pages/rota_advanced/RotaTemplateList";
import RotaTemplateSchedule from "../pages/rota_advanced/schedule/RotaTemplateSchedule";
import LiveRosterList from "../pages/roster_advanced/LiveRosterList";
import ShiftsActions from "../pages/roster_advanced/shifts_actions/ShiftsActions";
import ClaimedShifts from "../pages/roster_advanced/claimed_shifts/ClaimedShifts";
import GetFinalPayrollList from "../pages/payroll/finalization/GetFinalPayrollList";
import AddQuizzes from "../pages/training/quizzes/AddQuizzes";
import QuizzesList from "../pages/training/quizzes/QuizzesList";
import EditQuizzes from "../pages/training/quizzes/EditQuizzes";
import ArchieveStaffRecords from "../pages/staff_records/archieved_staff/ArchieveStaffRecords";
import ProfileUpdateRequestsList from "../pages/staff_records/profile-update/ProfileUpdateRequestsList";
import PermissionRoutes from "../permission_advance/PermissionRoutes";

function BasicRoutes() {
  return (
    <>

      <Routes>
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}


        <Route
          path="/advanced"
          element={<PrivateRoute element={<PermissionRoutes title="Super Admin" action="view"><Advanced /></PermissionRoutes>
          } />}
        />


        <Route
          path="/bradford-score"
          element={<PrivateRoute element={<PermissionRoutes title="Bradford Score" action="view"><BradfordScore /></PermissionRoutes>
          } />}
        />



        <Route
          path="/holiday"
          element={<PrivateRoute element={<PermissionRoutes title="Holiday" action="view"><Holiday /></PermissionRoutes>
          } />}
        />

        <Route
          path="/messaging-system"
          element={<PrivateRoute element={<PermissionRoutes title="Messaging System" action="view"><MessagingSystemAdvanced /></PermissionRoutes>
          } />}
        />

        <Route
          path="/messaging-system-advanced"
          element={<PrivateRoute element={<PermissionRoutes title="Messaging System" action="view"><MessagingSystem /></PermissionRoutes>
          } />}
        />



        <Route
          path="/payroll"
          element={<PrivateRoute element={<PermissionRoutes title="Payroll" action="view"><Payroll /></PermissionRoutes>
          } />}
        />

        <Route
          path="/final-payroll"
          element={<PrivateRoute element={<PermissionRoutes title="Payroll" action="view"><GetFinalPayrollList /></PermissionRoutes>
          } />}
        />

        <Route
          path="/payroll/:id"
          element={<PrivateRoute element={<PermissionRoutes title="Payroll" action="view"><PayrollIndividual /></PermissionRoutes>
          } />}
        />


        <Route
          path="/live-roster"
          element={<PrivateRoute element={<PermissionRoutes title="Roster" action="view"><LiveRosterList /></PermissionRoutes>
          } />}
        />
        <Route
          path="/shifts-actions"
          element={<PrivateRoute element={<PermissionRoutes title="Roster" action="update"><ShiftsActions /></PermissionRoutes>
          } />}
        />
        <Route
          path="/shifts-requests"
          element={<PrivateRoute element={<PermissionRoutes title="Roster" action="update"><ClaimedShifts /></PermissionRoutes>
          } />}
        />
        <Route
          path="/rota"
          element={<PrivateRoute element={<PermissionRoutes title="Roster" action="view"><Rota /></PermissionRoutes>
          } />}
        />
        <Route
          path="/rota-templates"
          element={<PrivateRoute element={<PermissionRoutes title="Roster" action="view"><RotaTemplateGroups /></PermissionRoutes>
          } />}
        />
        <Route
          path="/rota-schedule"
          element={<PrivateRoute element={<PermissionRoutes title="Roster" action="view"><RotaSchedule /></PermissionRoutes>
          } />}
        />
        <Route
          path="/rota-advanced"
          element={<PrivateRoute element={<PermissionRoutes title="Roster" action="view"><RotaTemplateList /></PermissionRoutes>
          } />}
        />
        <Route
          path="/rota-users-schedule"
          element={<PrivateRoute element={<PermissionRoutes title="Roster" action="view"><RotaTemplateSchedule /></PermissionRoutes>
          } />}
        />


        {/* <Route path="/add-new-service" element={<PrivateRoute element={<AddNewShift />} />} /> */}

        <Route
          path="/training"
          element={<PrivateRoute element={<PermissionRoutes title="Training" action="view"><Training /></PermissionRoutes>
          } />}
        />


        <Route
          path="/training-quizzes"
          element={<PrivateRoute element={<PermissionRoutes title="Training" action="view"><QuizzesList /></PermissionRoutes>
          } />}
        />




        <Route
          path="/supervisions"
          element={<PrivateRoute element={<PermissionRoutes title="Supervision" action="view"><Supervisions /></PermissionRoutes>
          } />}
        />



        <Route
          path="/recruitment"
          element={<PrivateRoute element={<PermissionRoutes title="Recruitment" action="view"><Recruitment /></PermissionRoutes>
          } />}
        />


        <Route
          path="/recruitment-application-process"
          element={<PrivateRoute element={<PermissionRoutes title="Recruitment" action="view"><RecruitmentApplicationProcess /></PermissionRoutes>
          } />}
        />


        <Route
          path="/staff-records"
          element={<PrivateRoute element={<PermissionRoutes title="Staff" action="view"><StaffRecords /></PermissionRoutes>
          } />}
        />

        <Route
          path="/archived-staff-records"
          element={<PrivateRoute element={<PermissionRoutes title="Super Admin" action="view"><ArchieveStaffRecords /></PermissionRoutes>
          } />}
        />

        <Route
          path="/profile-update-requests"
          element={<PrivateRoute element={<PermissionRoutes title="Staff" action="update"><ProfileUpdateRequestsList /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-new-staff"
          element={<PrivateRoute element={<PermissionRoutes title="Staff" action="create"><AddNewStaff /></PermissionRoutes>
          } />}
        />

        <Route
          path="/staff-profile"
          element={<PrivateRoute element={<PermissionRoutes title="Staff" action="view"><StaffMemberProfile /></PermissionRoutes>
          } />}
        />



        <Route
          path="/staff-overview"
          element={<PrivateRoute element={<PermissionRoutes title="Staff Overview" action="view"><StaffOverview /></PermissionRoutes>
          } />}
        />

        <Route
          path="/service-users"
          element={<PrivateRoute element={<PermissionRoutes title="Service User" action="view"><ServiceUsers /></PermissionRoutes>
          } />}
        />

        <Route
          path="/service-user-profile"
          element={<PrivateRoute element={<PermissionRoutes title="Service User" action="view"><ServiceProfile /></PermissionRoutes>
          } />}
        />

        <Route
          path="/outstanding-request"
          element={<PrivateRoute element={<PermissionRoutes title="Enquiries" action="view"><OutStadingRequest /></PermissionRoutes>
          } />}
        />

        <Route
          path="/internal-reviews"
          element={<PrivateRoute element={<PermissionRoutes title="Internal Reviews" action="view"><InternalReviewsList /></PermissionRoutes>
          } />}
        />


        <Route
          path="/individual-reviews"
          element={<PrivateRoute element={<PermissionRoutes title="Internal Reviews" action="view"><IndividualReviews /></PermissionRoutes>
          } />}
        />

        <Route
          path="/view-individual-reviews"
          element={<PrivateRoute element={<PermissionRoutes title="Internal Reviews" action="view"><ViewIndividualReviews /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plans"
          element={<PrivateRoute element={<PermissionRoutes title="Care Plan Review" action="view"><CarePlans /></PermissionRoutes>
          } />}
        />

        <Route
          path="/view-care-plan"
          element={<PrivateRoute element={<PermissionRoutes title="Care Plan Review" action="view"><ViewCarePlan /></PermissionRoutes>
          } />}
        />

        <Route
          path="/care-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><CareNotes /></PermissionRoutes>
          } />}
        />

        <Route
          path="/memory-box"
          element={<PrivateRoute element={<PermissionRoutes title="Memory Box" action="view"><MemoryBox /></PermissionRoutes>
          } />}
        />

        <Route
          path="/young-information"
          element={<PrivateRoute element={<PermissionRoutes title="Staff" action="view"><YoungInformation /></PermissionRoutes>
          } />}
        />

        <Route
          path="/quality-survey"
          element={<PrivateRoute element={<PermissionRoutes title="Quality Survey" action="view"><ViewQualitySurvey /></PermissionRoutes>
          } />}
        />


        {/* Care Plan Common Options */}

        <Route
          path="/common-options"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><CommonOptions /></PermissionRoutes>
          } />}
        />

        <Route
          path="/care-plan/general-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><AllGeneralCareNotes /></PermissionRoutes>
          } />}
        />

        <Route
          path="/care-plan/daily-summary"
          element={<PrivateRoute element={<PermissionRoutes title="Daily Summary" action="view"><DailySummary /></PermissionRoutes>
          } />}
        />

        {/* <Route path="/add-general-notes" element={<PrivateRoute element={<AddGeneralCareNotes />} />} />
        <Route path="/edit-general-notes" element={<PrivateRoute element={<EditGeneralCareNotes />} />} /> */}
        {/* Health */}
        <Route
          path="/care-plan/health-appointments"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><HealthAppointments /></PermissionRoutes>
          } />}
        />

        {/* <Route
          path="/care-plan/health-appointments"
          element={<PrivateRoute element={<HealthAppointments />} />} />
          <Route
          path="/care-plan/add-health-appointments"
          element={<PrivateRoute element={<AddHealthAppointments />} />} />
          <Route
          path="/care-plan/edit-health-appointments-notes"
          element={<PrivateRoute element={<EditHealthAppointments />} />} /> 
           <Route
          path="/care-plan/injury-health-issue"
          element={<PrivateRoute element={<InjuryHealthIssue />} />} /> */}
        <Route
          path="/care-plan/weight-height"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><WeightHeight /></PermissionRoutes>
          } />}
        />


        {/* General */}

        <Route
          path="/care-plan/safeguarding"
          element={<PrivateRoute element={<PermissionRoutes title="Safeguarding" action="view"><Safeguarding /></PermissionRoutes>
          } />}
        />

        {/* <Route path="/care-plan/showering-bath" element={<PrivateRoute element={<ShoweringBath />} />} /> */}
        {/* <Route path="/care-plan/toileting" element={<PrivateRoute element={<Toileting />} />} /> */}

        {/* MentalWellbeing */}

        <Route
          path="/care-plan/abc"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><ABC /></PermissionRoutes>
          } />}
        />

        <Route
          path="/care-plan/consent-capacity-mca-dols"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><ConsentCapacityMCADOLS /></PermissionRoutes>
          } />}
        />

        {/* <Route path="/care-plan/mood-rating" element={<PrivateRoute element={<MoodRating />} />} /> */}
        {/* <Route
          path="/care-plan/physical-intervention"
          element={<PrivateRoute element={<PhysicalIntervention />} />} /> */}
        {/* ProfessionalFamilyInteractions */}
        {/* IndependentLiving */}
        {/* <Route
          path="/care-plan/nutrition-hydration"
          element={<PrivateRoute element={<NutritionHydration />}
          /> */}

        <Route
          path="/support-plan/crisis-management"
          element={<PrivateRoute element={<PermissionRoutes title="Staff" action="view"><CrisisManagement /></PermissionRoutes>
          } />}
        />


        <Route
          path="/support-plan/modify-crisis-management"
          element={<PrivateRoute element={<PermissionRoutes title="Staff" action="view"><ModifyCrisisManagement /></PermissionRoutes>
          } />}
        />

        <Route
          path="/medicine-list"
          element={<PrivateRoute element={<PermissionRoutes title="Medicine List" action="view"><MedicineList /></PermissionRoutes>
          } />}
        />


        {/* Routes not present in Figma */}

        <Route
          path="/medicine-stock"
          element={<PrivateRoute element={<PermissionRoutes title="Medicine Stock" action="view"><MedicineStock /></PermissionRoutes>
          } />}
        />

        <Route
          path="/documents"
          element={<PrivateRoute element={<PermissionRoutes title="Documents" action="view"><Documents /></PermissionRoutes>
          } />}
        />


        <Route
          path="/recent-incidents"
          element={<PrivateRoute element={<PermissionRoutes title="Recent Incidents" action="view"><RecentIncidents /></PermissionRoutes>
          } />}
        />

        <Route
          path="/view-incidents"
          element={<PrivateRoute element={<PermissionRoutes title="Recent Incidents" action="view"><ViewRecentIncident /></PermissionRoutes>
          } />}
        />

        <Route
          path="/pbs-plans"
          element={<PrivateRoute element={<PermissionRoutes title="PBS Review" action="view"><PbsPlans /></PermissionRoutes>
          } />}
        />

        <Route
          path="/view-plans"
          element={<PrivateRoute element={<PermissionRoutes title="PBS Review" action="view"><ViewPbsPlan /></PermissionRoutes>
          } />}
        />

        <Route
          path="/risk-assessments"
          element={<PrivateRoute element={<PermissionRoutes title="Risk Assessment" action="view"><RiskAssessments /></PermissionRoutes>
          } />}
        />

        <Route
          path="/consent-capacity"
          element={<PrivateRoute element={<PermissionRoutes title="Consent & Capacity" action="view"><ConsentCapacity /></PermissionRoutes>
          } />}
        />


        <Route
          path="/current-health-issues"
          element={<PrivateRoute element={<PermissionRoutes title="Health Issues" action="view"><CurrentHealthIssues /></PermissionRoutes>
          } />}
        />

        <Route
          path="/appointments"
          element={<PrivateRoute element={<PermissionRoutes title="Appointments" action="view"><Appointments /></PermissionRoutes>
          } />}
        />

        <Route
          path="/appointments-dummy"
          element={<PrivateRoute element={<PermissionRoutes title="Appointments" action="view"><AppointmentDummy /></PermissionRoutes>
          } />}
        />


        <Route
          path="/emar"
          element={<PrivateRoute element={<PermissionRoutes title="Emar" action="view"><Emar /></PermissionRoutes>
          } />}
        />


        {/* Roll Based Access Control for ADDING Data Routes */}

        <Route
          path="/add-service-user"
          element={<PrivateRoute element={<PermissionRoutes title="Service User" action="create"><AddNewServiceUser /></PermissionRoutes>
          } />}
        />

        <Route
          path="/add-reviews"
          element={<PrivateRoute element={<PermissionRoutes title="Internal Reviews" action="create"><AddReview /></PermissionRoutes>
          } />}
        />


        <Route
          path="/service-user-enquiries"
          element={<PrivateRoute element={<PermissionRoutes title="Enquiries" action="view"><ServiceUserEnquiries /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-care-plans"
          element={<PrivateRoute element={<PermissionRoutes title="Care Plan Review" action="create"><AddCarePlan /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-quality-survey"
          element={<PrivateRoute element={<PermissionRoutes title="Quality Survey" action="create"><AddQualitySurvey /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-health-appointments"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddHealthAppointments /></PermissionRoutes>
          } />}
        />

        <Route
          path="/care-plan/add-injury-health-issue"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddInjuryHealthIssue /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-observations"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddObservations /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-health-other"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddHealthOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-weight-height"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddWeightHeight /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-activities-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddActivities /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-general-note"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddGeneralNote /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-general-other"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddGeneralOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-safeguarding"
          element={<PrivateRoute element={<PermissionRoutes title="Safeguarding" action="create"><AddSafeguarding /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-sleep-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddSleepInformation /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-mouth-hygiene"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddMouthHygiene /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-personal-care-other"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddPersonalCareOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-showering-bath"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddShoweringBath /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-toileting"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddToileting /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-abc"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddABC /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-consent-capacity-mca-dols"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddConsentCapacityMCADOLS /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-mood-rating"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddMoodRating /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-mental-wellbeing-other"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddMentalWellBeingOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-physical-intervention"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddPhysicalIntervention /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-all-other-interactions"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddAllOtherInteractions /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-emails"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddEmails /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-meetings"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddMeetings /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-professional-family-interactions-other"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddProfessionalFamilyInteractionsOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-reviews"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddReviews /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-telephone-calls"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddTelephoneCalls /></PermissionRoutes>
          } />}
        />

        <Route
          path="/care-plan/add-cleaning"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddCleaning /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-cooking"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddCooking /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-finance"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddFinance /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-education"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddEducation /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-laundry"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddLaundry /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-nutrition-hydration"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddNutritionHydration /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-independent-living-other"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddIndependentLivingOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/add-public-interaction"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="create"><AddPublicInteraction /></PermissionRoutes>
          } />}
        />


        <Route
          path="/support-plan/add-crisis-management"
          element={<PrivateRoute element={<PermissionRoutes title="Staff" action="create"><AddCrisisManagement /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-medicine-list"
          element={<PrivateRoute element={<PermissionRoutes title="Medicine List" action="create"><AddMedicineList /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-medicine-stock"
          element={<PrivateRoute element={<PermissionRoutes title="Medicine Stock" action="create"><AddMedicineStock /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-incidents"
          element={<PrivateRoute element={<PermissionRoutes title="Recent Incidents" action="create"><AddRecentIncidents /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-plans"
          element={<PrivateRoute element={<PermissionRoutes title="PBS Review" action="create"><AddPbsPlans /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-risk-assessments"
          element={<PrivateRoute element={<PermissionRoutes title="Risk Assessment" action="create"><AddRiskAssessments /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-consent-capacity"
          element={<PrivateRoute element={<PermissionRoutes title="Consent & Capacity" action="create"><CCAddNewForm /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-training"
          element={<PrivateRoute element={<PermissionRoutes title="Training" action="create"><AddTraining /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-training-quizzes"
          element={<PrivateRoute element={<PermissionRoutes title="Training Quizzes" action="create"><AddQuizzes /></PermissionRoutes>
          } />}
        />


        <Route
          path="/edit-training-quizzes"
          element={<PrivateRoute element={<PermissionRoutes title="Training Quizzes" action="update"><EditQuizzes /></PermissionRoutes>
          } />}
        />



        <Route
          path="/add-new-candidate"
          element={<PrivateRoute element={<PermissionRoutes title="Recruitment" action="create"><AddCandidates /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-medication"
          element={<PrivateRoute element={<PermissionRoutes title="Emar" action="create"><AddMedicationSchedule /></PermissionRoutes>
          } />}
        />


        <Route
          path="/add-health-issues"
          element={<PrivateRoute element={<PermissionRoutes title="Health Issues" action="create"><AddHealthIssues /></PermissionRoutes>
          } />}
        />



        {/* Roll Based Access Control for EDITING Data Routes */}


        <Route
          path="/assign-staff"
          element={<PrivateRoute element={<PermissionRoutes title="Assign Staff" action="view"><AssignUser /></PermissionRoutes>
          } />}
        />


        <Route
          path="/edit-candidate"
          element={<PrivateRoute element={<PermissionRoutes title="Recruitment" action="update"><EditCandidates /></PermissionRoutes>
          } />}
        />


        <Route
          path="/edit-reviews"
          element={<PrivateRoute element={<PermissionRoutes title="Internal Reviews" action="update"><EditReview /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-health-appointments-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditHealthAppointments /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-injury-health-issue-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditInjuryHealthIssue /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-observations-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditObservations /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-health-other-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditHealthOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-weight-height-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditWeightHeight /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-activities-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditActivities /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-general-note-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditGeneralNote /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-general-other-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditGeneralOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-safeguarding-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Safeguarding" action="view"><EditSafeguarding /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-sleep-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditSleepInformation /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-mouth-hygiene-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditMouthHygiene /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-personal-care-other-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditPersonalCareOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-showering-bath-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditShoweringBath /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-toileting-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditToileting /></PermissionRoutes>
          } />}
        />

        <Route
          path="/care-plan/edit-abc-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditABC /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-consent-capacity-mcadols-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditConsentCapacityMCADOLS /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-mood-rating-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditMoodRating /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-mental-wellbeing-other-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditMentalWellBeingOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-physical-intervention-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditPhysicalIntervention /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-all-other-interactions-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditAllOtherInteractions /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-emails-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditEmails /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-meetings-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditMeetings /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-professional-family-interactions-other-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditProfessionalFamilyInteractionsOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-reviews-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditReviews /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-telephone-calls-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditTelephoneCalls /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-cleaning-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditCleaning /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-cooking-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditCooking /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-finance-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditFinance /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-education-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditEducation /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-laundry-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditLaundry /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-nutrition-hydration-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditNutritionHydration /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-independent-living-other-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditIndependentLivingOthers /></PermissionRoutes>
          } />}
        />


        <Route
          path="/care-plan/edit-public-interaction-notes"
          element={<PrivateRoute element={<PermissionRoutes title="Care Notes" action="view"><EditPublicInteraction /></PermissionRoutes>
          } />}
        />


        <Route
          path="/edit-medicine-list"
          element={<PrivateRoute element={<PermissionRoutes title="Medicine List" action="update"><EditMedicineList /></PermissionRoutes>
          } />}
        />


        <Route
          path="/edit-incidents"
          element={<PrivateRoute element={<PermissionRoutes title="Recent Incidents" action="update"><EditRecentIncidents /></PermissionRoutes>
          } />}
        />


        <Route
          path="/edit-plans"
          element={<PrivateRoute element={<PermissionRoutes title="PBS Review" action="update"><EditPbsPlans /></PermissionRoutes>
          } />}
        />


        <Route
          path="/edit-risk-assessments"
          element={<PrivateRoute element={<PermissionRoutes title="Risk Assessment" action="update"><EditAssessments /></PermissionRoutes>
          } />}
        />


        <Route
          path="/edit-medication"
          element={<PrivateRoute element={<PermissionRoutes title="Emar" action="update"><EditMedicationSchedule /></PermissionRoutes>
          } />}
        />


      </Routes>
    </>
  );
}

export default BasicRoutes;
