import React, { useEffect, useState } from "react";
import StaffRecordsBlocks from "./StaffRecordsBlocks";
import ProfileDetails from "../../components/user-detail/ProfileDetails";
import { Tab, Nav } from "react-bootstrap";
import StaffRecordHeader from "./StaffRecordHeader";
import { Link } from "react-router-dom";
import StaffRecordTabsContent from "./records/StaffRecordTabsContent";
import ComplianceDocumentsTab from "./records/ComplianceDocuments";
import References from "./records/ReferencesDocuments";
import AbsenceLeave from "./records/AbsenceLeave";
import Supervisions from "./records/Supervisions";
import Training from "./records/Trainings";
import Holidays from "./records/Holidays";
import Disciplinary from "./records/DisciplinaryDocuments";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";
import InterviewDocuments from "./records/InterviewDocuments";
import SubmitDocuments from "./records/SubmitDocuments";
import DBSDocuments from "./records/DBSDocuments";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";

function StaffRecordsIndividual({ getUserRecord }) {
  // console.log("🚀 ~ StaffRecordsIndividual ~ getUserRecord:", getUserRecord)
  // const initialUserData = getUserRecord;
  const [userData, setUserData] = useState(getUserRecord);
  console.log("🚀 ~ StaffRecordsIndividual ~ userData:", userData)
  console.log("🚀 ~ StaffRecordsIndividual ~ userData:", userData)
  useEffect(() => {
    setUserData(getUserRecord)
  }, [getUserRecord])


  return (
    <>
      <div className="profileDetailWrapper staffRecordDetailWrapper customTabsWrapper">
        <div className="row">
          <div className="col-md-3">
            <ProfileDetails getUserRecord={userData} />
          </div>
          <div className="col-md-9">
            <div className="userDetail userPayrollDetail">
              <StaffRecordHeader getUserRecord={userData} />
              <div className="tabsWrapper">
                <Tab.Container id="myTab" defaultActiveKey="contractedHoursTab">
                  <div className="tabsHeader">
                    <Nav variant="tabs">
                      <PermissionWrapper title="Payroll" action="view">
                        <Nav.Item>
                          <Nav.Link eventKey="contractedHoursTab">
                            Contracted Hours
                          </Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Supervision" action="view">
                        <Nav.Item>
                          <Nav.Link eventKey="supervisions">
                            Supervisions
                          </Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Training" action="update">
                        <Nav.Item>
                          <Nav.Link eventKey="training">Trainings</Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Holiday" action="update">
                        <Nav.Item>
                          <Nav.Link eventKey="holidays">Holidays</Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Holiday" action="update">
                        <Nav.Item>
                          <Nav.Link eventKey="absenceLeave">
                            Absence & Leaves
                          </Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Staff Member Documents" action="view">
                        <Nav.Item>
                          <Nav.Link eventKey="interviewDocumentsTab">
                            Interview Documents
                          </Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Staff Member Documents" action="view">
                        <Nav.Item>
                          <Nav.Link eventKey="submitDocumentsTab">
                            Documents Submit
                          </Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Staff Member Documents" action="view">
                        <Nav.Item>
                          <Nav.Link eventKey="dbsDocumentsTab">
                            DBS
                          </Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Staff Member Documents" action="view">
                        <Nav.Item>
                          <Nav.Link eventKey="complianceDocumentsTab">
                            Compliance Documents
                          </Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Staff Member Documents" action="view">
                        <Nav.Item>
                          <Nav.Link eventKey="disciplinaryTab">
                            Disciplinary
                          </Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                      <PermissionWrapper title="Staff Member Documents" action="view">
                        <Nav.Item>
                          <Nav.Link eventKey="referencesTab">References</Nav.Link>
                        </Nav.Item>
                      </PermissionWrapper>
                    </Nav>
                  </div>
                  <Tab.Content>
                    <PermissionWrapper title="Payroll" action="view">
                      <Tab.Pane eventKey="contractedHoursTab">
                        <div className="staffRecordTabsContent">
                          <StaffRecordsBlocks
                            currentStatus={true}
                            getUserRecord={userData}
                          />
                          {/* <StaffRecordTabsContent getUserRecord={userData} /> */}
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Supervision" action="view">
                      <Tab.Pane eventKey="supervisions">
                        <div className="staffRecordTabsContent">
                          <Supervisions getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Holiday" action="update">
                      <Tab.Pane eventKey="holidays">
                        <div className="staffRecordTabsContent">
                          <Holidays getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Holiday" action="update">
                      <Tab.Pane eventKey="absenceLeave">
                        <div className="staffRecordTabsContent">
                          <AbsenceLeave getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Training" action="update">
                      <Tab.Pane eventKey="training">
                        <div className="staffRecordTabsContent">
                          <Training getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Staff Member Documents" action="view">
                      <Tab.Pane eventKey="interviewDocumentsTab">
                        <div className="staffRecordTabsContent">
                          <InterviewDocuments getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Staff Member Documents" action="view">
                      <Tab.Pane eventKey="submitDocumentsTab">
                        <div className="staffRecordTabsContent">
                          <SubmitDocuments getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Staff Member Documents" action="view">
                      <Tab.Pane eventKey="dbsDocumentsTab">
                        <div className="staffRecordTabsContent">
                          <DBSDocuments getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Staff Member Documents" action="view">
                      <Tab.Pane eventKey="complianceDocumentsTab">
                        <div className="staffRecordTabsContent">
                          <ComplianceDocumentsTab getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Staff Member Documents" action="view">
                      <Tab.Pane eventKey="disciplinaryTab">
                        <Disciplinary getUserRecord={userData} />
                      </Tab.Pane>
                    </PermissionWrapper>
                    <PermissionWrapper title="Staff Member Documents" action="view">
                      <Tab.Pane eventKey="referencesTab">
                        <div className="staffRecordTabsContent">
                          <References getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </PermissionWrapper>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffRecordsIndividual;
