import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import Layout from "../../../components/layouts/Layout";
import editBlock from "../../../assets/images/icons/editBlock.svg";
import eventDel from "../../../assets/images/icons/eventDel.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import useColumnsWithPermission from "../../../permissions/useColumnsWithPermission";
import {
    BASE_URL,
    GET_STAFF_MEMBERS_PROFILE_URL,
    UPDATE_STAFF_MEMBERS_PROFILE_URL,
    DELETE_MEDICINE_STOCK_URL,
} from "../../../env";
import {
    updateRecords,
    getRecords,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
    deleteRecords,
    catchAsync,
} from "../../../helper/helper";
import StatusUpdated from "../../../components/general/StatusUpdated";
function ProfileUpdateRequestsList() {
    const storedUserDetails = localStorage.getItem("profileUserDetails");
    const [isSuccessReturn, setIsSuccessReturn] = useState(false);

    const updateStaffHoliday = catchAsync(async (req, res) => {
        try {
            // console.log("🚀 ~ updateStaffHoliday ~ req:", req)
            const response = await updateRecords(UPDATE_STAFF_MEMBERS_PROFILE_URL, req);
            console.log("🚀 ~ updateStaffHoliday ~ response:", response)
            processFetchRecords("update", response);
        } catch (error) {
            console.error("Error updating staff holidays:", error);
        }
    });
    const [holidayRequestStatus, setHolidayRequestStatus] = useState(null);
    useEffect(() => {
        const updateHoliday = async () => {
            if (holidayRequestStatus !== null) {  // Only run if there's a valid status
                console.log("🚀 ~ HolidaysGroup ~ holidayRequestStatus:", holidayRequestStatus);
                await updateStaffHoliday(holidayRequestStatus);
                fetchAllRecords();
            }
        };
        updateHoliday();
        // updateStaffHoliday(holidayRequestStatus);
    }, [holidayRequestStatus]);

    const userDetails = JSON.parse(storedUserDetails);
    // console.log("🚀 ~ ProfileUpdateRequestsList ~ userDetails:", userDetails)
    const [userData, setUserData] = useState([]);
    const apiQuery = {
        sortproperty: "createdAt",
        sortorder: -1,
        offset: 0,
        query: {}
    }
    const [search, setSearch] = useState("");
    const [filterSearch, setFilterSearch] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [medicationIdData, setMedicationIdData] = useState({
        medicationId: null,
    });
    const [profileRequestUpdateDetail, setProfileRequestUpdateDetail] = useState();
    const [isProfileUpdateRequestsList, setIsProfileUpdateRequestsList] = useState(false);
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);


    // Update Status of the Shifts
    const subModalUpdateRosterUserShift = (onChanageReject, shiftData) => {
        setHolidayRequestStatus({
            requestId: shiftData?._id,
            status: onChanageReject === "rejected" ? "reject" : onChanageReject === "approved" ? "accept" : "pending",
        })
    };

    // Modal Popup
    const [showAddMedication, setShowAddMedication] = useState(false);
    const subModalAddMedicine = () => {
        setShowAddMedication(!showAddMedication);
        fetchAllRecords();
    };

    const [showDeleteMedication, setShowDeleteMedication] = useState(false);
    const subModalDeleteMedication = (profileRequestUpdateId) => {
        setMedicationIdData({ medicationId: profileRequestUpdateId });
        setShowDeleteMedication(!showDeleteMedication);
        fetchAllRecords();
    };

    const [showEditMedication, setShowEditMedication] = useState(false);
    const subModalEditMedication = (emarItem) => {
        setProfileRequestUpdateDetail(emarItem);
        setShowEditMedication(!showEditMedication);
        fetchAllRecords();
    };

    // Getting records from DB
    const processFetchRecords = (method, data) => {
        if (method === "get" && data?.status === isSuccess) {
            const profileRequestUpdate = data?.data?.profileRequests?.filter((item) => item.status === "Pending");
            console.log("🚀 ~ processFetchRecords ~ profileRequestUpdate:", profileRequestUpdate)
            setUserData(profileRequestUpdate);
            setFilterSearch(profileRequestUpdate);
            setIsLoading(false);
            // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
        } else if (method === "delete" && data?.status === isSuccess) {
            toastSuccessMessages(toast.success, data?.message, "", 500, 500);
            setTimeout(() => {
                setIsProfileUpdateRequestsList(false);
            }, 2000);
        }
        // else {
        //   console.log({ data });
        //   toastErrorMessages(toast.error, data?.message, 1000);
        // }
    };

    const fetchAllRecords = catchAsync(async (req, res) => {
        const response = await getRecords(GET_STAFF_MEMBERS_PROFILE_URL, apiQuery);
        console.log("🚀 ~ fetchAllRecords ~ apiQuery:", apiQuery)
        // console.log("🚀 ~ fetchAllRecords ~ apiQuery:", apiQuery)
        // console.log(
        //   "🚀 ~ file: ProfileUpdateRequestsList.jsx:89 ~ fetchAllRecords ~ response:",
        //   response
        // );
        processFetchRecords("get", response);
    });

    useEffect(() => {
        fetchAllRecords();
    }, []);

    // Table Columns

    const columns = [
        {
            name: "Staff Name",
            selector: (row) => {
                return (
                    <span
                        className="usersNameInfo"
                    >
                        <span className="userImage">
                            <img
                                src={`${BASE_URL}${row?.staffId?.user?.profile_picture_url}`}
                                alt={row?.staffId?.staffMemberName}
                            />
                        </span>
                        <span className="userInfo">
                            <span className="nameBlock">{`${row?.staffId?.staffMemberName}`}{" "}{`${row?.staffId?.lastName}`}</span>

                        </span>

                    </span>
                )
            },
            width: "15%"

        },
        {
            name: "Request Changes",
            cell: (row) => {
                if (row?.requestedChanges) {
                    const formattedText = Object.entries(row.requestedChanges)
                        .map(([key, value]) => {
                            const formattedKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
                            return `${formattedKey}: ${value}`;
                        })
                        .join("<br />");

                    return (
                        <div className="requestChanges" dangerouslySetInnerHTML={{ __html: formattedText }} />
                    );
                }
                return ""; // Return an empty string if there are no requestedChanges
            },
        },
        {
            name: "Status",
            selector: (row) => (

                row?.status !== "Pending" ? (
                    <span className={`statusButton ${row?.status === "Rejected" ? "background-danger" : row?.status === "Accepted" ? "background-success" : "background-warning"}`}>
                        {row?.status}</span>
                ) : (
                    <StatusUpdated
                        initialStatus={row?.status}
                        isSuccessReturn={isSuccessReturn}
                        onChange={(e) => subModalUpdateRosterUserShift(e.target.value, row)}
                    />
                )


            ),
            width: "12%",
            center: true,
        },
    ];

   
    // Search Filter
    // useEffect(() => {
    //     const result = userData.filter((user) => {
    //         return user.staffId?.staffMemberName.toLowerCase().match(search.toLowerCase());
    //     });
    //     setFilterSearch(result);
    // }, [search, userData]);

    // Delete Medicine records
    const deleteRecord = catchAsync(async (req, res) => {
        setIsSubmitButtonClicked(true);
        if (medicationIdData !== "") {
            const response = await deleteRecords(
                DELETE_MEDICINE_STOCK_URL,
                medicationIdData
            );

            setIsProfileUpdateRequestsList(true);
            processFetchRecords("delete", response);
            fetchAllRecords();
            setIsSubmitButtonClicked(false);
            setShowDeleteMedication(!showDeleteMedication);
        } else {
            setShowDeleteMedication(!showDeleteMedication);
            setIsSubmitButtonClicked(false);
            setIsProfileUpdateRequestsList(true);
            toastErrorMessages(toast.error, "Unable to remove", "", 1000);
        }
    });
    useEffect(() => {
        setTimeout(() => {
            setIsProfileUpdateRequestsList(false);
        }, 2000);
    }, [isProfileUpdateRequestsList])

    return (
        <>
            <Layout pageTitleText="Profile Update Requests">
                <div className="rowClickUnset">
                    <div className="mainContent">

                        <div className="dataTableWrapper">
                            <DataTable
                                className="maxWidthTable"
                                columns={columns}
                                data={userData}
                                pagination
                                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                paginationPerPage={10}
                                fixedHeader
                                fixedHeaderScrollHeight="520px"
                                highlightOnHover
                                responsive={true}
                                progressPending={isLoading}
                                progressComponent={<ProgressComponent />}
                            />
                        </div>
                    </div>
                </div>
            </Layout>


            {/* Delete Medication Stock */}

            <Modal
                show={showDeleteMedication}
                onHide={subModalDeleteMedication}
                className="ftcModalPopup confirmationPopup deletionConfirmation"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="editBlockContent text-center">
                        <img src={eventDel} alt={eventDel} />
                        <p>Are you sure you want to delete this medication?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {isSubmitButtonClicked ? (
                        <Button className="btn-sm" variant="danger" disabled>
                            Loading...
                        </Button>
                    ) : (
                        <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                            Yes
                        </Button>
                    )}

                    <Button
                        className="btn-sm"
                        variant="outline-secondary"
                        onClick={subModalDeleteMedication}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            {isProfileUpdateRequestsList && <ToastContainer />}
        </>
    );
}

export default ProfileUpdateRequestsList;
