import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addABC } from "../../../../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_CARE_PLAN_URL } from "../../../../../../env";
import ReactDOMServer from 'react-dom/server';
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";
function AddABC() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("addedby", userId);
  };

  const [suFormData, setSuFormData] = useState({
    addedby: selectedUserId,
    behaviour: "",
    antecedentEvents: "",
    consequenceEvents: "",
    eventDateTime: moment().valueOf(),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addABC,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      userId: userDetails?._id,
      addedby: selectedUserId,
      noteType: "ABC",
      isHTML: true,
      title: values?.consequenceEvents,
      flag: false,
      noteDetails: noteDetailsString,
      eventDateTime: moment(values.eventDateTime).valueOf(),
    }

    // console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(POST_CARE_PLAN_URL, updateValues);
    console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };


  const [htmlData, setHtmlData] = useState("")
  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-12">
          <p><strong>Antecedent Events: </strong><span id="antecedentEventsData">{values?.antecedentEvents} </span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Behaviour : </strong><span id="behaviourData">{values?.behaviour}</span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Consequence Events: </strong><span id="consequenceEventsData">{values?.consequenceEvents}</span></p>
        </div>
      </>
    );
  }, [values]);

  return (

    <>
      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Add ABC note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={selectedUserId}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date / Time"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <TextArea
                        // type="text"
                        id="antecedentEvents"
                        name="antecedentEvents"
                        value={values?.antecedentEvents}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Antecedent Events"
                        tooltipInfo={true}
                        tooltipText={"What happened prior to, or as the behaviour began to occur?"}
                        extraClass={"position-relative"}
                        rows="3"
                        className="mb-0"
                        customError={touched?.antecedentEvents && errors?.antecedentEvents}
                        errorMessage={errors?.antecedentEvents}
                        erroClass={
                          touched?.antecedentEvents && errors?.antecedentEvents
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextArea
                        // type="text"
                        id="behaviour"
                        name="behaviour"
                        value={values?.behaviour}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Behaviour"
                        tooltipInfo={true}
                        tooltipText={"Describe the behaviour that was witnessed/occurred"}
                        extraClass={"position-relative"}
                        rows="3"
                        className="mb-0"
                        customError={touched?.behaviour && errors?.behaviour}
                        errorMessage={errors?.behaviour}
                        erroClass={
                          touched?.behaviour && errors?.behaviour
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextArea
                        id="consequenceEvents"
                        name="consequenceEvents"
                        value={values?.consequenceEvents}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Consequence Events"
                        tooltipInfo={true}
                        tooltipText={"What happened immediately after the behaviour?"}
                        extraClass={"position-relative"}
                        rows="3"
                        className="mb-0"
                        customError={touched?.consequenceEvents && errors?.consequenceEvents}
                        errorMessage={errors?.consequenceEvents}
                        erroClass={
                          touched?.consequenceEvents && errors?.consequenceEvents
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>

              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}


export default AddABC