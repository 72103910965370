import React from "react";
import Layout from "../../components/layouts/Layout";
import MessagingSystem from "./overview/MessagingSystem";
import StaffRecords from "./overview/StaffRecords";
import HolidaysAbsence from "./overview/HolidaysAbsence";
import Roster from "./overview/Roster";
import StaffOverviewBlocks from "../../components/general/StaffOverviewBlocks";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";
import MedicationOverview from "./overview/MedicationOverview";
function StaffOverview() {
  return (
    <Layout pageTitleText="Staff Overview">
      <div className="staffOverview">
        <StaffOverviewBlocks />
        <div className="row">
          <PermissionWrapper title="Roster" action="view">
            <div className="col-md-6">
              <div className="mainContent rowClickUnset h-100">
                <Roster staffHeadingBlock="Roster" />
              </div>
            </div>
          </PermissionWrapper>
          <div className="col-md-6">
            <PermissionWrapper title="Emar" action="view">

              {/* <div className="mainContent">
                <HolidaysAbsence staffHeadingBlock="Holidays & Absence" />
              </div> */}
              <div className="mainContent">
                <MedicationOverview staffHeadingBlock="Medicaiton Tasks" />
              </div>

            </PermissionWrapper>
          </div>
          <PermissionWrapper title="Staff" action="view">
            <div className="col-md-12">
              <div className="mainContent staffRecords">
                <StaffRecords staffHeadingBlock="Staff Records" />
              </div>
            </div>
          </PermissionWrapper>
          {/* <div className="col-md-4">
            <div className="mainContent messagingSystem">
              <MessagingSystem staffHeadingBlock="Messaging System" />
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export default StaffOverview;
