import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addStaffHoliday } from "../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_HOLIDAY_STAFF_MEMBER_URL, CHECK_EXITS_TEMPLATE_SHIFTS_BY_ID_URL } from "../../env";
import SelectBox from "../../components/forms-fields/SelectBox";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import {
    getRecords,
    postRecords,
    catchAsync,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
} from "../../helper/helper";
import moment from "moment/moment";

function AddStaffHoliday({ userId, subModalAddHoliday }) {

   const handleShowHideModal = () => {
        subModalAddHoliday((prevShow) => !prevShow);
    };
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
    const [isToastAddHoliday, setIsToastAddHoliday] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(null);
    const handleUserSelect = (userId) => {
        setSelectedUserId(userId);
        setFieldValue("staffRequester", userId);
    };


    const [suFormData, setSuFormData] = useState({
        staffRequester: selectedUserId,
        hldRqStartDate: "",
        hldRqEndDate: "",
        hldRqTotalDays: 0,
        hldRqTotalHours: 0,
        hldRqStatus: "pending",
        hldRequestType: "holiday",
        hldRqReason: "",
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: suFormData,
        validationSchema: addStaffHoliday,
        onSubmit: async (values) => {
            try {
                setIsSubmitButtonClicked(true);
                await postAddHolidayFormData(values);
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        },
    });

    const processPostRecords = (method, data) => {
        if (data?.status !== isSuccess) {
            setIsToastAddHoliday(true);
            toastErrorMessages(toast.error, data?.message, "", 500, 500);
            setIsSubmitButtonClicked(false);
            setTimeout(() => {
                handleShowHideModal();
                setIsToastAddHoliday(false)
            }, 2000);
        } else if (
            method === "postAddHolidayFormData" &&
            data?.status === isSuccess
        ) {
            setIsToastAddHoliday(true);
            toastSuccessMessages(toast.success, data?.message, "/holiday", 500, 500);
            setIsSubmitButtonClicked(false);
            setTimeout(() => {
                handleShowHideModal();
                setIsToastAddHoliday(false)
            }, 2000);
        }
    };

    const postAddHolidayFormData = catchAsync(async (req, res) => {
        const updatedValues = {
            ...values,
            hldRqStartDate: moment(values.hldRqStartDate).valueOf(),
            hldRqEndDate: moment(values.hldRqEndDate).valueOf(),

        };
        const response = await postRecords(POST_HOLIDAY_STAFF_MEMBER_URL, updatedValues);
        processPostRecords("postAddHolidayFormData", response);
        setIsSubmitButtonClicked(false);
    });

    const hldRequestTypeOptions = [
        { value: "holiday", label: "Holiday" },
        { value: "sickLeave", label: "Sick Leave" },
        { value: "absent", label: "Absent" },
    ];


    // Function to calculate the difference in days
    const calculateDaysDifference = (hldRqStartDate, hldRqEndDate) => {
        const start = moment(hldRqStartDate);
        const end = moment(hldRqEndDate);
        const diff = end.diff(start, 'days');
        return diff + 1;
    };

    useEffect(() => {
        if (values.hldRqStartDate && values.hldRqEndDate) {
            const daysDiff = calculateDaysDifference(
                values.hldRqStartDate,
                values.hldRqEndDate
            );
            setFieldValue("hldRqTotalDays", daysDiff);
        }
    }, [values.hldRqStartDate, values.hldRqEndDate, setFieldValue]);



    const [apiQuery, setApiQuery] = useState({
        startDate: "",
        endDate: "",
        staffUserId: ""
    });
    const [filterSearch, setFilterSearch] = useState([]);
    const [isHolidaysGroup, setIsHolidaysGroup] = useState(false);

    // Getting records from DB
    const processFetchRecords = (method, data) => {
        if (method === "get" && data?.status === isSuccess) {
            const holidayStock = data?.data;
            setFilterSearch(holidayStock);
            setFieldValue("hldRqTotalHours", holidayStock?.totalHours);
        } else if (method === "delete" && data?.status === isSuccess) {

            setTimeout(() => {
                setIsHolidaysGroup(false);
            }, 2000);
        }
    };

    const fetchAllRecords = catchAsync(async (req, res) => {
        const response = await getRecords(CHECK_EXITS_TEMPLATE_SHIFTS_BY_ID_URL, apiQuery);
        console.log("🚀 ~ fetchAllRecords ~ response:", response)
        processFetchRecords("get", response);
    });

    useEffect(() => {
        setApiQuery({
            startDate: moment(values?.hldRqStartDate).valueOf(),
            endDate: moment(values?.hldRqEndDate).valueOf(),
            staffUserId: values?.staffRequester
        })

    }, [values?.staffRequester, values?.hldRqStartDate, values?.hldRqEndDate]);

    useEffect(() => {
        fetchAllRecords();
    }, [apiQuery]);


    return (
        <>
            <div className="incidents consentCapacityAddNew">
                <form onSubmit={handleSubmit}>
                    <div className="formBlockContent">
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="date"
                                    name="hldRqStartDate"
                                    selected={values.hldRqStartDate ? moment(values.hldRqStartDate).toDate() : null}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    labelText="Start Date"
                                    customError={touched.hldRqStartDate && errors.hldRqStartDate}
                                    errorMessage={errors.hldRqStartDate}
                                />
                            </div>
                            <div className="col-md-12">
                                <Input
                                    type="date"
                                    name="hldRqEndDate"
                                    selected={values.hldRqEndDate ? moment(values.hldRqEndDate).toDate() : null}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    labelText="End Date"
                                    customError={touched.hldRqEndDate && errors.hldRqEndDate}
                                    errorMessage={errors.hldRqEndDate}
                                />
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <CustomSelect
                                        id=""
                                        name="staffRequester"
                                        onSelect={handleUserSelect}
                                        defaultValue={selectedUserId}
                                        filterBy=""
                                        label="Staff Requester"
                                        customError={touched?.staffRequester && errors?.staffRequester}
                                        errorMessage={errors?.staffRequester}
                                        errorClass={
                                            touched?.staffRequester && errors?.staffRequester
                                                ? "validationError"
                                                : ""
                                        }
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <Input
                                    type="number"
                                    name="hldRqTotalDays"
                                    value={
                                        // Calculated number of days
                                        values?.hldRqStartDate && values?.hldRqEndDate
                                            ? calculateDaysDifference(
                                                values?.hldRqStartDate,
                                                values?.hldRqEndDate
                                            )
                                            : ""
                                    }
                                    onChange={(e) => {
                                        setFieldValue("staffWorkLoad.hldRqTotalDays", e.target?.value);
                                    }}
                                    onBlur={handleBlur}
                                    labelText="Total Days"
                                    customError={
                                        touched?.hldRqTotalDays && errors?.hldRqTotalDays
                                    }
                                    errorMessage={errors?.hldRqTotalDays}
                                    errorClass={
                                        touched?.hldRqTotalDays && errors?.hldRqTotalDays
                                            ? "validationError"
                                            : ""
                                    }
                                    disabledStatus={true}
                                    readOnly={true}
                                    requiredStar={false}
                                />
                            </div>

                            <div className="col-md-6">
                                <Input
                                    type="number"
                                    id=""
                                    name="hldRqTotalHours"
                                    value={values?.hldRqTotalHours}
                                    onChange={(e) => setFieldValue("hldRqTotalHours", e.target.value)}
                                    onBlur={handleBlur}
                                    max={100}
                                    labelText="Total Hours"
                                // disabledStatus={true}

                                />
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <SelectBox
                                    name="hldRequestType"
                                    value={values.hldRequestType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    options={hldRequestTypeOptions}
                                    labelText="Holiday Type"
                                    customError={touched.hldRequestType && errors.hldRequestType}
                                    errorMessage={errors.hldRequestType}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="formActionButtons">
                        <span
                            onClick={subModalAddHoliday}
                            className="btn btn-sm btn-outline-primary"
                        >
                            Close
                        </span>
                        {isSubmitButtonClicked ? (
                            <Button
                                onClick={subModalAddHoliday}
                                className="btn-sm"
                                variant="primary"
                                disabled
                            >
                                Loading...
                            </Button>
                        ) : (
                            <Button className="btn-sm" variant="primary" type="submit">
                                Save
                            </Button>
                        )}
                    </div>
                </form>
            </div>
            {isToastAddHoliday && <ToastContainer />}
        </>
    );
}

export default AddStaffHoliday;