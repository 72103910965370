import React, { useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import moment from 'moment';
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL, } from "../../env";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import UserDetailReview from '../../components/user-detail/UserDetailReview';
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import IsCompleted from './IsCompleted';

function ViewPbsPlan() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userData = JSON.parse(storedUserDetails);
  const storedPBSDetails = localStorage.getItem("pbsPlanDetail");
  const pbsPlansDetail = JSON.parse(storedPBSDetails);
  const [isReviewed, setIsReviewed] = useState(false);
  console.log("🚀 ~ ViewPbsPlan ~ pbsPlansDetail:", pbsPlansDetail)
  const navigate = useNavigate();

  return (
    <>
      <Layout
        pageTitleText={
          <>
            PBS Plans
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
        <div className="viewEditorWrapper">
          <div className="mainContent mt-3 pt-4">
            <header className="mainContentHeader">
              <div className="usersNameInfo">
                <div className="contentBox">
                  <span className='isReviewed'>
                    <IsCompleted
                      isReviewed={pbsPlansDetail?.isReviewed === true}
                      setIsReviewed={setIsReviewed}
                      individualQueryData={pbsPlansDetail} />
                  </span>
                </div>
              </div>
            </header>
            <header className="mainContentHeader">
              <div className="usersNameInfo">
                <span className="userImage">
                  <img
                    src={`${BASE_URL}${pbsPlansDetail?.staffId?.profile_picture_url}`}
                    alt={pbsPlansDetail?.staffId?.name}
                  />
                </span>
                <div className="contentBox">
                  <p className='m-0'><strong>{pbsPlansDetail?.staffId?.name}</strong>
                    <br />
                    <small className='m-0'> {moment(pbsPlansDetail?.updatedAt).format("DD/MM/YYYY / hh:mm:ss A")}</small>
                    <small className='m-0'> Due Date: {moment(pbsPlansDetail?.completionDate).format("DD/MM/YYYY")}</small>
                  </p>
                </div>
              </div>
              <div>
                <span className="btn btn-sm btn-primary" onClick={() => {
                  navigate("/pbs-plans");
                }}>Back to PBS Plans</span>

                &nbsp;
                <span className="btn btn-sm btn-secondary" onClick={() => {
                  navigate("/edit-plans");
                }}>Edit</span>

              </div>
            </header>
            <div className="viewEditorContent">

              <div className="sectionWrapper">
                <div className="sectionContent pbsFieldWrapper">
                  <header className="alert alert-dark">
                    <h3>About Plan</h3>
                  </header>
                  <div className="p-3">
                    {ReactHtmlParser(pbsPlansDetail?.aboutPlan)}
                  </div>
                </div>
              </div>
              <div className="sectionWrapper">
                <div className="sectionContent pbsFieldWrapper">
                  <header className="alert alert-dark mobpBg">
                    <h3>Management of behavioural presentation</h3>
                  </header>
                  <div className="mobpContentBg p-3">
                    {ReactHtmlParser(pbsPlansDetail?.managementOfBehaviorPlan)}
                  </div>
                </div>
              </div>
              <div className="sectionWrapper">
                <div className="sectionContent pbsFieldWrapper">
                  <header className="alert alert-dark spBg">
                    <h3>Secondary Prevention</h3>
                  </header>
                  <div className="spContentBg p-3">
                    {ReactHtmlParser(pbsPlansDetail?.secondaryPrevention)}
                  </div>
                </div>
              </div>
              <div className="sectionWrapper">
                <div className="sectionContent pbsFieldWrapper">
                  <header className="alert alert-dark rsBg">
                    <h3>Reactive Strategies</h3>
                  </header>
                  <div className="rsContentBg p-3">
                    {ReactHtmlParser(pbsPlansDetail?.reactiveStartegies)}
                  </div>
                </div>
              </div>
              <div className="sectionWrapper">
                <div className="sectionContent pbsFieldWrapper">
                  <header className="alert alert-dark pisrBg">
                    <h3>Post Incident Support- Recovery</h3>
                  </header>
                  <div className="pisrContentBg p-3">
                    {ReactHtmlParser(pbsPlansDetail?.postIncidentSupport)}
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ViewPbsPlan;