import React from "react";
import ScheduleBlocks from "../../../components/general/ScheduleBlocks";
import memoryBox from "../../../assets/images/icons/careplan/memoryBox.png";
import qualitySurvey from "../../../assets/images/icons/careplan/qualitySurvey.svg";
import viewCarePlan from "../../../assets/images/icons/careplan/viewCarePlan.svg";
import PermissionWrapper from "../../../permission_advance/PermissionWrapper";

function CarePlansModule() {
  return (
    <>
      <div className="scheduleBlocks carePlansBlocks">
        <div className="row">
          <div className="col-md-4">
            <ScheduleBlocks
              linkArrow={"/memory-box"}
              blockIcon={memoryBox}
              heading={"Memory Box"}
              paragraph={"Share a picture, video or internet link"}
            />
          </div>
          <div className="col-md-4">
            <ScheduleBlocks
              linkArrow={"/quality-survey"}
              blockIcon={qualitySurvey}
              heading={"Quality Survey"}
              paragraph={"Tell us how we are doing?"}
            />
          </div>
          <PermissionWrapper title="Care Plan Review" action="view">
            <div className="col-md-4">
              <ScheduleBlocks
                linkArrow={"/care-plans"}
                blockIcon={viewCarePlan}
                heading={"View Care Plan"}
                paragraph={"View areas of the Care Plan"}
              />
            </div>
          </PermissionWrapper>
        </div>
      </div>
    </>
  );
}

export default CarePlansModule;
