import React, { useState, useEffect } from "react";
import humanBody from "../../../assets/images/humanBody.svg";
import circleHealthIssue from "../../../assets/images/icons/circleHealthIssue.svg";
import Layout from "../../../components/layouts/Layout";
import UserDetailReview from "../../../components/user-detail/UserDetailReview";

import { Modal } from "react-bootstrap";
// import AddHealthIssues from "./AddHealthIssues";
import { UPDATE_HEALTHISSUES_URL } from "../../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  formateDate,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
  handleRedirectIfProfileNotPresent,
} from "../../../helper/helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import HealthIssuePopover from "../HealthIssuePopover";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditHealthIssue from "../EditHealthIssue";
import { useNavigate } from "react-router-dom";
import ProgressComponent from "../../../components/general/ProgressComponent";
import ClickableHealthCategories from "../ClickableHealthCategories";
import OnClickAddHealthIssue from "../OnClickAddHealthIssue";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import PermissionWrapper from "../../../permission_advance/PermissionWrapper";

function AllIssues({
  allHealthIssues,
  allHealthIssueListData,
  handleSuccessSubmission,
  noRecordFound,
  isLoading,
}) {
  // console.log("🚀 ~ file: AllIssues.jsx:31 ~ AllIssues ~ allHealthIssueListData:", allHealthIssueListData)

  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);

  const navigate = useNavigate();

  useEffect(() => {
    handleRedirectIfProfileNotPresent(
      "profileUserDetails",
      "/service-users",
      navigate
    );
  }, [navigate]);

  // Variables
  const [show, setShow] = useState(false);
  const [activePopover, setActivePopover] = useState(null);
  const [showAddHealthIssuesModal, setShowAddHealthIssuesModal] =
    useState(false);

  const [healthIssueListData, setHealthIssueListData] = useState(
    allHealthIssueListData
  );
  const [userId, setUserId] = useState(userDetails?._id);
  const [issueStatus, setIssueStatus] = useState("");
  const [isToastCurrentIssue, setIsToastCurrentIssue] = useState(false);
  const apiQuery = {
    userId: userId,
  };
  const [healthIssues, setHealthIssues] = useState(allHealthIssues);
  // console.log("🚀 ~ file: AllIssues.jsx:54 ~ AllIssues ~ healthIssues:", healthIssues)
  const renderedEnums = new Set();

  const [showEditModal, setShowEditModal] = useState(false); // State to control the EditHealthIssue modal
  const [editHealthIssueId, setEditHealthIssueId] = useState(); // State to control the EditHealthIssue modal
  const [showAddOnClickModal, setShowAddOnClickModal] = useState(false); // State to control the AddHealthIssueOnClick modal
  const [enumOnClickPart, setEnumOnClickPart] = useState(); // State to control the AddHealthIssueOnClick modal
  const [nameOnClickPart, setNameOnClickPart] = useState(); // State to control the AddHealthIssueOnClick modal


  // Functions
  useEffect(() => {
    setHealthIssues(allHealthIssues);
    setHealthIssueListData(allHealthIssueListData);
  }, [allHealthIssues, allHealthIssueListData]);
  // const handlePopoverClick = (placement) => {
  //   if (activePopover === placement) {
  //     setActivePopover(null);
  //   } else {
  //     setActivePopover(placement);
  //   }
  // };

  useEffect(() => {
    // console.log("my activePopover:", activePopover);
  }, [activePopover]);

  const handlePopoverClick = (placement) => {
    setActivePopover((prevActivePopover) => {
      return prevActivePopover === placement ? null : placement;
    });
    // console.log("my acit:", activePopover)
  };

  const togglePopoverOverlay = (placement) => {
    if (activePopover !== placement) {
      setActivePopover(placement);
    }
  };

  const handleRootClose = (e) => {
    if (
      activePopover &&
      e.target.closest(".popover") === null &&
      e.target.closest(".targetPoint") === null
    ) {
      setActivePopover(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleRootClose);

    return () => {
      document.removeEventListener("mouseup", handleRootClose);
    };
  }, [activePopover]);

  // const handleRootClose = () => {
  //   setActivePopover(null);
  // };

  // useEffect(() => {

  //   document.addEventListener("mousedown", handleRootClose);

  //   return () => {
  //     document.removeEventListener("mousedown", handleRootClose);
  //   };
  // }, []);

  function handleToastMessage(toastType, message) {
    setIsToastCurrentIssue(toastType);

    if (toastType) {
      toast.success(message, {
        onClose: () => {
          setIsToastCurrentIssue(false);
        },
        autoClose: 1000,
        closeButton: 1000,
        theme: "colored",
      });
    } else {
      toast.error(message, {
        onClose: () => {
          setIsToastCurrentIssue(false);
        },
        autoClose: 1000,
        closeButton: 1000,
        theme: "colored",
      });
    }
  }

  // Handler to toggle the EditHealthIssue modal
  const toggleEditModal = (editHealthIssueData) => {
    // Do something with the issueId here, such as setting it in state or using it in another function
    setEditHealthIssueId(editHealthIssueData);
    setShowEditModal(!showEditModal);
    // console.log("test", editHealthIssueData)
  };


  // Handler to toggle the Add Click modal
  const addHealthIssueOnClick = (idValue, nameValue) => {
    // Do something with the issueId here, such as setting it in state or using it in another function
    setEnumOnClickPart(idValue);
    setNameOnClickPart(nameValue);
    setShowAddOnClickModal(!showAddOnClickModal);
    // console.log("test", editHealthIssueData)
  };

  return (
    <>
      <div className="tabContents">
        <div className="partsOfBodyHeader">
          <div className="rootSpanWrapper">
            {noRecordFound ? (
              <>
                <div className="noRecordFound">
                  <p>No records found.</p>
                </div>
              </>
            ) : isLoading ? (
              <ProgressComponent />
            ) : (
              <>
                {healthIssues
                  // Filter out duplicates
                  .filter((item) => {
                    if (!renderedEnums.has(item.enum)) {
                      renderedEnums.add(item.enum);
                      return true;
                    }
                    return false;
                  })
                  // Map and render unique items
                  .map((item, index) => (
                    <span className="rootSpan">
                      {activePopover !== item.enum ? (
                        <>
                          <span
                            key={index}
                            className={`bodyPartsLink ${item.status ? "statusActive" : "statusNotActive"
                              } ${activePopover === item.enum ? "active" : ""}`}
                            onClick={() => togglePopoverOverlay(item.enum)}
                          >
                            {item.name}
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className={`bodyPartsLink ${activePopover === item.enum ? "active" : ""
                              }`}
                          >
                            {item.name}
                          </span>
                        </>
                      )}
                    </span>
                  ))}
              </>
            )}
          </div>
        </div>
        <div className="partsOfBodyContent">
          <div className="humanBody">
            <ClickableHealthCategories addHealthIssueOnClick={addHealthIssueOnClick} />
            {healthIssues.map((item, index) => (
              <div className={`targetPoint ${item?.enum}`} key={index}>
                <OverlayTrigger
                  trigger="click"
                  placement="auto-start"
                  overlay={
                    <Popover id={`popover-${index}`}>
                      <Popover.Body>
                        <div className="issuePointDetail">
                          {healthIssueListData.map((issueItem, idx) =>
                            item?.enum === issueItem?.category?.enum ? (
                              <div className="dataRow" key={idx}>
                                <HealthIssuePopover
                                  // healthNote={item?.name}
                                  issueItemDetail={issueItem}
                                  // healthNoteTitle={issueItem?.healthNote}
                                  // healthComplaint={issueItem?.complaint}
                                  // lastUpdate={formateDate(
                                  //   issueItem?.updatedAt,
                                  //   "m/d/y"
                                  // )}
                                  currentIssueStatus={issueItem?.status}
                                  // issueId={issueItem?._id}
                                  // userId={issueItem?.userId}
                                  handleToastMessage={handleToastMessage}
                                  handleSuccessSubmission={
                                    handleSuccessSubmission
                                  }
                                  handleShowHide={toggleEditModal}
                                />
                              </div>
                            ) : null
                          )}
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                  rootClose={true}
                  show={activePopover === item?.enum} // Control visibility based on activePopover
                  onHide={() => setActivePopover(null)} // Hide on outside click
                >
                  <img
                    className={`target ${item?.status ? "activeCirlce" : "notActiveCirlce"
                      }`}
                    src={circleHealthIssue}
                    alt=""
                    onClick={() => handlePopoverClick(item?.enum)}
                  />
                </OverlayTrigger>
              </div>
            ))}
            <img src={humanBody} alt="Human Body" />
          </div>
        </div>
      </div>
      <PermissionWrapper title="Health Issues" action="update">
        <Modal
          show={showEditModal}
          onHide={toggleEditModal}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Update Health Issue - {editHealthIssueId?.category}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {editHealthIssueId} */}
            <EditHealthIssue
              handleShowHide={toggleEditModal}
              userDataId={userId}
              userHealthIssueData={editHealthIssueId}
              handleSuccessSubmission={handleSuccessSubmission}
            />
          </Modal.Body>
        </Modal>
      </PermissionWrapper>
      {/* Add Health Issue on click */}
      <PermissionWrapper title="Health Issues" action="create">
        <Modal
          show={showAddOnClickModal}
          onHide={addHealthIssueOnClick}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Add Health Issue for - {nameOnClickPart}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OnClickAddHealthIssue
              handleShowHide={addHealthIssueOnClick}
              userDataId={userId}
              enumOnClickPart={enumOnClickPart}
              handleSuccessSubmission={handleSuccessSubmission}
            />
          </Modal.Body>
        </Modal>
      </PermissionWrapper>
      {isToastCurrentIssue && <ToastContainer />}
    </>
  );
}

export default AllIssues;
