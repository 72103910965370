import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ProfileDetails from "../../components/user-detail/ProfileDetails";
import SupervisionQA from "./SupervisionQA";

function SupervisionQAIndividual({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUserData] = useState(initialUserData);
  console.log("🚀 ~ 123 SupervisionQAIndividual ~ userData:", userData)
  return (
    <>
      <div className="profileDetailWrapper">
        <div className="row">
          <div className="col-md-3">
            <ProfileDetails getUserRecord={userData} />
          </div>
          <div className="col-md-9">
            <div className="userDetail rowClickUnset">
              <SupervisionQA getUserRecord={userData} />
            </div>
          </div>
        </div> 
      </div>
    </>
  );
}

export default SupervisionQAIndividual;
