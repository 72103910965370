import React, { useState, useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import eventClose from "../../assets/images/icons/eventClose.svg";
import appointmentCancel from "../../assets/images/icons/appointmentCancel.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddAppointments from "./AddAppointments";
import password_eye_icon from "../../assets/images/icons/password_eye_icon.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import { hasAnyPermissions } from "../../permission_advance/PermissionColumn";
import moment from "moment";
import {
  BASE_URL,
  GET_APPOINTMENTS_URL,
  DELETE_APPOINTMENTS_URL,
} from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import editBlock from "../../assets/images/icons/editBlock.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditAppointment from "./EditAppointment";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";


function Appointments() {

  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [eventDisplayStates, setEventDisplayStates] = useState({});
  const [show, setShow] = useState(false);
  const [dataID, setDataID] = useState({ appointmentId: null });
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const handleModalEvent = () => setShow(!show);
  const [isAppointmentUpdated, setIsAppointmentUpdated] = useState(false)
  const [aptForSelectedDate, setAptForSelectedDate] = useState("")
  const [appointmentListData, setAppointmentListData] = useState([]);
  const [showAddMedication, setShowAddMedication] = useState(false);
  const [showEditMedication, setShowEditMedication] = useState(false);
  const [appointDetailForEdit, setAppointDetailForEdit] = useState();
  const addAppointmentModal = () => {
    setAptForSelectedDate(moment().format('YYYY-MM-DD'))
    setShowAddMedication(!showAddMedication);
    fetchAppointmentsRecords();
  };

  const editAppointmentModal = (appointmentItem) => {
    setAppointDetailForEdit(appointmentItem)
    setShowEditMedication(!showEditMedication);
    fetchAppointmentsRecords();
  };

  const currentYearStart = moment().startOf('year').valueOf();
  const currentYearEnd = moment().endOf('year').valueOf();

  const getAppointmentApiQuery = {
    userId: userDetails?._id,
    startDate: currentYearStart,
    endDate: currentYearEnd,
  };

  // Get Appointments Data
  const processAppointmentData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data;
      if (allData && allData.length <= 0) {

        // setNoRecordFound(true);
      } else {
        setAppointmentListData(allData);
      }
    }
    else if (method === "delete" && data?.status === isSuccess) {
      setIsAppointmentUpdated(true)
      setIsSubmitButtonClicked(false);
      fetchAppointmentsRecords();
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
    }
    else {
      console.log({ data });
      toastErrorMessages(toast?.error, data?.message, 500);
    }
  };

  const fetchAppointmentsRecords = catchAsync(async () => {
    const getAppointmentRecords = await getRecords(
      GET_APPOINTMENTS_URL,
      getAppointmentApiQuery
    );
    processAppointmentData("get", getAppointmentRecords);
    setTimeout(() => {
      setIsAppointmentUpdated(false);
    }, 2000);

  });

  useEffect(() => {
    fetchAppointmentsRecords();
  }, []);

  // Cancel Appointment

  const handleRemoveModalEvent = (aptCancellationId) => {
    setShow(!show);
    setDataID({ appointmentId: aptCancellationId });
  };

  const cancellationRecords = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (dataID !== "") {
      const response = await deleteRecords(
        DELETE_APPOINTMENTS_URL,
        dataID
      );
      processAppointmentData("delete", response);
      setShow(!show);
    } else {
      setShow(!show);
      toastErrorMessages(toast.error, "Unable to Cancelled", "", 500);
      setIsSubmitButtonClicked(false);
    }
  });


  function renderEventContent(eventInfo) {
    const toggleEventDetailView = (eventId) => {
      setEventDisplayStates((prevState) => {
        const updatedStates = { ...prevState };
        // Close all other event details
        Object.keys(updatedStates).forEach((key) => {
          if (key !== eventId) {
            updatedStates[key] = false;
          }
        });
        // Toggle the current event detail
        updatedStates[eventId] = !prevState[eventId];

        return updatedStates;
      });
    };
    const eventId = eventInfo.event.id;


    const displayEventDetail = eventDisplayStates[eventId] || false;
    return (
      <>
        <div className="eventAppointmentWrapper">
          <div className="eventShortDetail">
            {
              appointmentListData.map(appointmentItem => {
                if (appointmentItem._id === eventInfo.event.id) {
                  return (
                    <>
                      <p className="viewDetailIcon"><span onClick={() => toggleEventDetailView(appointmentItem._id)}>
                        <img src={password_eye_icon} alt="View" title="View" />
                      </span></p>
                      <p>{appointmentItem.appointmentStartTime} to {appointmentItem.appointmentEndTime}</p>
                      <p className="viewDetailTitle">
                        <strong>{appointmentItem.appointmentTitle}</strong>
                      </p>
                    </>
                  )
                }
              })
            }


          </div>
          {displayEventDetail && (
            <>
              {appointmentListData.map(appointmentItem => {
                if (appointmentItem?._id === eventInfo?.event?.id) {
                  return (
                    <div className="event-details" key={appointmentItem?._id}>
                      <header>
                        <div className="heaerDetailContent">
                          <div className="eventDetailName">
                            <h4>{userDetails?.modelId?.suFirstMiddleName} {userDetails?.modelId?.suLastName}</h4>
                            <p className="viewDetailTitle">
                              <strong>{appointmentItem.appointmentTitle}</strong>
                            </p>
                          </div>
                        </div>
                        <div className="statusTag">
                          <span className="close" onClick={() => toggleEventDetailView(eventInfo.event.id)} >
                            <img src={eventClose} alt="Close" />
                          </span>
                        </div>
                      </header>
                      <div className="eventContent">
                        <p>{moment(appointmentItem?.appointmentDate).format("DD/MM/YYYY")}<br />{appointmentItem?.appointmentStartTime} to {appointmentItem?.appointmentEndTime}</p>
                        <p>
                          {/* <img src={greyClock} alt="Time" /> */}
                          <strong>Appointment duration: </strong><br />
                          {Math.floor(appointmentItem?.appointmentMin / 60)} hours and {appointmentItem?.appointmentMin % 60} minutes
                        </p>

                        <p><strong>Detail:</strong> {appointmentItem?.appointmentDetails}</p>
                        {
                          appointmentItem?.staff?.name && (
                            <>
                              <p className="m-0">
                                <strong>Staff</strong>
                              </p>
                              <div className="usersNameInfo">
                                <div className="userImage">
                                  <img
                                    src={BASE_URL + appointmentItem?.staff?.profile_picture_url}
                                    alt={appointmentItem?.staff?.name}
                                  />
                                </div>
                                <div className="userInfo">
                                  <span className="nameBlock">
                                    {appointmentItem?.staff?.name}
                                  </span>
                                  <span className="infoBlock">Contact No. {appointmentItem?.staff?.phoneNumber}</span>
                                </div>
                              </div>
                            </>
                          )
                        }

                      </div>
                      {hasAnyPermissions(["create", "update", "delete"], "Appointments") && (
                        <footer>
                          <PermissionWrapper title="Appointments" action="create">
                            <span className="edit" onClick={() => { editAppointmentModal(appointmentItem) }}>
                              <img src={editBlock} alt="Edit" title="Edit" />
                            </span>
                          </PermissionWrapper>
                          <PermissionWrapper title="Appointments" action="delete">
                            <span className="delete"
                              onClick={() => {
                                handleRemoveModalEvent(appointmentItem?._id);
                              }}>
                              <img src={appointmentCancel} alt="Delete" />
                            </span>
                          </PermissionWrapper>
                        </footer>
                      )}

                    </div>
                  );
                }
                return null;
              })}
            </>
          )}
        </div>
      </>
    );
  }

  const events = appointmentListData.map(appointmentItem => (
    appointmentItem?.status !== "cancelled" ? {
      id: appointmentItem._id, // Use the appropriate ID field from your appointment data
      title: appointmentItem.appointmentTitle, // Use the appropriate title field from your appointment data
      start: appointmentItem.appointmentDate, // Use the appropriate start date field from your appointment data
      end: appointmentItem.appointmentDate,
    } : null)).filter(appointment => appointment !== null);



  const handleDateClick = (arg) => {
    setAptForSelectedDate(moment(arg.date).valueOf())
    setShowAddMedication(true);
  };


  return (
    <>
      <Layout pageTitleText="Appointments">
        <div className="contentWrapper">
          <PermissionWrapper title="Appointments" action="create">
            <header className="headerContainerSearchAction">
              <div className="actionButtons">
                <span
                  onClick={addAppointmentModal}
                  className="btn btn-sm btn-primary"
                >
                  Add New Appointment
                </span>
              </div>
            </header>
          </PermissionWrapper>
          {/* <strong>{userDetails?._id}</strong> */}
          <div className="mainContent">
            <div className="eventAppointments">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} // Include the timeGridPlugin
                headerToolbar={{
                  center: "prev,title,next",
                  left: "",
                  right: "",

                  // start: "dayGridMonth timeGridWeek timeGridDay", // Add the view buttons you want
                  // center: "title",
                  // end: "prev,next today",
                }}
                initialView="dayGridMonth"
                weekends={true}
                events={events}
                eventContent={renderEventContent}
                // eventClick={handleEventMouseEnter}
                // eventMouseEnter={handleEventMouseEnter}
                // eventMouseLeave={handleEventMouseEnter}
                views={{
                  timeGridWeek: {
                    type: "timeGridWeek",
                    duration: { days: 7 },
                  },
                  timeGridDay: {
                    type: "timeGridDay",
                    duration: { days: 1 },
                  },
                }}
                buttonText={false}
                dateClick={handleDateClick}
              //   {
              //   dayGridMonth: "Month",
              //   timeGridWeek: "Week",
              //   timeGridDay: "Day",
              //   today: "Today",
              // }
              />
            </div>
          </div>
        </div>
      </Layout>

      {/* Add Appointments */}
      <PermissionWrapper title="Appointments" action="create">
        <Modal
          show={showAddMedication}
          onHide={addAppointmentModal}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="rosterPopup">
            <AddAppointments
              addAppointmentModal={addAppointmentModal}
              userDataId={userDetails?._id}
              aptForSelectedDate={aptForSelectedDate}
            />
          </Modal.Body>
        </Modal>
      </PermissionWrapper>

      {/* Edit Appointments */}

      <Modal
        show={showEditMedication}
        onHide={editAppointmentModal}
        className="ftcModalPopup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <EditAppointment
            editAppointmentModal={editAppointmentModal}
            userDataId={userDetails?._id}
            aptForSelectedDate={aptForSelectedDate}
            appointDetailForEdit={appointDetailForEdit}
          />
        </Modal.Body>
      </Modal>


      {/* Cancel Appointment */}

      <Modal
        show={show}
        onHide={handleModalEvent}
        className="ftcModalPopup confirmationPopup deletionConfirmation"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="editBlockContent text-center">
            <img src={appointmentCancel} alt={appointmentCancel} />
            <p>Are you sure you want to cancel this appointment?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSubmitButtonClicked ? (
            <Button className="btn-sm" variant="danger" disabled>
              Loading...
            </Button>
          ) : (
            <Button className="btn-sm" variant="danger" onClick={cancellationRecords}>
              Submit
            </Button>
          )}
          <Button
            className="btn-sm"
            variant="outline-secondary"
            onClick={handleModalEvent}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {isAppointmentUpdated && <ToastContainer />}
    </>
  );
}

export default Appointments;
