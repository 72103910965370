import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import editBlock from "../../assets/images/icons/editBlock.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import AddDocuments from "./AddDocuments";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import folderIcon from "../../assets/images/icons/folder.svg";
import password_eye_icon_circle from "../../assets/images/icons/password_eye_icon_circle.svg";
import "react-toastify/dist/ReactToastify.css";
import downloadIcon from "../../assets/images/icons/downloadIcon.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import { hasAnyPermissions } from "../../permission_advance/PermissionColumn";
import {
  BASE_URL,
  GET_SU_DOCUMENTS_URL,
  DELETE_SU_DOCUMENTS_URL,
} from "../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
} from "../../helper/helper";
import EditDocuments from "./EditDocuments";
import moment from "moment";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";
function DocumentsGroup() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [userData, setUserData] = useState([]);
  const apiQuery = {
    sortProperty: "createdAt",
    sortOrder: -1,
    query: {
      critarion: {
        userId: userDetails?.modelId?._id,
      }
    }
  };
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [documentIdData, setDocumentIdData] = useState({
    documentId: null,
  });
  const [documentListDetail, setDocumentListDetail] = useState();
  const [isDocumentsGroup, setIsDocumentsGroup] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [carePlanDetail, setCarePlansDetail] = useState();


  // Modal Popup
  const [showAddMedication, setShowAddMedication] = useState(false);
  const subModalAddDocuments = () => {
    setShowAddMedication(!showAddMedication);
    fetchAllRecords();
  };

  const [showDeleteMedication, setShowDeleteMedication] = useState(false);
  const subModalDeleteMedication = (documentListId) => {
    setDocumentIdData({ documentId: documentListId });
    setShowDeleteMedication(!showDeleteMedication);
    fetchAllRecords();
  };

  const [showEditDocumentation, setShowEditDocumentation] = useState(false);
  const subModalEditDocumentation = (docItem) => {
    setDocumentListDetail(docItem);
    setShowEditDocumentation(!showEditDocumentation);
    fetchAllRecords();
  };

  const [showViewCarePlan, setShowViewCarePlan] = useState(false);
  const subModalViewCarePlans = (details) => {
    setCarePlansDetail(details);
    setShowViewCarePlan(!showViewCarePlan);
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const medicationStock = data?.data?.documentList?.map((item) => item);
      setUserData(medicationStock);
      setFilterSearch(medicationStock);
      setIsLoading(false);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsDocumentsGroup(false);
      }, 2000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_SU_DOCUMENTS_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ apiQuery:", apiQuery)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Table Columns

  const columns = [
    {
      name: "Title",
      selector: (row) => {
        return (
          <>
            <span className="usersNameInfo">
              <span className="iconImage">
                <img src={folderIcon} alt={`${row?.title}`} />
              </span>
              <span className="userInfo">
                <span className="nameBlock">{`${row?.title}`}</span>
              </span>
            </span>
          </>
        );
      },
      width: "20%",
      wrap: true,
    },
    {
      name: "Detail",
      selector: (row) => {
        return (
          <>
            <div className="mt-3"><p> {row?.details ? row?.details?.slice(0, 90) + "..." : ""}</p></div>
          </>
        );
      },
      wrap: true
    },
    {
      name: "Created by",
      selector: (row) => {
        return (
          <>
            <p className="createdBy">
              <strong>{row?.addedBy?.name}</strong>
              <br />
              <span><small>{moment(row?.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}</small></span>
            </p>
          </>
        )
      },
      width: "20%",
    },
    ...(hasAnyPermissions(["create", "update", "delete", "view"], "Documents")
      ? [
        {
          name: "Action",
          selector: (row) => {
            return (
              <div className="actionColumns">
                <span className="viewIcons edit">
                  <a href={BASE_URL + row?.docPath} target="_blank" download>
                    <img src={downloadIcon} alt="Download" />
                  </a>
                </span>
                <span
                  className="edit"
                  onClick={() => {
                    subModalViewCarePlans(row);
                  }}>
                  <img style={{ width: "23px" }} src={password_eye_icon_circle} alt="View" title="View" />&nbsp;
                </span>
                <PermissionWrapper title="Documents" action="update">
                  <span
                    className="edit"
                    onClick={() => {
                      subModalEditDocumentation(row);
                    }}
                  >
                    <img src={editBlock} alt="Edit" title="Edit" />
                  </span>
                </PermissionWrapper>
                <PermissionWrapper title="Documents" action="delete">
                  <span
                    className="delete"
                    onClick={() => {
                      subModalDeleteMedication(row?._id);
                    }}
                  >
                    <img src={eventDel} alt="Delete" title="Delete" />
                  </span>
                </PermissionWrapper>
              </div>
            );
          },
          width: "20%",
          center: true,
          wrap: true,
        },
      ]
      : []),
  ];

  // Search Filter
  useEffect(() => {
    const result = userData.filter((user) => {
      return user.title.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, userData]);

  // Delete Documents records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (documentIdData !== "") {
      const response = await deleteRecords(
        DELETE_SU_DOCUMENTS_URL,
        documentIdData
      );

      setIsDocumentsGroup(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteMedication(!showDeleteMedication);
    } else {
      setShowDeleteMedication(!showDeleteMedication);
      setIsSubmitButtonClicked(false);
      setIsDocumentsGroup(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }
  });
  useEffect(() => {
    setTimeout(() => {
      setIsDocumentsGroup(false);
    }, 2000);
  }, [isDocumentsGroup])

  return (
    <>

      <div className="mainContent">
        <div className="dataTableWrapper">
          <DataTable
            className="maxWidthTable minWidthTable"
            columns={columns}
            data={filterSearch}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="520px"
            highlightOnHover
            actions={
              <>
                <PermissionWrapper title="Documents" action="create">
                  <span
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      subModalAddDocuments(filterSearch);
                    }}
                  >
                    Add Documents
                  </span>
                </PermissionWrapper>
              </>
            }
            responsive={true}
            subHeader={true}
            subHeaderComponent={
              <div className="searchContainer">
                <div className="searchTextBox">
                  <input
                    type="text"
                    placeholder="Type your search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            }
            subHeaderAlign="left"
            subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
          />
        </div>
      </div>


      {/* View Care Notes */}
      <Modal
        show={showViewCarePlan}
        onHide={subModalViewCarePlans}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Document Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body className="careNotesPopup">
          <div className="downloadLink">
            <p>
              <strong>{carePlanDetail?.addedBy?.name}</strong>
              <small>{moment(carePlanDetail?.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}</small></p>
            <span className="viewIcons edit">
              <a href={BASE_URL + carePlanDetail?.docPath} target="_blank" download title="Download Document">
                <img src={downloadIcon} alt="Download" />
              </a>
            </span>
          </div>
          {
            carePlanDetail?.title !== '' && (
              <p><strong>Title:</strong> {carePlanDetail?.title}</p>
            )
          }
          <p><strong>Description:</strong> {carePlanDetail?.details}</p>
        </Modal.Body>
      </Modal>


      {/* Add Medication Stock */}
      <PermissionWrapper title="Documents" action="create">
        <Modal
          show={showAddMedication}
          onHide={subModalAddDocuments}
          className="ftcModalPopup medicineInventoryStock"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body className="rosterPopup">
            <AddDocuments
              userId={userDetails?.modelId?._id}
              subModalAddDocuments={subModalAddDocuments}
            />
          </Modal.Body>
        </Modal>
      </PermissionWrapper>
      {/* Edit Medication Stock */}
      <PermissionWrapper title="Documents" action="update">
        <Modal
          show={showEditDocumentation}
          onHide={subModalEditDocumentation}
          className="ftcModalPopup medicineInventoryStock"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body className="rosterPopup">
            <EditDocuments
              userId={userDetails?.modelId?._id}
              subModalEditDocumentation={subModalEditDocumentation}
              documentListDetail={documentListDetail}
            />
          </Modal.Body>
        </Modal>
      </PermissionWrapper>
      {/* Delete Medication Stock */}
      <PermissionWrapper title="Documents" action="delete">
        <Modal
          show={showDeleteMedication}
          onHide={subModalDeleteMedication}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this Document?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteMedication}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </PermissionWrapper>
      {isDocumentsGroup && <ToastContainer />}
    </>
  );
}

export default DocumentsGroup;
