import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

// Assets Images
import ftcLogo from "../assets/images/ftc_logo.svg";
import ProfileDropdown from "./header-toolbar/ProfileDropdown";
import HeaderMenu from "./header-toolbar/HeaderMenu";
import PageTitle from "./header-toolbar/PageTitle";
import { getNextWeekDatesStartingFrom } from "../helper/helperJsx";
import ProtectedComponent from "../permissions/ProtectedComponent";
import { permissions } from "../permissions/roles";
import PermissionWrapper from "../permission_advance/PermissionWrapper";
const drawerWidth = 260;

export default function Sidenav({ routePageTitle }) {
  const [open, setOpen] = useState([true]);
  const { startDate, endDate } = getNextWeekDatesStartingFrom(0);

  const location = useLocation();

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (index) => {
    setOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const navigate = useNavigate();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const drawer = (
    <div className="sideNavWrapper">
      <div className="logoBox">
        <img src={ftcLogo} alt="" className="FTCLogo" />
      </div>
      <List className="sideNavUl" sx={{ width: "100%", maxWidth: 360 }}>
        {/* Staff Overview */}
        <PermissionWrapper title="Staff Overview" action="view">
          <ListItemButton
            selected={isActive("/staff-overview")}
            onClick={() => {
              navigate("/staff-overview");
            }}
          >
            <ListItemText primary="Staff Overview" />
          </ListItemButton>
        </PermissionWrapper>
        {/* Roster */}
        {/* <ProtectedComponent requiredPermissions={[permissions.ROSTER]}> */}
        <PermissionWrapper title="Roster" action="view">
          <ListItemButton
            selected={isActive("/live-roster")}
            onClick={() => {
              navigate("/live-roster", {
                state: {
                  startDate: startDate,
                  endDate: endDate,
                  activeWeekTabNum: 0,
                },
              });
            }}
          >
            <ListItemText primary="Live Roster" />
          </ListItemButton>
        </PermissionWrapper>
        {/*  */}

        {/* Rota */}
        {/* <ListItemButton
          selected={isActive("/rota-templates")}
          onClick={() => {
            navigate("/rota-templates", { state: { navigateState: true } });
          }}
        >
          <ListItemText primary="Rota Templates" />
        </ListItemButton> */}

        {/* Rota Advance */}
        {/* <ProtectedComponent requiredPermissions={[permissions.ROSTER]}> */}
        <PermissionWrapper title="Roster" action="view">
          <ListItemButton
            selected={isActive("/rota-advanced")}
            onClick={() => {
              navigate("/rota-advanced", { state: { navigateState: true } });
            }}
          >
            <ListItemText primary="Rota Templates" />
          </ListItemButton>
        </PermissionWrapper>
        {/*  */}

        {/* Staff Records */}
        <PermissionWrapper title="Staff" action="view">
          <ListItemButton
            selected={isActive("/staff-records")}
            onClick={() => {
              navigate("/staff-records");
            }}
          >
            <ListItemText primary="Staff Records" />
          </ListItemButton>
        </PermissionWrapper>
        {/* Payrollw */}
        {/* <ProtectedComponent requiredPermissions={[permissions.PAYROLL]}> */}
        <PermissionWrapper title="Payroll" action="view">
          <ListItemButton
            selected={isActive("/payroll")}
            onClick={() => {
              navigate("/payroll");
            }}
          >
            <ListItemText primary="Payroll" />
          </ListItemButton>
        </PermissionWrapper>
        {/*  */}


        {/* Messaging System */}
        <PermissionWrapper title="Messaging System" action="view">
          <ListItemButton
            selected={isActive("/messaging-system")}
            onClick={() => {
              navigate("/messaging-system");
            }}
          >
            <ListItemText primary="Messaging System" />
          </ListItemButton>
        </PermissionWrapper>
        {/* Supervisions */}
        {/* <ProtectedComponent requiredPermissions={[permissions.SUPERVISION]}> */}
        <PermissionWrapper title="Supervision" action="view">
          <ListItemButton
            selected={isActive("/supervisions")}
            onClick={() => {
              navigate("/supervisions");
            }}
          >
            <ListItemText primary="Supervisions" />
          </ListItemButton>
        </PermissionWrapper>
        {/*  */}
        {/* Recruitments */}
        {/* <ProtectedComponent requiredPermissions={[permissions.RECRUITMENT]}> */}
        <PermissionWrapper title="Recruitment" action="view">
          <ListItemButton
            selected={
              isActive("/recruitment") ||
              isActive("/recruitment-application-process")
            }
            onClick={() => {
              navigate("/recruitment");
            }}
          >
            <ListItemText primary="Recruitment" />
          </ListItemButton>
        </PermissionWrapper>
        {/*  */}

        {/* Training */}
        {/* <ProtectedComponent requiredPermissions={[permissions.TRAINING]}> */}
        <PermissionWrapper title="Training" action="view">
          <ListItemButton
            selected={isActive("/training")}
            onClick={() => {
              navigate("/training");
            }}
          >
            <ListItemText primary="Training" />
          </ListItemButton>
        </PermissionWrapper>
        {/*  */}
        {/* Bradford Score */}
        {/* <ProtectedComponent requiredPermissions={[permissions.HOLIDAYS]}> */}

        {/*  */}
        {/* Medicine List */}
        <PermissionWrapper title="Medicine List" action="view">
          <ListItemButton
            selected={isActive("/medicine-list")}
            onClick={() => {
              navigate("/medicine-list");
            }}
          >
            <ListItemText primary="Medicine List" />
          </ListItemButton>
        </PermissionWrapper>

        {/* Holiday */}
        {/* <ProtectedComponent requiredPermissions={[permissions.HOLIDAYS]}> */}
        <PermissionWrapper title="Holiday" action="view">
          <ListItemButton
            selected={isActive("/holiday")}
            onClick={() => {
              navigate("/holiday");
            }}
          >
            <ListItemText primary="Holiday" />
          </ListItemButton>
        </PermissionWrapper>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
        }}
        className="contentHeader"
      >
        <Toolbar className="headerToolbar">
          <div className="headerToggleTitle">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <PageTitle pageTitleProps={routePageTitle} />
          </div>
          <Typography variant="h6" component="div">
            <div className="headerToolbarMenuProfile">
              <HeaderMenu />
              <ProfileDropdown />
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
        className="myWrappers"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
