import React, { useState } from "react";
import NameSpace from "./NameSpace";
import { Alert } from "react-bootstrap";
import Layout from "../../components/layouts/Layout";
import UserDetailHeader from "../../components/user-detail/UserDetailHeader";
import ListAllMembers from "../../pages/messaging_system_advanced/modals/ListAllMembers";
import CreateGroupChat from "../../pages/messaging_system_advanced/modals/CreateGroupChat";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import UserList from "./model/UserList";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";
function MessagingSystemAdvanced() {
  const [mobileNameSpace, setMobileNameSpace] = useState(false);
  const [isNewMsgSelectStaff, setIsNewMsgSelectStaff] = useState(false);
  const [isCreateGroupModal, setIsCreateGroupModal] = useState(false);
  const nameSpaceToggle = () => {
    setMobileNameSpace(!mobileNameSpace);
    console.log(mobileNameSpace);
  };

  const handleSelectStaffForMessage = () => {
    setIsNewMsgSelectStaff(true);
  };

  const handleCreateGroupModal = () => {
    setIsCreateGroupModal(true);
  };
  const handleCloseAddNewMsgModel = () => {
    setIsNewMsgSelectStaff(false);
  };

  const handleCloseCreateGroupModal = () => {
    setIsCreateGroupModal(false);
  };
  return (
    <>
      <Layout pageTitleText="Messaging System">
        <div className="messageWrapper">
          {/* <UserDetailHeader addNewMessage={true} /> */}
          {/* Header */}
          {/* fade alert alert-danger show  style={{ backgroundColor: "#ff0000" }}*/}
          <header className="headerContainerSearchAction">

            <div className="actionButtons">
              <div className="gap-1">
                <span
                  className="btn btn-sm btn-primary"
                  onClick={handleSelectStaffForMessage}
                >
                  New Chat
                </span>
                <PermissionWrapper title="Messaging System" action="create">
                  <span
                    className="btn btn-sm btn-primary"
                    onClick={handleCreateGroupModal}
                  >
                    Create Group
                  </span>
                </PermissionWrapper>
              </div>
            </div>
            {/* <h5 style={{ color: "#fff", margin: 0 }}>Code Upgrade in progress</h5> */}
          </header>

          <div className={
            !mobileNameSpace ? ("messageContainer") : "messageContainer responsiveMessageContainer"
          }>
            <NameSpace mobileDisplayStatus={mobileNameSpace} onMobile={nameSpaceToggle} />
          </div>
        </div>
      </Layout>
      {isNewMsgSelectStaff && (
        <UserList
          handleShowModel={isNewMsgSelectStaff}
          onClose={handleCloseAddNewMsgModel}
        />
      )}
      {isCreateGroupModal && (
        <CreateGroupChat
          handleShowModel={isCreateGroupModal}
          handleCloseModel={handleCloseCreateGroupModal}
        />
      )}
    </>
  );
}

export default MessagingSystemAdvanced;