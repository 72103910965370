// Draggable.jsx
import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import editBlock from "../../../assets/images/icons/editBlock.svg";
import deleteBasket from "../../../assets/images/icons/eventDel.svg";
import PermissionWrapper from '../../../permission_advance/PermissionWrapper';
export function Draggable(props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    cursor: 'move',
  };

  const handleEditButtonClick = (event) => {
    // Stop the event from propagating to the draggable element
    event.stopPropagation();
    if (props.onEditButtonClick) {
      props.onEditButtonClick(event);
    }
  };
  const handleDeleteButtonClick = (event) => {
    // Stop the event from propagating to the draggable element
    event.stopPropagation();
    if (props.onDeleteButtonClick) {
      props.onDeleteButtonClick(event);
    }
  };

  return (
    <>
      <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
        {props.children}
      </div>
      <div className="shiftActionButtons">
        <PermissionWrapper title="Roster" action="update">
          <img
            src={editBlock}
            alt="Edit"
            className="edit actionItem"
            onClick={handleEditButtonClick}
          />
        </PermissionWrapper>
        <PermissionWrapper title="Roster" action="delete">
          <img
            src={deleteBasket}
            alt="Delete"
            className="Delete curSorPointer actionItem"
            onClick={handleDeleteButtonClick}
          /></PermissionWrapper>
      </div>
    </>
  );
}
