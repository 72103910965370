import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import StatusUpdated from "../../../components/general/StatusUpdated";
import {
  ADMIN_ID,
  BASE_URL,
  GET_ROSTER_SHIFT_OVERVIEW,
} from "../../../env";
import {
  getRecords,
  updateRecords,
  catchAsync,
  isSuccess,
  formateDate,
  calculateHours,
  filterColumns,
  filterRowsByProperty,
  displayEnumProperty,
} from "../../../helper/helper";
import { wrap } from "lodash";
import moment from "moment/moment";
function Roster({ staffHeadingBlock }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setRecords] = useState([]);
  console.log("🚀 ~ Roster ~ userData:", userData);

  const apiQuery = {
    staffUserId: ADMIN_ID,
  };

  const fetchAllRecords = catchAsync(async () => {
    const response = await getRecords(GET_ROSTER_SHIFT_OVERVIEW, apiQuery);
    processFetchRecords("get", response);
  });

  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      // Flatten the data to include location in each shift
      const userData = data?.data?.flatMap((item) =>
        item.shifts.map((shift) => ({
          ...shift,
          location: item.location, // Add location to each shift
        }))
      );
      setIsLoading(false);
      setRecords(userData);
    }
    console.log("🚀 ~ processFetchRecords ~ userData:", userData);
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const columns = [
    {
      name: "Service User",
      selector: (row) => {
        return (
          <span className="usersNameInfo">
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.serviceUser?.profile_picture_url}`}
                alt={`${row?.serviceUser?.name}`}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">
                {`${row?.serviceUser?.modelId?.suFirstMiddleName}`}
                {" "}
                {`${row?.serviceUser?.modelId?.suLastName}`}
              </span>
              <span className="infoBlock">
                <small>{row.location}</small>
              </span>
            </span>
          </span>
        );
      },
    },

    {
      name: "Shift Date",
      selector: (row) =>
        moment(row.shiftDate).format("DD/MM/YYYY"), // Format the date
      width: "20%",
    },
    {
      name: "Shift Time",
      selector: (row) => (
        <>
          {row.shiftStartTime}{" - "}
          {row.shiftEndTime}
        </>
      ),
      width: "20%",
    },

    {
      name: "Work Hours",
      selector: (row) => row.workHrs,
      width: "20%",
      center: true,
    },

  ];

  return (
    <>
      <h4>{staffHeadingBlock}</h4>
      <div className="dataTableWrapper">
        <DataTable
          className="maxWidthTable"
          columns={columns}
          data={userData}
          fixedHeader
          fixedHeaderScrollHeight="280px"
          highlightOnHover
          responsive={true}
          progressPending={isLoading}
          progressComponent={<ProgressComponent />}
        />
      </div>
    </>
  );
}

export default Roster;
