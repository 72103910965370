import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import StatusUpdated from "../../../components/general/StatusUpdated";
import {
    ADMIN_ID,
    BASE_URL,
    GET_MEDICAITON_OVERVIEW_LIST,
} from "../../../env";
import {
    getRecords,
    updateRecords,
    catchAsync,
    isSuccess,
    formateDate,
    calculateHours,
    filterColumns,
    filterRowsByProperty,
    displayEnumProperty,
} from "../../../helper/helper";
import { wrap } from "lodash";
import moment from "moment/moment";

function MedicationOverview({ staffHeadingBlock }) {
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setRecords] = useState([]);
    console.log("🚀 ~ MedicationOverview ~ userData:", userData)

    const apiQuery = {
        staffUserId: ADMIN_ID
    };
    const fetchAllRecords = catchAsync(async (req, res) => {
        const response = await getRecords(
            GET_MEDICAITON_OVERVIEW_LIST,
            apiQuery
        );
        processFetchRecords("get", response);
    });

    const processFetchRecords = (method, data) => {
        if (method === "get" && data?.status === isSuccess) {
            const userData = data?.data?.map((item) => item);
            setIsLoading(false);
            setRecords(userData);
        }
    };

    useEffect(() => {
        fetchAllRecords();
    }, []);

    const columns = [
        {
            name: "Service User",
            selector: (row) => {
                return (
                    <span className="usersNameInfo">
                        <span className="userInfo">
                            <span className="nameBlock">{`${row?.suFirstMiddleName}`}{" "}{`${row?.suLastName}`}</span>
                        </span>
                    </span>
                );
            },
            width: "25%",
        },
        {
            name: "Medication Detail",
            selector: (row) => {
                return (
                    <>
                        <span>
                            {row?.medicationName}<br />
                            {/* <small>Type:{row?.medicationType}</small><br /> */}
                            <small>No of Doses: {row?.noOfDoses}</small>
                        </span>
                    </>
                );
            },
            wrap: true,
            width: "35%",
        },
        {
            name: "Status",
            selector: (row) => {
                return (
                    <>
                        <span>
                            {row?.status}
                        </span>
                    </>
                );
            },
            width: "15%",
            center: true
        },
        {
            name: "Schedule",
            selector: (row) => {
                return (
                    <>
                        <span>
                            Date: {moment(row?.date).format("DD/MM/YYYY")}
                            <br />
                            Time: {row?.startTime}
                        </span>
                    </>
                );
            },
        },

    ];

    return (
        <>
            <h4>{staffHeadingBlock}</h4>
            <div className="dataTableWrapper">
                <DataTable
                    className="maxWidthTable maxWidthTableRowWidth"
                    columns={columns}
                    data={userData}
                    fixedHeader
                    fixedHeaderScrollHeight="340px"
                    highlightOnHover
                    responsive={true}
                    progressPending={isLoading}
                    progressComponent={<ProgressComponent />}
                />
            </div>
        </>
    );
}

export default MedicationOverview;
