import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addPbsPlansStock } from "../../schemas";
import { useFormik } from "formik";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import ReactHtmlParser from 'html-react-parser';
import moment from 'moment';
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL, UPDTE_PBS_PLANS_URL } from "../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../components/forms-fields/TextArea";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import UserDetailReview from '../../components/user-detail/UserDetailReview';
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import PermissionWrapper from '../../permission_advance/PermissionWrapper';

function ViewRecentIncident() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userData = JSON.parse(storedUserDetails);
  const storedPBSDetails = localStorage.getItem("recentIncidentDetail");
  const recentIncidentDetail = JSON.parse(storedPBSDetails);
  const navigate = useNavigate();
  console.log("🚀 ~ EditPbsPlans ~ storedPBSDetails:", storedPBSDetails)
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddPbsPlans, setIsToastAddPbsPlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(recentIncidentDetail?.staffId?._id);
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
  };

  const editor = useRef(null);
  const [content, setContent] = useState(recentIncidentDetail?.note);

  const [suFormData, setSuFormData] = useState({

    userId: recentIncidentDetail?.userId?._id,
    staffId: recentIncidentDetail?.staffId?._id,
    note: content,
    id: recentIncidentDetail?._id
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addPbsPlansStock,
    onSubmit: async (values) => {
      try {
        console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postAddPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddPbsPlans(true);
      toastErrorMessages(toast.error, data?.message, "/recent-incidents", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postAddPbsPlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddPbsPlans(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
      }, 2000);
    }
  };

  const postAddPbsPlansFormData = catchAsync(async (req, res) => {
    console.log(values);
    const updatedValues = {
      ...values,
      note: content,
      staffId: selectedUserId,
    }
    const response = await postRecords(UPDTE_PBS_PLANS_URL, updatedValues);
    console.log("🚀 ~ postAddPbsPlansFormData ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddPbsPlansFormData", response);
  });


  const handleChangeTextEditor = (newContent) => {
    setContent(newContent);
  };

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Recent Incident
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
        <div className="viewEditorWrapper">
          <div className="mainContent mt-3 pt-4">
            <header className="mainContentHeader">
              <div className="usersNameInfo">

                <div className="contentBox">
                  <p className='m-0'><strong>Incident Date - Time</strong>
                    <br />
                    <small className='m-0'>
                      {moment(recentIncidentDetail?.incidentDate).format("DD/MM/YYYY / hh:mm:ss A")}
                    </small>
                  </p>
                </div>
              </div>
              <div>

                <span className="btn btn-sm btn-primary" onClick={() => {
                  navigate("/recent-incidents");
                }}>Back to Recent Incidents</span>
                <PermissionWrapper title="Recent Incidents" action="update">
                  &nbsp;
                  <span className="btn btn-sm btn-secondary" onClick={() => {
                    navigate("/edit-incidents");
                  }}>Edit</span>
                </PermissionWrapper>
              </div>
            </header>
            <div className="viewEditorContent">
              <h3> {recentIncidentDetail?.incidentTitle}</h3>
              {ReactHtmlParser(recentIncidentDetail?.note)}
            </div>
          </div>
        </div>
      </Layout>
      {isToastAddPbsPlans && <ToastContainer />}
    </>
  );
}

export default ViewRecentIncident