import React, { useState, useEffect } from "react";
import PatientOptions from "./PatientOptions";
import avatarEdit from "../../assets/images/icons/avatarEdit.svg";
import avatarMessage from "../../assets/images/icons/avatarMessage.svg";
import upDownArrow from "../../assets/images/icons/upDownArrow.svg";
import videoThumb from "../../assets/images/videoThumb.png";
import { BASE_URL } from "../../env";
import ServiceUserProfileUpdate from "../../components/modals/ServiceUserProfileUpdate";
import { GET_SERVICE_USER_BY_ID_URL, GET_ALL_SERVICE_USERS_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,

} from "../../helper/helper";
import ProgressComponent from "../general/ProgressComponent";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import moment from 'moment';
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import PermissionWrapper from "../../permission_advance/PermissionWrapper";

function UserDetailReview({
  getUserRecord,
  avatarActionButtons,
  viewNotes,
  profileDetail,
  patientOptions,
  horizontalDetail,
  verticalDetail,
  launchVideoButtons
}) {
  const initialUserData = getUserRecord;
  const [showVideo, setShowVideo] = useState(false);
  const [fullProfileDetail, setFullProfileDetail] = useState(false);
  const [dataID, setDataID] = useState(
    {
      query: {
        critarion: {
          // active: true
          _id: initialUserData
        }
      }
    }
  );
  const [profileUserDetails, setprofileUserDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  useEffect(() => {
    localStorage.setItem(
      "profileUserDetails",
      JSON.stringify(profileUserDetails)
    );
  }, [profileUserDetails])

  const handleToggleVideo = () => {
    setShowVideo(!showVideo);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setShowNotes(false);
    fetchAllRecords();
  };
  const handleShowNotes = () => {
    setShowNotes(!showNotes);
  };
  const showFullProfileDetail = () => {
    setFullProfileDetail(!fullProfileDetail);
  };

  //Get Single SU
  const processProfileData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data;
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    const getEnquiry = await getRecords(GET_ALL_SERVICE_USERS_URL, dataID);
    console.log("🚀 ~ fetchAllddddddRecords ~ getEnquiry:", getEnquiry)
    processProfileData("get", getEnquiry);
    setprofileUserDetails(getEnquiry?.data?.users[0]);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);
  return (
    <>
      {horizontalDetail ? (
        <>
          <div className="mainContent userProfile">
            {Object.keys(profileUserDetails).length === 0 ? (
              <ProgressComponent />
            ) : (
              <>
                <div className="userDetailReview">
                  <div className="userImage">
                    <div className="userAvatar">
                      {!profileUserDetails?.profile_picture_url ? (
                        <>
                          <img
                            src="https://placehold.co/600x400"
                            alt={profileUserDetails?.name}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={
                              BASE_URL + profileUserDetails?.profile_picture_url
                            }
                            alt={profileUserDetails?.name}
                          />
                        </>
                      )}

                      {avatarActionButtons ? (
                        <div className="avatarAction">
                          <PermissionWrapper title="Service User" action="update">
                            <span onClick={handleShowModal}>
                              <img src={avatarEdit} alt=" avatar edit" />
                            </span>
                          </PermissionWrapper>

                          {/* <span>
                            <img src={avatarMessage} alt="avatar message" />
                          </span> */}
                        </div>
                      ) : null}
                      {launchVideoButtons ? (
                        <>
                          <div className="videoActionButton mt-2">
                            <div className="text-center">
                              <Button
                                className="btn-sm"
                                variant="success"
                                onClick={handleToggleVideo}
                              >
                                Launch Video
                              </Button>
                            </div>
                            <Modal
                              show={showVideo}
                              onHide={handleToggleVideo}
                              className="ftcModalPopup patientVideoPopup"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  {profileUserDetails?.name}&nbsp;
                                  {profileUserDetails?.modelId?.suLastName}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="text-center">
                                  {
                                    profileUserDetails?.profile_video_url != "" ? (
                                      <video
                                        controls
                                        src={`${BASE_URL}${profileUserDetails?.profile_video_url}`}
                                        style={{ cursor: "pointer" }}
                                      />
                                    ) : (
                                      <>
                                        <img src={videoThumb} alt={videoThumb} />
                                        <p>
                                          <strong>
                                            {profileUserDetails?.name}&nbsp;
                                            {profileUserDetails?.modelId?.suLastName}'s
                                          </strong>{" "}
                                          Video is not available
                                        </p>
                                      </>
                                    )
                                  }


                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </>
                      ) : null}

                    </div>
                  </div>
                  <div className="detailTable">
                    <p className="currentStatus">
                      {profileUserDetails?.modelId?.currSU ? (
                        <>
                          <strong>Current Status:</strong> Active{" "}
                          <span>&nbsp;</span>
                        </>
                      ) : (
                        <>
                          <strong>Current Status:</strong> Inactive{" "}
                          <span style={{ background: "red" }}>&nbsp;</span>
                        </>
                      )}

                    </p>
                    <header>
                      <h3>
                        {profileUserDetails?.modelId?.suFirstMiddleName ? (
                          profileUserDetails?.modelId?.suFirstMiddleName
                        ) : (
                          profileUserDetails?.name
                        )}&nbsp;
                        {profileUserDetails?.modelId?.suLastName}&nbsp;
                        <small>
                          (&nbsp;
                          <span className="textTransformCapitalize">
                            {profileUserDetails?.modelId?.suSex}
                          </span>{" "}
                          {
                            profileUserDetails?.modelId?.suAge && (
                              <>
                                | {profileUserDetails?.modelId?.suAge}
                                &nbsp; Years&nbsp;
                              </>
                            )
                          }
                          )
                        </small>
                        <br />
                        <small><strong>Ref ID:</strong> {
                          profileUserDetails?.modelId?.suEnquiries[0]?.referenceId ? (
                            profileUserDetails?.modelId?.suEnquiries[0]?.referenceId
                          ) : (
                            profileUserDetails?.modelId?.referenceId
                          )
                        }</small>
                      </h3>
                      <div className="actionButtons">
                        {viewNotes ? (
                          <>
                            
                              <Link
                                to="/common-options"
                                className="btn btn-sm btn-primary"
                              >
                                Add Notes
                              </Link>
                            

                            &nbsp;
                            <Link
                              to="/care-plan/general-notes"
                              className="btn btn-sm btn-primary"
                            >
                              View Notes
                            </Link>
                          </>
                        ) : null}
                      </div>
                    </header>

                    <div className="userDetailContainer">
                      <div className="row">
                        <div className="col-md-3 col-sm-6">
                          <p>
                            <small>Email (home)</small>{" "}
                            {profileUserDetails?.modelId?.suEmailHome ? (
                              <>
                                <a href={profileUserDetails?.modelId?.suEmailHome}>
                                  {profileUserDetails?.modelId?.suEmailHome}
                                </a>
                              </>
                            ) : (

                              <>
                                <a href={profileUserDetails?.email}>
                                  {profileUserDetails?.email}
                                </a>
                              </>
                            )}

                          </p>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <p>
                            <small>Emergency contact no</small>

                            {profileUserDetails?.modelId?.suEmergencyContact ? (
                              profileUserDetails?.modelId?.suEmergencyContact
                            ) : (
                              profileUserDetails?.phoneNumber
                            )
                            }
                          </p>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <p>
                            <small>First visit date</small>
                            {profileUserDetails?.modelId?.suFirstVisitDate
                              ? (
                                moment(profileUserDetails?.modelId?.suFirstVisitDate).format("DD/MM/YYYY")
                              )
                              : ""}
                          </p>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <p>
                            <small>Last visit date</small>
                            {profileUserDetails?.modelId?.suLastVisitDate
                              ? (
                                moment(profileUserDetails?.modelId?.suLastVisitDate).format("DD/MM/YYYY")
                              )
                              : ""}
                          </p>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <p>
                            <small>Address1</small>{" "}
                            {profileUserDetails?.modelId?.suAddress1}
                          </p>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <p>
                            <small>Address2</small>
                            {profileUserDetails?.modelId?.suAddress2}
                          </p>
                        </div>
                        {profileDetail ? (
                          <div className="toggleProfileDetail">
                            {!fullProfileDetail ? (
                              <>
                                <span
                                  onClick={showFullProfileDetail}
                                  className="showDetail"
                                >
                                  See profile detail{" "}
                                  <img src={upDownArrow} alt={upDownArrow} />
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  onClick={showFullProfileDetail}
                                  className="hideDetail"
                                >
                                  Hide profile detail{" "}
                                  <img src={upDownArrow} alt={upDownArrow} />
                                </span>
                              </>
                            )}
                          </div>
                        ) : null}
                      </div>
                      {fullProfileDetail ? (
                        <>
                          <div className="fullDetail">
                            <div className="row">
                              <div className="col-md-3 col-sm-6">
                                <p>
                                  <small>Email (work)</small>
                                  {profileUserDetails?.modelId?.suEmailWork}
                                </p>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <p>
                                  <small>Preferred name</small>
                                  {profileUserDetails?.modelId?.suPreferredName}
                                </p>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <p>
                                  <small>DOB</small>
                                  {profileUserDetails?.modelId?.suDOB
                                    ? (
                                      moment(profileUserDetails?.modelId?.suDOB).format("DD/MM/YYYY")
                                    )
                                    : ""}
                                </p>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <p>
                                  <small>Family Head</small>
                                  {profileUserDetails?.modelId?.suFamilyHead}
                                </p>
                              </div>

                              <div className="col-md-3 col-sm-6">
                                <p>
                                  <small>SSN</small>
                                  {profileUserDetails?.modelId?.suSsn}
                                </p>
                              </div>

                              <div className="col-md-3 col-sm-6">
                                <p>
                                  <small>Refered by: </small>
                                  {profileUserDetails?.modelId?.suReferredBY}
                                </p>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>Provider</small>
                                  {profileUserDetails?.modelId?.providerName}
                                </p>
                              </div>

                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>Home Phone No.</small>
                                  {profileUserDetails?.modelId?.suHomePhone}
                                </p>
                              </div>

                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>Work Phone No.</small>
                                  {profileUserDetails?.modelId?.suWorkPhone}
                                </p>
                              </div>

                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>Mobile Home No.</small>
                                  {profileUserDetails?.modelId?.suMobileHomeNo}
                                </p>
                              </div>

                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>Mobile Work No.</small>
                                  {profileUserDetails?.modelId?.suMobileWorkNo}
                                </p>
                              </div>

                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>Pref. Home No.</small>
                                  {profileUserDetails?.modelId?.suPrefHomeNo}
                                </p>
                              </div>

                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>Pref. Work No.</small>
                                  {profileUserDetails?.modelId?.suPrefWorkNo}
                                </p>
                              </div>

                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>City</small>
                                  {profileUserDetails?.modelId?.suCity}
                                </p>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>State</small>
                                  {profileUserDetails?.modelId?.suState}
                                </p>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <p>
                                  <small>Zip</small>
                                  {profileUserDetails?.modelId?.suZip}
                                </p>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <p>
                                  <small>Funding by</small>
                                  {profileUserDetails?.modelId?.seMedicalAlert}
                                </p>
                              </div>
                              <div className="col-md-12">
                                <p>
                                  <small>Notes</small>
                                  {profileUserDetails?.modelId?.suNote}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {patientOptions ? (
            <>
              <div className="patientOptions">
                <PatientOptions getUserRecord={profileUserDetails} />
              </div>
            </>
          ) : null}

          {profileUserDetails && (
            
              <ServiceUserProfileUpdate
                showModal={showModal}
                closeModal={handleCloseModal}
                profileDataId={dataID}
              />
            

          )}
        </>
      ) : verticalDetail ? (
        <>
          <div className="profileDetailBlock">
            {Object.keys(profileUserDetails).length === 0 ? (
              <ProgressComponent />
            ) : (
              <>
                <div className="userAvatar">
                  {!profileUserDetails?.profile_picture_url ? (
                    <>
                      <img
                        src="https://placehold.co/600x400"
                        alt={profileUserDetails?.name}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={BASE_URL + profileUserDetails?.profile_picture_url}
                        alt={profileUserDetails?.name}
                      />
                    </>
                  )}

                  {avatarActionButtons ? (
                    <div className="avatarAction">
                      <span onClick={handleShowModal}>
                        <img src={avatarEdit} alt=" avatar edit" />
                      </span>
                      <span>
                        <img src={avatarMessage} alt="avatar message" />
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="userDetail">
                  <p className="currentStatus">
                    {profileUserDetails?.modelId?.currSU ? (
                      <>
                        <strong>Current Status:</strong> Active{" "}
                        <span>&nbsp;</span>
                      </>
                    ) : (
                      <>
                        <strong>Current Status:</strong> Inactive{" "}
                        <span style={{ background: "red" }}>&nbsp;</span>
                      </>
                    )}
                  </p>
                  <p>
                    {profileUserDetails?._id}
                    <small>Email (home)</small>
                    <a href={profileUserDetails?.modelId?.suEmailHome}>
                      {profileUserDetails?.modelId?.suEmailHome}
                    </a>
                  </p>
                  <p>
                    <small>Emergency contact no</small>
                    {profileUserDetails?.modelId?.suEmergencyContact}
                  </p>
                  <p>
                    <small>First visit date</small>
                    {profileUserDetails?.modelId?.suFirstVisitDate
                      ? (
                        moment(profileUserDetails?.modelId?.suFirstVisitDate).format("DD/MM/YYYY")
                      )
                      : ""}
                  </p>
                  <p>
                    <small>Last visit date</small>
                    {profileUserDetails?.modelId?.suLastVisitDate
                      ? (
                        moment(profileUserDetails?.modelId?.suLastVisitDate).format("DD/MM/YYYY")
                      )
                      : ""}
                  </p>
                  <p>
                    <small>Address1</small> {profileUserDetails?.modelId?.suAddress1}
                  </p>
                  <p>
                    <small>Address2</small> {profileUserDetails?.modelId?.suAddress2}
                  </p>
                </div>
              </>
            )}
          </div>
        </>
      ) : null}
    </>
  );
}

export default UserDetailReview;
