import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import { editCarePlanDocument } from "../../../../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_CARE_PLAN_URL, ADMIN_ID } from "../../../../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
function IsSafeguardingReviewed({ isReviewed, setIsReviewed, carePlanData }) {
  //   console.log("🚀 ~ IsSafeguardingReviewed ~ carePlanData:", carePlanData?._id)
  const [isToastAddPbsPlans, setIsToastAddPbsPlans] = useState(false);
  const editor = useRef(null);

  const [suFormData, setSuFormData] = useState({
    carePlanid: carePlanData?._id,
    // reviewedBy: ADMIN_ID,
    isReviewed: isReviewed,
    reviewedBy: {
      reviewedUser: ADMIN_ID,
      reviewedTime: moment().valueOf(),
    },
  });
  //   console.log("🚀 ~ IsSafeguardingReviewed ~ suFormData:", suFormData)

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: suFormData,
    onSubmit: async (values) => {
      try {
        await postEditPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const postEditPbsPlansFormData = async (updatedValues) => {
    console.log("🚀 ~ postEditPbsPlansFormData ~ updatedValues:", updatedValues)
    const response = await postRecords(UPDTE_CARE_PLAN_URL, updatedValues);
    processPostRecords("postEditPbsPlansFormData", response);
  };

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddPbsPlans(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
    } else if (method === "postEditPbsPlansFormData" && data?.status === isSuccess) {
      setIsToastAddPbsPlans(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsReviewed(true); // Update the isReviewed state in the parent
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Form.Check
          type="checkbox"
          id="isReviewed"
          name="isReviewed"
          checked={isReviewed || values?.isReviewed}
          onChange={(e) => {
            handleChange(e);
            handleSubmit();
          }}
          onBlur={handleBlur}
          disabled={isReviewed}
          label="Marked as Reviewed"
        />
      </form>

      {isToastAddPbsPlans && <ToastContainer />}
    </>
  );
}

export default IsSafeguardingReviewed;