import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import password_eye_icon_circle from "../../assets/images/icons/password_eye_icon_circle.svg";
import { GET_ALL_SERVICE_USERS_URL, GET_ALL_SERVICE_USERS_REVIEW_LIST } from "../../env";
import Table from 'react-bootstrap/Table';
import {
    getRecords,
    isSuccess,
    catchAsync,
} from "../../helper/helper";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../env";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PermissionWrapper from "../../permission_advance/PermissionWrapper";

function OverviewTaskLists({ overviewTaskData, taskType }) {
    const [taskDataType, setTaskDataType] = useState("upcoming");
    const [isLoading, setIsLoading] = useState(true);
    const [profileUserDetails, setProfileUserDetails] = useState({});
    const [dataID, setDataID] = useState(null); // Moved to top level
    const navigate = useNavigate();
    useEffect(() => {
        if (taskType === "Due Tasks") {
            setTaskDataType("due");
        } else if (taskType === "Urgent Tasks") {
            setTaskDataType("urgent");
        } else {
            setTaskDataType("upcoming");
        }
        setIsLoading(false);
    }, [taskType]);

    const fetchServiceUserData = async (serviceUserId) => {
        try {
            const dataID = {
                query: {
                    critarion: {
                        _id: serviceUserId,
                    },
                },
            };

            const getEnquiry = await getRecords(GET_ALL_SERVICE_USERS_URL, dataID);
            if (getEnquiry?.status === isSuccess) {
                await setProfileUserDetails(getEnquiry?.data?.users[0]);
                // Save profileUserDetails in localStorage
                localStorage.setItem("profileUserDetails", JSON.stringify(getEnquiry?.data?.users[0]));

            } else {
                console.error("Failed to fetch service user data.");
            }
        } catch (error) {
            console.error("Error fetching service user data:", error);
        }
    };

    const handleEditViewPBSPlans = async (item, url, serviceUserId) => {
        try {
            // Fetch service user data
            await fetchServiceUserData(serviceUserId);

            // Save PBS plan details in localStorage
            if (item) {
                localStorage.setItem("pbsPlanDetail", JSON.stringify(item));
            } else {
                console.error("Item is undefined");
            }

            navigate(url);

        } catch (error) {
            console.error("Error during handleEditViewPBSPlans: ", error);
        }
    };

    // useEffect(() => {
    //     localStorage.setItem(
    //       "profileUserDetails",
    //       JSON.stringify(profileUserDetails)
    //     );
    //   }, [profileUserDetails])

    //Internal Reviews Data
    const [reviewReviewListData, setReviewReviewListData] = useState([]);
    const [noRecordFound, setNoRecordFound] = useState(false);
    const [apiQuery, setApiQuery] = useState({});
    const processReviewData = (method, data) => {

        if (method === "get" && data?.status === isSuccess) {
            const allData = data?.data;
            console.log("🚀 ~ processReviewData ~ allData:", data?.data)
            // console.log("my outstanding enq list: ", allData);
            if (allData && allData.length <= 0) {
                setNoRecordFound(true);
            } else {
                // setNoRecordFound(false);
                // setIsLoading(false);
                // setRecords(allData);
                setReviewReviewListData(allData);
            }
        }
    };

    const fetchAllRecords = async (apiQuery) => {
        try {
            const getReview = await getRecords(GET_ALL_SERVICE_USERS_REVIEW_LIST, apiQuery);
            console.log("🚀 ~ fetchAllRecords ~ Fetched Data:", getReview);
            return getReview; // Return the fetched data
        } catch (error) {
            console.error("Error fetching records:", error);
            return [];
        }
    };

    const handleRowClickStaffMember = async (item, apiQuery, reviewId) => {
        console.log("🚀 ~ handleRowClickStaffMember ~ reviewId:", reviewId);

        // Fetch all records
        const fetchedData = await fetchAllRecords(apiQuery);

        if (Array.isArray(fetchedData?.data)) {
            const matchedReviewData = fetchedData?.data.find(record => record._id === reviewId);

            console.log("🚀 ~ handleRowClickStaffMember ~ matchedReviewData:", matchedReviewData);

            if (matchedReviewData) {
                await localStorage.setItem("userReviewData", JSON.stringify(matchedReviewData));
            } else {
                console.warn(`No matching review found for reviewId: ${reviewId}`);
            }
        } else {
            console.error("Fetched data is not an array:", fetchedData);
        }

        localStorage.setItem("profileUserDetails", JSON.stringify(item));
        // Navigate (uncomment this if needed)
        navigate("/view-individual-reviews");
    };



    // console.log("Internal Overviews ", overviewTaskData?.internalReviews[taskDataType])
    // console.log("carePlan ", overviewTaskData?.carePlan[taskDataType])
    console.log("carePlan ", overviewTaskData?.supervisions[taskDataType])
    // console.log("safeguarding ", overviewTaskData?.safeguarding[taskDataType])
    // console.log("PBS Overview ", overviewTaskData?.pbsPlan[taskDataType])
    return (
        <div className="dataTableWrapper taskList">
            {
                overviewTaskData?.pbsPlan[taskDataType]?.length > 0 ||
                    overviewTaskData?.carePlan[taskDataType]?.length > 0 ||
                    overviewTaskData?.internalReviews[taskDataType]?.length > 0 ||
                    overviewTaskData?.safeguarding[taskDataType]?.length > 0 ? (
                    <Table striped bordered className="overViewTaskDetail">
                        <thead>
                            <tr>
                                <th>Service User</th>
                                <th>Type</th>
                                <th>Due Date</th>
                                <th className="text-center">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* PBS Plan Task */}
                            {
                                overviewTaskData?.pbsPlan[taskDataType]?.map((item) => (
                                    <tr>
                                        <td>
                                            <span className="usersNameInfo">
                                                <span className="userImage">
                                                    <img
                                                        src={`${BASE_URL}${item?.userId?.profile_picture_url}`}
                                                        alt={`${item?.userId?.modelId?.suFirstMiddleName}`}
                                                    />
                                                </span>
                                                <span className="userInfo">
                                                    <span className="nameBlock">
                                                        {`${item?.userId?.modelId?.suFirstMiddleName}`}
                                                        {" "}
                                                        {`${item?.userId?.modelId?.suLastName}`}
                                                    </span>
                                                </span>
                                            </span>
                                        </td>
                                        <td>PBS Plan</td>
                                        <td><span>{moment(item?.completionDate).format("DD/MM/YYYY")}</span></td>
                                        <td><div className="actionColumns individualReviewsActions">
                                            <span className="edit" onClick={() => handleEditViewPBSPlans(item, "/view-plans", item?.userId?._id)}>
                                                <img src={password_eye_icon_circle} alt="View" title="View" />
                                            </span>
                                        </div></td>
                                    </tr>
                                ))}

                            {/* Care Plan Task */}
                            {
                                overviewTaskData?.carePlan[taskDataType]?.map((item) => (
                                    <tr>
                                        <td>
                                            <span className="usersNameInfo">
                                                <span className="userImage">
                                                    <img
                                                        src={`${BASE_URL}${item?.userId?.profile_picture_url}`}
                                                        alt={`${item?.userId?.modelId?.name}`}
                                                    />
                                                </span>
                                                <span className="userInfo">
                                                    <span className="nameBlock">{`${item?.userId?.name}`}
                                                        {
                                                            item?.userId?.modelId?.suLastName && (
                                                                <>
                                                                    {" "}
                                                                    {`${item?.userId?.modelId?.suLastName}`}
                                                                </>
                                                            )
                                                        }
                                                    </span>
                                                </span>
                                            </span>
                                        </td>
                                        <td>Care Plan</td>
                                        <td><span>{moment(item?.eventDateTime).format("DD/MM/YYYY")}</span></td>
                                        <td><div className="actionColumns individualReviewsActions">
                                            <span className="edit" onClick={() => handleEditViewPBSPlans(item, "/view-care-plan", item?.userId?._id)}>
                                                <img src={password_eye_icon_circle} alt="View" title="View" />
                                            </span>
                                        </div></td>
                                    </tr>
                                ))}

                            {/* Internal Reviews */}
                            {
                                overviewTaskData?.internalReviews[taskDataType]?.map((item) => (
                                    <tr>
                                        <td>
                                            <span className="usersNameInfo">
                                                <span className="userImage">
                                                    <img
                                                        src={`${BASE_URL}${item?.commentFor?.profile_picture_url}`}
                                                        alt={`${item?.commentFor?.modelId?.name}`}
                                                    />
                                                </span>
                                                <span className="userInfo">
                                                    {/* <span className="nameBlock">{`${item?.commentFor?.modelId?.name}`}</span> */}
                                                    <span className="nameBlock">
                                                        {`${item?.commentFor?.modelId?.suFirstMiddleName}`}
                                                        {" "}
                                                        {`${item?.commentFor?.modelId?.suLastName}`}
                                                    </span>
                                                </span>
                                            </span>
                                        </td>
                                        <td>Internal Reviews</td>
                                        <td><span>{moment(item?.personalDetails?.dor).format("DD/MM/YYYY")}</span></td>
                                        <td><div className="actionColumns individualReviewsActions">
                                            <span className="edit" onClick={
                                                () => handleRowClickStaffMember(
                                                    item?.commentFor, {
                                                    userId: item?.commentFor?._id,
                                                },
                                                    item?._id
                                                )}>
                                                <img src={password_eye_icon_circle} alt="View" title="View" />
                                            </span>
                                        </div></td>
                                    </tr>
                                ))}

                            {/* safeguarding */}
                            {
                                overviewTaskData?.safeguarding[taskDataType]?.map((item) => (
                                    <tr>
                                        <td>
                                            <span className="usersNameInfo">
                                                <span className="userImage">
                                                    <img
                                                        src={`${BASE_URL}${item?.userId?.profile_picture_url}`}
                                                        alt={`${item?.userId?.name}`}
                                                    />
                                                </span>
                                                <span className="userInfo">
                                                    {/* <span className="nameBlock">{`${item?.userId?.modelId?.name}`}</span> */}
                                                    <span className="nameBlock">
                                                        {`${item?.userId?.name}`}
                                                        {
                                                            item?.userId?.modelId?.suLastName && (
                                                                <>
                                                                    {" "}
                                                                    {`${item?.userId?.modelId?.suLastName}`}
                                                                </>
                                                            )
                                                        }

                                                    </span>
                                                </span>
                                            </span>
                                        </td>
                                        <td>Safeguarding</td>
                                        <td><span>{moment(item?.eventDateTime).format("DD/MM/YYYY")}</span></td>
                                        <td><div className="actionColumns individualReviewsActions">
                                            <span className="edit" onClick={() => handleEditViewPBSPlans(item, "/care-plan/safeguarding", item?.userId)}>
                                                <img src={password_eye_icon_circle} alt="View" title="View" />
                                            </span>
                                        </div></td>
                                    </tr>
                                ))}

                        </tbody>
                    </Table>
                ) : overviewTaskData?.pbsPlan[taskDataType]?.length === 0 &&
                overviewTaskData?.carePlan[taskDataType]?.length === 0 &&
                overviewTaskData?.internalReviews[taskDataType]?.length === 0 &&
                overviewTaskData?.supervisions[taskDataType]?.length === 0 &&
                overviewTaskData?.safeguarding[taskDataType]?.length === 0 && (
                    <div className="noRecordFound">
                        <p>No records found.</p>
                    </div>
                )
            }

            <PermissionWrapper title="Supervision" action="view">
                {
                    overviewTaskData?.supervisions[taskDataType]?.length > 0 ? (
                        <>
                            <br />
                            <h4>Supervision</h4>

                            <Table striped bordered className="overViewTaskDetail">
                                <thead>
                                    <tr>
                                        <th>Staff Member</th>
                                        <th>Type</th>
                                        <th>Due Date</th>
                                        <th className="text-center">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Supervisions */}
                                    {
                                        overviewTaskData?.supervisions[taskDataType]?.map((item) => (
                                            <tr>
                                                <td>
                                                    <span className="usersNameInfo">
                                                        <span className="userImage">
                                                            <img
                                                                src={`${BASE_URL}${item?.staffMember?.profile_picture_url}`}
                                                                alt={`${item?.staffMember?.name}`}
                                                            />
                                                        </span>
                                                        <span className="userInfo">
                                                            <span className="nameBlock">{`${item?.staffMember?.name}`}
                                                                {
                                                                    item?.staffMember?.modelId?.lastName && (
                                                                        <>
                                                                            {" "}
                                                                            {`${item?.staffMember?.modelId?.lastName}`}
                                                                        </>
                                                                    )
                                                                }
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>Supervisions</td>
                                                <td><span>{moment(item?.sprDueDate).format("DD/MM/YYYY")}</span></td>
                                                <td><div className="actionColumns individualReviewsActions">
                                                    <span className="edit" onClick={() => handleEditViewPBSPlans(item, "/supervisions", item?.userId?._id)}>
                                                        <img src={password_eye_icon_circle} alt="View" title="View" />
                                                    </span>
                                                </div></td>
                                            </tr>
                                        ))}

                                </tbody>
                            </Table>
                        </>
                    ) : null
                }
            </PermissionWrapper>
        </div>
    );
}

export default OverviewTaskLists;
