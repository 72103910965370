import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Tab, Nav, Button, Modal } from "react-bootstrap";
import ProtectedConditionalComponent from "../../permissions/ProtectedConditionalComponent";
import { permissions } from "../../permissions/roles";

function StatusUpdated({ initialStatus, onChange, isSuccessReturn, isDisabled }) {
  const [status, setStatus] = useState(initialStatus);
  // const [accept, setAccept] = useState(acceptedOption);
  const statusClassMapping = {
    approved: "background-success text-white",
    pending: "background-warning text-dark",
    rejected: "background-danger text-white",
    APPROVED: "background-success text-white",
    PENDING: "background-warning text-dark",
    REJECTED: "background-danger text-white",
    Accepted: "background-success text-white",
    Pending: "background-warning text-dark",
    Rejected: "background-danger text-white",
  };
  const statusConditionalClassMapping = {
    approved: "background-success",
    pending: "background-warning",
    rejected: "background-danger",
    APPROVED: "background-success",
    PENDING: "background-warning",
    REJECTED: "background-danger",
    Accepted: "background-success",
    Pending: "background-warning",
    Rejected: "background-danger",
  };
  const handleStatusChange = (event) => {
    const newStatus = event?.target?.value;
    onChange(event, newStatus);
    setStatus(newStatus);
    console.log("🚀 ~ handleStatusChange ~ status:", status)
  };


  useEffect(() => {
    setStatus(initialStatus);
  }, [setStatus]);

  const FallbackComponent = ({ status, statusConditionalClassMapping }) => {
    return (
      <div className={`conditionalTextStatus ${statusConditionalClassMapping[status]}`}>
        {status} { }
      </div>
    );
  };
  return (
    // <FallbackComponent status={status} statusConditionalClassMapping={statusConditionalClassMapping} />
    <div className={`select-box status-select-box ${statusClassMapping[status]}`}>
      <Form.Select
        aria-label="Default select example"
        value={status}
        onChange={handleStatusChange}
        disabled={isDisabled}
      >
        {status === "approved" || status === "APPROVED" || status === "Accepted" ? (
          <>
            <option value="approved">Approved</option>
            <option value="pending">Pending</option>
            <option value="rejected">Reject</option>
          </>
        ) : status === "pending" || status === "PENDING" || status === "Pending" ? (
          <>
            <option value="pending">Pending</option>
            <option value="approved">Approve</option>
            <option value="rejected">Reject</option>
          </>
        ) : status === "rejected" || status === "REJECTED" || status === "Rejected" ? (
          <>
            <option value="rejected">Rejected</option>
            <option value="pending">Pending</option>
            <option value="approved">Approve</option>

          </>
        ) : (
          ""
        )}
      </Form.Select>

    </div>
  );
}

export default StatusUpdated;
