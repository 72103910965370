import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextArea from "../../components/forms-fields/TextArea";
import Input from "../../components/forms-fields/Input";
import CustomSelectSupervisionTemplate from "../../components/forms-fields/CustomSelectSupervisionTemplates";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import AddPlusIcon from "../../assets/images/icons/addPlusIcon.svg";
import questionEdit from "../../assets/images/icons/questionEdit.svg";
import actionButtonDel from "../../assets/images/icons/actionButtonDel.svg";
import actionButtonTick from "../../assets/images/icons/actionButtonTick.svg";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";
import {
  GET_ALL_STAFF_MEMBERS_SUPERVISIONS_QA_URL,
  POST_ASSIGN_NEXT_SUPERVISION_URL,
  POST_STAFF_SUPERVISION_Q_ANSWERS_URL,
  POST_SUPERVISION_TEMPLATE_COMMENT_URL,
  GET_SUPERVISION_ID_URL,
  BASE_URL,
  ADMIN_ID,
} from "../../env";

import {
  postRecords,
  catchAsync,
  isSuccess,
  toastErrorMessages,
  toastSuccessMessages,
  formateDate,
  apiQuery,
} from "../../helper/helper";
import moment from "moment/moment";

// Helper function for generating validation schema
const generateValidationSchema = (fields) => {
  const validationObject = {};

  fields.forEach((field) => {
    validationObject[field?.name] = Yup.string().required(
      `${field?.label} is required`
    );
  });

  validationObject.sprDueDate = Yup.date()
    .required("Due date is required")
    .min(new Date(), "Due date must be greater than the current date");

  return Yup.object().shape(validationObject);
};

const SupervisionQA = ({ getUserRecord }) => {
  const location = useLocation();
  const initialUserData = getUserRecord;
  const [showAddSupervisionModal, setShowAddSupervisionModal] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isAddNewAnswer, setIsAddNewAnswer] = useState(false);
  const [isToastSupervisionQA, setIsToastSupervisionQA] = useState(false);
  const supervisionQA = location?.state?.supervisionQA;
  // console.log("🚀 ~ SupervisionQA ~ supervisionQA:", supervisionQA)
  const [supervisionData, setSupervisionData] = useState(
    supervisionQA?.supervisionQuestionsAnswers
  );
  const [isCommentBoxClicked, setIsCommentBoxClicked] = useState(false);
  const [isAOBCommentBoxClicked, setIsAOBCommentBoxClicked] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [submitAdminAnswer, setSubmitAdminAnswer] = useState(false);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true, staffMember: initialUserData?._id },
    },
  };

  // how parent contact check boxes list
  const [howParentContacted, setHowParentContacted] = useState([
    { id: 1, values: "Met", label: "Met", checked: false },
    { id: 2, values: "Partially Met", label: "Partially Met", checked: false },
    { id: 3, values: "Not Met", label: "Not Met", checked: false },
  ]);

  const [templateFormData, setTemplateFormData] = useState();
  useEffect(() => {
    setTemplateFormData({
      supervisionQTemplateId: supervisionQA?.supervisionQuestionTemplate?._id,
      adminComment: supervisionQA?.supervisionQuestionTemplate?.adminComment,
      anyOtherBusinessComment: supervisionQA?.supervisionQuestionTemplate?.anyOtherBusinessComment,
      admin: ADMIN_ID,
    })
  }, [supervisionQA])
  const [editAnswerFormData, setEditAnswerFormData] = useState({
    _id: "",
    adminAnswer: "hello",
    admin: ADMIN_ID,
  });
  const [formData, setFormData] = useState({
    _id: "",
    adminAnswer: "",
    admin: ADMIN_ID,
  });
  const formFields = [
    { name: "sprDueDate", label: "Supervision Due Date" },
    { name: "sprStatus", label: "Supervision Status" },
    { name: "sprResult", label: "Supervision Result" },
    { name: "staffMember", label: "Staff Member" },
    { name: "supervisor", label: "Supervisor" },
    { name: "templateTitleId", label: "Supervision Template" },
  ];


  const validationSchema = generateValidationSchema(formFields);

  const formikFormData = useFormik({
    initialValues: {
      supervisionName: "SupervisorName",
      sprDueDate: "",
      sprStatus: "assigned",
      sprResult: "passed",
      staffMember: initialUserData?._id,
      supervisor: "",
      templateTitleId: "",
    },
    validationSchema,
    onSubmit: useCallback(async (values) => {
      setIsFormSubmit(true);
      await assignNextSupervision(values);
    }, []),
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = formikFormData;

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastSupervisionQA(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
      setIsFormSubmit(false);
    } else if (
      method === "getSupervisionsQAnswers" &&
      data?.status === isSuccess
    ) {
      // console.log("dsfsdfsdfsfsdfsdf", data?.data)
      setSupervisionData(
        data?.data?.supervisions[0]?.supervisionQuestionsAnswers
      );
    } else if (
      method === "assignNextSupervision" &&
      data?.status === isSuccess
    ) {
      setIsToastSupervisionQA(true);
      handleCloseModal();
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
      setIsFormSubmit(false);
      // window.history.back();
    } else if (method === "updateAnswer" && data?.status === isSuccess) {
      fetchAllRecords();
      setIsToastSupervisionQA(true);
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
    } else if (method === "addTemplateComment" && data?.status === isSuccess) {
      fetchAllRecords();
      setIsToastSupervisionQA(true);
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
    }
  };

  const [sprStatusUpdate, setSprStatusUpdate] = useState(false)
  useEffect(() => {
    console.log("🚀 ~ SupervisionQA ~ sprStatusUpdate:", sprStatusUpdate)

  }, [sprStatusUpdate])
  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await postRecords(
      GET_ALL_STAFF_MEMBERS_SUPERVISIONS_QA_URL,
      apiQuery
    );
    processFetchRecords("getSupervisionsQAnswers", response);

  });

  const assignNextSupervision = catchAsync(async (req, res) => {
    const response = await postRecords(POST_ASSIGN_NEXT_SUPERVISION_URL, req);
    processFetchRecords("assignNextSupervision", response);
    setSprStatusUpdate(
      response?.data?._id !== supervisionQA?._id ? true : false
    );
    // console.log("🚀 ~ assignNextSupervision ~ response:", response)
    // console.log("🚀 ~ updateAnswer ~ supervisionQA?._id:", supervisionQA?._id)
    // console.log("🚀 ~ updateAnswer ~ response?.data?._id:", response?.data?._id)
  });

  const updateAnswer = catchAsync(async (req, res) => {
    // console.log({ req });
    const response = await postRecords(
      POST_STAFF_SUPERVISION_Q_ANSWERS_URL,
      req
    );
    processFetchRecords("updateAnswer", response);


  });

  const postTemplateComment = catchAsync(async (req, res) => {
    // console.log({ req });
    const response = await postRecords(
      POST_SUPERVISION_TEMPLATE_COMMENT_URL,
      req
    );
    // console.log(response);
    processFetchRecords("addTemplateComment", response);
  });

  const handleInputChange = (e, item) => {
    e.preventDefault();
    const { name, value } = e.target;
    setEditAnswerFormData((prevData) => ({
      ...prevData,
      adminAnswer: value,
      _id: item?._id,
    }));
    // console.log(editAnswerFormData);
  };

  const handleInputChangeNewAnswer = (e, item) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      _id: item?._id,
    }));
  };

  const handleInputChangeTemplateCommentBox = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setTemplateFormData((prevData) => ({
      ...prevData,
      adminComment: value,
    }));
  };
  const handleInputChangeTemplateAOBCommentBox = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setTemplateFormData((prevData) => ({
      ...prevData,
      anyOtherBusinessComment: value,
    }));
    
  };
  // const filteredArray = formData.filter((item) => item.id === "j");

  const handleInputCommentBoxClick = (e) => {
    e.preventDefault();
    setIsCommentBoxClicked(!isCommentBoxClicked);
  };
  const handleInputAOBCommentBoxClick = (e) => {
    e.preventDefault();
    setIsAOBCommentBoxClicked(!isAOBCommentBoxClicked);
  };

  const handleSetAnswer = (item) => {
    setEditAnswerFormData((prevData) => ({
      ...prevData,
      _id: item?._id,
      adminAnswer: item?.adminAnswer,
    }));
    setIsEditable(item?._id);
  };

  const handleUpdateAnswer = (e) => {
    e.preventDefault();
    setIsEditable(false);
    updateAnswer(editAnswerFormData);
    setTimeout(() => {
      setFormData((prevData) => ({
        ...prevData,
        adminAnswer: "",
        _id: ""

      }));
    }, 2000);
  };

  const handleSubmitNewAnswer = (e) => {
    e.preventDefault();
    setSubmitAdminAnswer(false);
    if (formData?.adminAnswer !== "") {
      updateAnswer(formData);
    }
  };

  const handleShowAddNewAnswerBox = (e, item) => {
    e.preventDefault();
    // console.log(item);
    // setEditAnswerFormData((prevData) => ({
    //   ...prevData,
    //   _id: item?._id,
    //   adminAnswer: item?.adminAnswer,
    // }));
    setFormData((prevData) => ({
      ...prevData,
      adminAnswer: "",


    }));
    setIsAddNewAnswer(true);
    setSubmitAdminAnswer(item?._id);
  };

  const handleSubmitTemplateComment = (e) => {
    e.preventDefault();
    setIsCommentBoxClicked(false);
    postTemplateComment(templateFormData);
  };
  const handleSubmitTemplateAOBComment = (e) => {
    e.preventDefault();
    setIsAOBCommentBoxClicked(false);
    postTemplateComment(templateFormData);
  };

  const handleCloseEditableAnswerBox = (e) => {
    e.preventDefault();
    setSubmitAdminAnswer(false);
    setIsEditable(false);
  };

  const handleShowNextSupervisionForm = (e) => {
    e.preventDefault();
    setShowAddSupervisionModal(true);
  };

  const handleCloseModal = () => {
    setShowAddSupervisionModal(false);
    resetForm();
  };

  const handleCancelButton = (e) => {
    e.preventDefault();
    window.history.back();
  };

  useEffect(() => {
    setSupervisionData(supervisionQA?.supervisionQuestionsAnswers);
  }, [supervisionQA?.supervisionQuestionsAnswers]);

  // console.log(supervisionQA);

  // MCQs Radio Button Question
  const handleInputMCQsChange = (e, item) => {
    const { value } = e.target;

    setEditAnswerFormData((prevData) => ({
      ...prevData,
      adminAnswer: value,
      _id: item?._id,
    }));

    handleChange(e);
    handleBlur(e);
  };

  // New Question
  // const handleInputNewMCQsChange = (e, item) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;

  //   // Update the checked status in howParentContacted based on the selected value
  //   setHowParentContacted((prev) =>
  //     prev.map((option) => ({
  //       ...option,
  //       checked: option.values === value, // Set checked to true for the selected option only
  //     }))
  //   );

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //     _id: item?._id,
  //   }));
  //   handleChange(e);
  //   handleBlur(e);
  // };

  const handleInputNewMCQsChange = (e, item) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      adminAnswer: value,
      _id: item?._id

    }));
  };


  // const handleInputChangeNewAnswer = (e, item) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //     _id: item?._id,
  //   }));
  // };


  return (
    <>
      <div className="rowClickUnset">
        <div className="mainContent p-0">
          {/* Questionnaire */}
          <header className="mainContentHeader primaryHeader">
            <h3>Questionnaire</h3>
          </header>
          {supervisionData?.filter((item) => item.questionType === "Question")?.map((item) => (
            <div key={item?._id} className="questionWrapper">
              <h3 className="mt-4">Q: {item?.supervisionQuestion}</h3>
              <p disabled style={{ fontSize: "12px" }} className="mt-2">
                {moment(item?.createdAt).format("DD/MM/YYYY | h:mm A")}
              </p>

              {item?.staffAnswer !== "" && (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.staffMember?.profile_picture_url}`}
                          alt={item?.staffMember?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.staffMember?.name}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox mt-2">

                    <div className="mb-2">
                      <strong> A: </strong>
                      {item?.staffAnswer}{" "}
                    </div>
                    <p> <small className="mt-2">
                      {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                    </small>
                    </p>
                  </div>
                </div>
              )}

              {item?.supervisorAnswer !== "" && (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.supervisor?.profile_picture_url}`}
                          alt={item?.supervisor?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.supervisor?.name}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox mt-2">

                    <div className="mb-2">
                      <strong> A: </strong>
                      {item?.supervisorAnswer}{" "}
                    </div>
                    <p>  <small className="mt-2">
                      {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                    </small>
                    </p>
                  </div>
                </div>
              )}
              {item?.adminAnswer !== "" ? (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.admin?.profile_picture_url}`}
                          alt={item?.admin?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.admin?.name}`}</span>
                      </div>
                    </div>
                    {!isEditable && supervisionQA?.sprStatus !== "completed" && (

                      <span
                        className="editQuestion cursor-pointer"
                        onClick={() => handleSetAnswer(item)}
                      >
                        <img src={questionEdit} alt="Edit Question" />
                      </span>

                    )}
                  </div>
                  <div className="contentBox mt-2">
                    {isEditable === item?._id ? (
                      <>

                        <TextArea
                          name="adminAnswer"
                          className="editableTextArea"
                          value={editAnswerFormData?.adminAnswer}
                          onChange={(e) => handleInputChange(e, item)}
                          cols={3}
                          rows={3}
                        />
                        <div className="editableActionButton">
                          <span
                            className="editQuestion"
                            onClick={handleUpdateAnswer}
                          >
                            <img src={actionButtonTick} alt="Tick Answer" />
                          </span>
                          <span
                            className="addQuestion"
                            onClick={handleCloseEditableAnswerBox}
                          >
                            <img src={actionButtonDel} alt="Close" />
                          </span>
                        </div>

                      </>
                    ) : (
                      <>
                        <div>
                          <div className="mb-2">
                            <strong> A: </strong>
                            {item?.adminAnswer}{" "}
                          </div>
                          <p>
                            <small className="mt-2">
                              {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                            </small>
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (<p>No Answer</p>)}

              {item?.adminAnswer === "" && submitAdminAnswer !== item && supervisionQA?.sprStatus !== "completed" && (
                <div
                  className="text-muted mt-4 cursor-pointer"
                  onClick={(e) => handleShowAddNewAnswerBox(e, item)}
                >
                  <img
                    src={AddPlusIcon}
                    alt="PlusIcon"
                    className="addPlusIcon"
                  />{" "}
                  <span className="addAnswerText"> Add Your Answer</span>
                </div>
              )}

              {submitAdminAnswer === item?._id && isAddNewAnswer && (
                <div className="contentBox mt-2">
                  <TextArea
                    name="adminAnswer"
                    className="editableTextArea"
                    value={formData?.adminAnswer}
                    onChange={(e) => handleInputChangeNewAnswer(e, item)}
                    cols={3}
                    rows={3}
                  />
                  <div className="editableActionButton">
                    <span
                      className="editQuestion"
                      onClick={handleSubmitNewAnswer}
                    >
                      <img src={actionButtonTick} alt="Tick Answer" />
                    </span>
                    <span
                      className="addQuestion"
                      onClick={handleCloseEditableAnswerBox}
                    >
                      <img src={actionButtonDel} alt="Close" />
                    </span>
                  </div>
                </div>
              )}

              <hr />
            </div>
          ))}
        </div>

        <div className="mainContent p-0 mt-4">
          {/* Support Worker */}
          <header className="mainContentHeader primaryHeader">
            <h3>Support Worker</h3>
          </header>
          {supervisionData?.filter((item) => item.questionType === "supportWorker")?.map((item) => (
            <div key={item?._id} className="questionWrapper">
              <h3 className="mt-4">Q: {item?.supervisionQuestion}</h3>
              <p disabled style={{ fontSize: "12px" }} className="mt-2">
                {moment(item?.createdAt).format("DD/MM/YYYY | h:mm A")}
              </p>

              {item?.staffAnswer !== "" && (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.staffMember?.profile_picture_url}`}
                          alt={item?.staffMember?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.staffMember?.name}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox mt-2">

                    <div className="mb-2">
                      <strong> A: </strong>
                      {item?.staffAnswer}{" "}
                    </div>
                    <p>  <small className="mt-2">
                      {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                    </small>
                    </p>
                  </div>
                </div>
              )}

              {item?.supervisorAnswer !== "" && (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.supervisor?.profile_picture_url}`}
                          alt={item?.supervisor?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.supervisor?.name}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox mt-2">

                    <div className="mb-2">
                      <strong> A: </strong>
                      {item?.supervisorAnswer}{" "}
                    </div>
                    <p> <small className="mt-2">
                      {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                    </small>
                    </p>
                  </div>
                </div>
              )}

              {item?.adminAnswer !== "" ? (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.admin?.profile_picture_url}`}
                          alt={item?.admin?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.admin?.name}`}</span>
                      </div>
                    </div>
                    {!isEditable && supervisionQA?.sprStatus !== "completed" && (

                      <span
                        className="editQuestion cursor-pointer"
                        onClick={() => handleSetAnswer(item)}
                      >
                        <img src={questionEdit} alt="Edit Question" />
                      </span>

                    )}
                  </div>
                  <div className="contentBox mt-2">
                    {isEditable === item?._id ? (
                      <>

                        <div className="checkboxList spaceInCheckList">
                          {howParentContacted.map((row) => (
                            <Form.Check
                              key={row.id}
                              name="howParentContacted"
                              type="radio"
                              id={row.label}
                              label={row.label}
                              value={row.values}
                              checked={row.values === editAnswerFormData?.adminAnswer} // Bind checked to the checked state
                              onChange={(e) => handleInputMCQsChange(e, item)}
                            />
                          ))}
                        </div>
                        <TextArea
                          name="adminAnswer"
                          className="editableTextArea"
                          value={editAnswerFormData?.adminAnswer}
                          onChange={(e) => handleInputChange(e, item)}
                          cols={3}
                          rows={3}
                          hiddenDiv={true}
                        />
                        <div className="editableActionButton">
                          <span
                            className="editQuestion"
                            onClick={handleUpdateAnswer}
                          >
                            <img src={actionButtonTick} alt="Tick Answer" />
                          </span>
                          <span
                            className="addQuestion"
                            onClick={handleCloseEditableAnswerBox}
                          >
                            <img src={actionButtonDel} alt="Close" />
                          </span>
                        </div>

                      </>
                    ) : (
                      <>

                        <div className="mb-2">
                          <strong> A: </strong>
                          {item?.adminAnswer}{" "}
                        </div>
                        <p> <small className="mt-2">
                          {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                        </small>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ) : (<p>No Answer</p>)}


              {item?.adminAnswer === "" && submitAdminAnswer !== item && supervisionQA?.sprStatus !== "completed" && (
                <div
                  className="text-muted mt-4 cursor-pointer"
                  onClick={(e) => handleShowAddNewAnswerBox(e, item)}
                >
                  <img
                    src={AddPlusIcon}
                    alt="PlusIcon"
                    className="addPlusIcon"
                  />{" "}
                  <span className="addAnswerText"> Add Your Answer</span>
                </div>
              )}

              {submitAdminAnswer === item?._id && isAddNewAnswer && (

                <div className="contentBox mt-2">
                  <div className="checkboxList spaceInCheckList">
                    {howParentContacted.map((row) => (
                      <Form.Check
                        key={row.id}
                        name="howParentContacted"
                        type="radio"
                        id={row.label}
                        label={row.label}
                        value={row.values}
                        checked={row.values === formData.adminAnswer} // Bind checked to `formData`
                        onChange={(e) => handleInputNewMCQsChange(e, item)}
                      />
                    ))}
                  </div>

                  <TextArea
                    name="adminAnswer"
                    className="editableTextArea"
                    value={formData?.adminAnswer}
                    onChange={(e) => handleInputChangeNewAnswer(e, item)}
                    cols={3}
                    rows={3}
                    hiddenDiv={true}
                  />
                  <div className="editableActionButton">
                    <span
                      className="editQuestion"
                      onClick={handleSubmitNewAnswer}
                    >
                      <img src={actionButtonTick} alt="Tick Answer" />
                    </span>
                    <span
                      className="addQuestion"
                      onClick={handleCloseEditableAnswerBox}
                    >
                      <img src={actionButtonDel} alt="Close" />
                    </span>
                  </div>
                </div>
              )}

              <hr />
            </div>
          ))}
        </div>

        <div className="mainContent p-0 mt-4">
          {/* Senior Support Worker */}
          <header className="mainContentHeader primaryHeader">
            <h3>Senior Support Worker</h3>
          </header>
          {supervisionData?.filter((item) => item.questionType === "seniorSupportWorker")?.map((item) => (
            <div key={item?._id} className="questionWrapper">
              <h3 className="mt-4">Q: {item?.supervisionQuestion}</h3>
              <p disabled style={{ fontSize: "12px" }} className="mt-2">
                {moment(item?.createdAt).format("DD/MM/YYYY | h:mm A")}
              </p>

              {item?.staffAnswer !== "" && (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.staffMember?.profile_picture_url}`}
                          alt={item?.staffMember?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.staffMember?.name}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox mt-2">

                    <div className="mb-2">
                      <strong> A: </strong>
                      {item?.staffAnswer}{" "}
                    </div>
                    <p> <small className="mt-2">
                      {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                    </small>
                    </p>
                  </div>
                </div>
              )}

              {item?.supervisorAnswer !== "" && (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.supervisor?.profile_picture_url}`}
                          alt={item?.supervisor?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.supervisor?.name}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox mt-2">

                    <div className="mb-2">
                      <strong> A: </strong>
                      {item?.supervisorAnswer}{" "}
                    </div>
                    <p> <small className="mt-2">
                      {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                    </small>
                    </p>
                  </div>
                </div>
              )}

              {item?.adminAnswer !== "" ? (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.admin?.profile_picture_url}`}
                          alt={item?.admin?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.admin?.name}`}</span>
                      </div>
                    </div>
                    {!isEditable && supervisionQA?.sprStatus !== "completed" && (

                      <span
                        className="editQuestion cursor-pointer"
                        onClick={() => handleSetAnswer(item)}
                      >
                        <img src={questionEdit} alt="Edit Question" />
                      </span>

                    )}
                  </div>
                  <div className="contentBox mt-2">
                    {isEditable === item?._id ? (
                      <>

                        <div className="checkboxList spaceInCheckList">
                          {howParentContacted.map((row) => (
                            <Form.Check
                              key={row.id}
                              name="howParentContacted"
                              type="radio"
                              id={row.label}
                              label={row.label}
                              value={row.values}
                              checked={row.values === editAnswerFormData?.adminAnswer} // Bind checked to the checked state
                              onChange={(e) => handleInputMCQsChange(e, item)}
                            />
                          ))}
                        </div>
                        <TextArea
                          name="adminAnswer"
                          className="editableTextArea"
                          value={editAnswerFormData?.adminAnswer}
                          onChange={(e) => handleInputChange(e, item)}
                          cols={3}
                          rows={3}
                          hiddenDiv={true}
                        />
                        <div className="editableActionButton">
                          <span
                            className="editQuestion"
                            onClick={handleUpdateAnswer}
                          >
                            <img src={actionButtonTick} alt="Tick Answer" />
                          </span>
                          <span
                            className="addQuestion"
                            onClick={handleCloseEditableAnswerBox}
                          >
                            <img src={actionButtonDel} alt="Close" />
                          </span>
                        </div>

                      </>
                    ) : (
                      <>

                        <div className="mb-2">
                          <strong> A: </strong>
                          {item?.adminAnswer}{" "}
                        </div>
                        <p> <small className="mt-2">
                          {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                        </small>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ) : (<p>No Answer</p>)}


              {item?.adminAnswer === "" && submitAdminAnswer !== item && supervisionQA?.sprStatus !== "completed" && (
                <div
                  className="text-muted mt-4 cursor-pointer"
                  onClick={(e) => handleShowAddNewAnswerBox(e, item)}
                >
                  <img
                    src={AddPlusIcon}
                    alt="PlusIcon"
                    className="addPlusIcon"
                  />{" "}
                  <span className="addAnswerText"> Add Your Answer</span>
                </div>
              )}

              {submitAdminAnswer === item?._id && isAddNewAnswer && (

                <div className="contentBox mt-2">
                  <div className="checkboxList spaceInCheckList">
                    {howParentContacted.map((row) => (
                      <Form.Check
                        key={row.id}
                        name="howParentContacted"
                        type="radio"
                        id={row.label}
                        label={row.label}
                        value={row.values}
                        checked={row.values === formData.adminAnswer} // Bind checked to `formData`
                        onChange={(e) => handleInputNewMCQsChange(e, item)}
                      />
                    ))}
                  </div>

                  <TextArea
                    name="adminAnswer"
                    className="editableTextArea"
                    value={formData?.adminAnswer}
                    onChange={(e) => handleInputChangeNewAnswer(e, item)}
                    cols={3}
                    rows={3}
                    hiddenDiv={true}
                  />
                  <div className="editableActionButton">
                    <span
                      className="editQuestion"
                      onClick={handleSubmitNewAnswer}
                    >
                      <img src={actionButtonTick} alt="Tick Answer" />
                    </span>
                    <span
                      className="addQuestion"
                      onClick={handleCloseEditableAnswerBox}
                    >
                      <img src={actionButtonDel} alt="Close" />
                    </span>
                  </div>
                </div>
              )}

              <hr />
            </div>
          ))}
        </div>

        <div className="mainContent p-0 mt-4">
          {/* Service Team Manager */}
          <header className="mainContentHeader primaryHeader">
            <h3>Service Team Manager</h3>
          </header>
          {supervisionData?.filter((item) => item.questionType === "serviceTeamManager")?.map((item) => (
            <div key={item?._id} className="questionWrapper">
              <h3 className="mt-4">Q: {item?.supervisionQuestion}</h3>
              <p disabled style={{ fontSize: "12px" }} className="mt-2">
                {moment(item?.createdAt).format("DD/MM/YYYY | h:mm A")}
              </p>

              {item?.staffAnswer !== "" && (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.staffMember?.profile_picture_url}`}
                          alt={item?.staffMember?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.staffMember?.name}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox mt-2">

                    <div className="mb-2">
                      <strong> A: </strong>
                      {item?.staffAnswer}{" "}
                    </div>
                    <p>   <small className="mt-2">
                      {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                    </small>
                    </p>
                  </div>
                </div>
              )}

              {item?.supervisorAnswer !== "" && (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.supervisor?.profile_picture_url}`}
                          alt={item?.supervisor?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.supervisor?.name}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="contentBox mt-2">

                    <div className="mb-2">
                      <strong> A: </strong>
                      {item?.supervisorAnswer}{" "}
                    </div>
                    <p>  <small className="mt-2">
                      {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                    </small>
                    </p>
                  </div>
                </div>
              )}

              {item?.adminAnswer !== "" ? (
                <div className="answer">
                  <div className="profileBox">
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={`${BASE_URL}${item?.admin?.profile_picture_url}`}
                          alt={item?.admin?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">{`${item?.admin?.name}`}</span>
                      </div>
                    </div>
                    {!isEditable && supervisionQA?.sprStatus !== "completed" && (

                      <span
                        className="editQuestion cursor-pointer"
                        onClick={() => handleSetAnswer(item)}
                      >
                        <img src={questionEdit} alt="Edit Question" />
                      </span>

                    )}
                  </div>
                  <div className="contentBox mt-2">
                    {isEditable === item?._id ? (
                      <>

                        <div className="checkboxList spaceInCheckList">
                          {howParentContacted.map((row) => (
                            <Form.Check
                              key={row.id}
                              name="howParentContacted"
                              type="radio"
                              id={row.label}
                              label={row.label}
                              value={row.values}
                              checked={row.values === editAnswerFormData?.adminAnswer} // Bind checked to the checked state
                              onChange={(e) => handleInputMCQsChange(e, item)}
                            />
                          ))}
                        </div>
                        <TextArea
                          name="adminAnswer"
                          className="editableTextArea"
                          value={editAnswerFormData?.adminAnswer}
                          onChange={(e) => handleInputChange(e, item)}
                          cols={3}
                          rows={3}
                          hiddenDiv={true}
                        />
                        <div className="editableActionButton">
                          <span
                            className="editQuestion"
                            onClick={handleUpdateAnswer}
                          >
                            <img src={actionButtonTick} alt="Tick Answer" />
                          </span>
                          <span
                            className="addQuestion"
                            onClick={handleCloseEditableAnswerBox}
                          >
                            <img src={actionButtonDel} alt="Close" />
                          </span>
                        </div>

                      </>
                    ) : (
                      <>

                        <div className="mb-2">
                          <strong> A: </strong>
                          {item?.adminAnswer}{" "}
                        </div>
                        <p> <small className="mt-2">
                          {moment(item?.updatedAt).format("DD/MM/YYYY | h:mm A")}
                        </small>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ) : (<p>No Answer</p>)}


              {item?.adminAnswer === "" && submitAdminAnswer !== item && supervisionQA?.sprStatus !== "completed" && (
                <div
                  className="text-muted mt-4 cursor-pointer"
                  onClick={(e) => handleShowAddNewAnswerBox(e, item)}
                >
                  <img
                    src={AddPlusIcon}
                    alt="PlusIcon"
                    className="addPlusIcon"
                  />{" "}
                  <span className="addAnswerText"> Add Your Answer</span>
                </div>
              )}

              {submitAdminAnswer === item?._id && isAddNewAnswer && (

                <div className="contentBox mt-2">
                  <div className="checkboxList spaceInCheckList">
                    {howParentContacted.map((row) => (
                      <Form.Check
                        key={row.id}
                        name="howParentContacted"
                        type="radio"
                        id={row.label}
                        label={row.label}
                        value={row.values}
                        checked={row.values === formData.adminAnswer} // Bind checked to `formData`
                        onChange={(e) => handleInputNewMCQsChange(e, item)}
                      />
                    ))}
                  </div>

                  <TextArea
                    name="adminAnswer"
                    className="editableTextArea"
                    value={formData?.adminAnswer}
                    onChange={(e) => handleInputChangeNewAnswer(e, item)}
                    cols={3}
                    rows={3}
                    hiddenDiv={true}
                  />
                  <div className="editableActionButton">
                    <span
                      className="editQuestion"
                      onClick={handleSubmitNewAnswer}
                    >
                      <img src={actionButtonTick} alt="Tick Answer" />
                    </span>
                    <span
                      className="addQuestion"
                      onClick={handleCloseEditableAnswerBox}
                    >
                      <img src={actionButtonDel} alt="Close" />
                    </span>
                  </div>
                </div>
              )}

              <hr />
            </div>
          ))}
        </div>
      </div>

      <br />

      <div className="mainContent p-0">
        <header className="mainContentHeader primaryHeader">
          <h3>Comment</h3>
        </header>
        <div className="questionWrapper">
          <div className="answer">
            <div className="row mt-3">
              <div className="commentBox">
                {isAOBCommentBoxClicked ? (
                  <>
                    <TextArea
                      name="anyOtherBusinessComment "
                      value={templateFormData?.anyOtherBusinessComment}
                      cols={5}
                      rows={5}
                      onChange={(e) =>
                        handleInputChangeTemplateAOBCommentBox(e)
                      }
                      labelText="Any other business (AOB)"
                    />
                    <div className="editableActionButtons cursor-pointer">
                      <span
                        className="editQuestion"
                        onClick={handleSubmitTemplateAOBComment}
                      >
                        <img src={actionButtonTick} alt="Tick Answer" />
                      </span>
                      <span
                        className="addQuestion"
                        onClick={handleInputAOBCommentBoxClick}
                      >
                        <img src={actionButtonDel} alt="Close" />
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <TextArea
                      name=""
                      value={templateFormData?.anyOtherBusinessComment}
                      cols={5}
                      rows={5}
                      onChange={handleInputAOBCommentBoxClick}
                      labelText="Any other business (AOB)"
                    />
                  </>
                )}
              </div>
              <div className="commentBox">
                {isCommentBoxClicked ? (
                  <>
                    <TextArea
                      name="adminComment"
                      value={templateFormData?.adminComment}
                      cols={5}
                      rows={5}
                      onChange={(e) =>
                        handleInputChangeTemplateCommentBox(e)
                      }
                      labelText="Comment"
                    />
                    <div className="editableActionButtons cursor-pointer">
                      <span
                        className="editQuestion"
                        onClick={handleSubmitTemplateComment}
                      >
                        <img src={actionButtonTick} alt="Tick Answer" />
                      </span>
                      <span
                        className="addQuestion"
                        onClick={handleInputCommentBoxClick}
                      >
                        <img src={actionButtonDel} alt="Close" />
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <TextArea
                      name=""
                      value={templateFormData?.adminComment}
                      cols={5}
                      rows={5}
                      onChange={handleInputCommentBoxClick}
                      labelText="Comment"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="formActionButtons mt-0">
              <Link
                className="btn btn-sm btn-outline-primary"
                onClick={handleCancelButton}
              >
                Back
              </Link>

              {isFormSubmit ? (
                <Button className="btn-sm btn-primary" disabled>
                  Loading Please Wait....
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="btn-sm btn-primary"
                  onClick={handleShowNextSupervisionForm}
                  disabled={sprStatusUpdate || supervisionQA?.sprStatus === "completed" ? true : false}
                >
                  {
                    sprStatusUpdate || supervisionQA?.sprStatus === "completed" ? "Supervision Completed" : "Complete and Book Next"
                  }

                </Button>
              )}

            </div>
          </div>
        </div>
      </div>

      <section>

        <Modal
          show={showAddSupervisionModal}
          onHide={handleCloseModal}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Assign New Supervision</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row mt-3">
                <div className="col-sm-12">
                  <Input
                    type="date"
                    id=""
                    name="sprDueDate"
                    value={values?.sprDueDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelText="Supervision Due Date"
                    customError={touched?.sprDueDate && errors?.sprDueDate}
                    errorMessage={errors?.sprDueDate}
                    errorClass={
                      touched?.sprDueDate && errors?.sprDueDate
                        ? "validationError"
                        : ""
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelect
                      onSelect={(supervisorId) => {
                        setFieldValue("supervisor", supervisorId);
                      }}
                      filterBy="supervisor"
                      label="Supervisor"
                      customError={touched?.supervisor && errors?.supervisor}
                      errorMessage={errors?.supervisor}
                      errorClass={
                        touched?.supervisor && errors?.supervisor
                          ? "validationError"
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectSupervisionTemplate
                      onSelect={(templateTitleId) => {
                        setFieldValue("templateTitleId", templateTitleId);
                      }}
                      label="Supervision Template"
                      customError={
                        touched?.templateTitleId && errors?.templateTitleId
                      }
                      errorMessage={errors?.templateTitleId}
                      errorClass={
                        touched?.templateTitleId && errors?.templateTitleId
                          ? "validationError"
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-sm"
                variant="outline-primary"
                onClick={handleCloseModal}
              >
                Back
              </Button>
              {isFormSubmit ? (
                <Button className="btn-sm btn-primary" disabled>
                  Loading Please Wait....
                </Button>
              ) : (
                <Button type="submit" className="btn-sm btn-primary">
                  Submit
                </Button>
              )}
            </Modal.Footer>
          </form>
        </Modal>

      </section>

      {isToastSupervisionQA && <ToastContainer />}
    </>
  );
};

export default SupervisionQA;
