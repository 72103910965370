import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { assignRoleToUser } from "../../schemas";
import { useFormik } from "formik";
import {
  isSuccess,
  toastErrorMessages,
  toastSuccessMessages,
  catchAsync,
  postRecords,
  getRecords,
} from "../../helper/helper";

import {
  POST_UPDATE_STAFF_MEMBER_DETAILS_URL,
  GET_STAFF_PERMISSIONS,
  ADMIN_AUTH_TOKEN,
  BASE_URL,
} from "../../env";

const AssignRole = ({ userProfileDetail, showAssignRole }) => {
  // console.log("🚀 ~ AssignRole ~ userProfileDetail:", userProfileDetail);

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAssignUserRole, setIsToastAssignUserRole] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [permissionData, setPermissionData] = useState();

  const handleShowHideModal = () => {
    showAssignRole((prevShow) => !prevShow);
  };

  const [permissionItems, setPermissionItems] = useState([]);
  // console.log("🚀 ~ AssignRole ~ permissionItems:", permissionItems)

  const [suFormData, setSuFormData] = useState({
    userId: userProfileDetail?._id,
    staffRoles: userProfileDetail?.user?.staffRoles || []
  });
  // console.log("🚀 ~ AssignRole ~ suFormData:", suFormData)

  useEffect(() => {
    const initialCheckedItems = {};
    suFormData.staffRoles.forEach(role => {
      initialCheckedItems[role] = true;
    });
    // setCheckedItems(initialCheckedItems);
  }, [suFormData.staffRoles]);

  // const handleCheckboxChange = (e) => {
  //   const { name, checked } = e.target;
  //   setCheckedItems(prevState => ({
  //     ...prevState,
  //     [name]: checked
  //   }));
  // };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: assignRoleToUser,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddAssignUserRoleFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAssignUserRole(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (method === "postAddAssignUserRoleFormData" && data?.status === isSuccess) {
      setIsToastAssignUserRole(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const postAddAssignUserRoleFormData = catchAsync(async (req, res) => {
    const updateValues = {
      ...values,
      staffRoles: checkedPermissions
    };
    // console.log("🚀 ~ postAddAssignUserRoleFormData ~ updateValues:", updateValues);
    const response = await postRecords(POST_UPDATE_STAFF_MEMBER_DETAILS_URL, updateValues);
    // console.log("🚀 ~ carenotes ~ response:", response);
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddAssignUserRoleFormData", response);
  });


  const permissionListQuery = {
    sortProperty: "createdAt",
    sortOrder: -1,
    offset: 0,
    limit: 10,
    query: {
      critarion: {
        active: true
      }
    }
  }
  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_STAFF_PERMISSIONS, permissionListQuery);
    // console.log("🚀 ~ fetchAllRecords ~ response:", response?.data?.permissions)
    const medicineListsData2 = response?.data?.permissions?.filter((item) => item.permissionName !== "Super Admin");
    const formatedData = medicineListsData2?.map((item) => ({
      value: item?._id,
      label: item?.permissionName
    }))

    if (permissionItems?.length === 0) {
      setPermissionItems((prevItems) => [...prevItems, ...formatedData]);
    }

  });
  useEffect(() => {
    fetchAllRecords();
  }, []);


  const [checkedPermissions, setCheckedPermissions] = useState([]);
  console.log("🚀 ~ AssignRole ~ checkedPermissions:", checkedPermissions)

  useEffect(() => {
    if (userProfileDetail?.user?.staffRoles) {
      const checkedIds = userProfileDetail.user.staffRoles.map((role) => role._id);
      setCheckedPermissions(checkedIds);
    }
  }, [userProfileDetail]);

  const handleCheckboxChange = (id) => {
    setCheckedPermissions((prevState) =>
      prevState.includes(id)
        ? prevState.filter((item) => item !== id) // Remove if already checked
        : [...prevState, id] // Add if not already checked
    );
  };
  

  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <p>Please select user role for<br /><strong>{userProfileDetail?.staffMemberName} {" "} {userProfileDetail?.lastName}</strong></p>
        <Form onSubmit={handleSubmit}>
          {permissionItems
            .slice() // Create a shallow copy to avoid mutating the original state
            .sort((a, b) => a.label.localeCompare(b.label)) // Sort items by label in ascending order
            .map((item, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                id={`checkbox-${index}`}
                label={item.label}
                name={item.value} // Ensure `item.value` is the permission ID
                checked={checkedPermissions.includes(item.value)}
                onChange={() => handleCheckboxChange(item.value)}

                // onChange={handleCheckboxChange}
              />
            ))}
          <div className="formActionButtons mt-3">
            <span
              onClick={handleShowHideModal}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button
                onClick={handleShowHideModal}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Submit
              </Button>
            )}
          </div>
        </Form>
      </div>
      {isToastAssignUserRole && <ToastContainer />}
    </>
  );
};

export default AssignRole;
