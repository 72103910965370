import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import StaffRecordsIndividual from "../StaffRecordsIndividual";
import ArchievedStaffMembers from "./ArchievedStaffMembers";

function ArchieveStaffRecords() {
  // const searchParams = new URLSearchParams(location.search);
  // const userID = searchParams.get("userId");
  const location = useLocation();

  const userData = location?.state && location?.state?.userId;

  return (
    <Layout pageTitleText="Archived Staff Records">
      {userData ? (
        <StaffRecordsIndividual getUserRecord={userData} />
      ) : (
        <ArchievedStaffMembers />
      )}
    </Layout>
  );
}

export default ArchieveStaffRecords;
