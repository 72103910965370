import React, { useEffect, useState } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DeletePopup from "../../components/modals/DeletePopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../components/general/ProgressComponent";
import StatusUpdatedExempt from "../../components/general/StatusUpdatedExempt";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import {
  filterArrayBySearchTerm,
  filterColumns,
  formateDate,
  catchAsync,
  isSuccess,
  updateRecords,
  toastSuccessMessages,
  toastErrorMessages,
  displayEnumProperty,
} from "../../helper/helper";
import {
  PUT_HOLIDAY_STAFF_MEMBER_URL,
  ADMIN_ID,
  GET_STAFFS_HOLIDAYS_DETAILS_URL,
} from "../../env";

function SicknessScore({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUserData] = useState();
  const [filteredRows, setFilteredRows] = useState();
  const [search, setSearch] = useState("");
  const [isToastSicknessScore, setIsToastSicknessScore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isExemptClicked, setIsExemptClicked] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [deleteRecordRow, setDeleteRecordRow] = useState(null);
  const [deleteRecordStatus, setDeleteRecordStatus] = useState(null);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: {
        active: true,
        hldRqStatus: { $in: ["rejected", "pending"] },
        hldRequestType: { $in: ["absent", "sickLeave"] },
        staffRequester: initialUserData?._id,
      },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    try {
      const response = await updateRecords(
        GET_STAFFS_HOLIDAYS_DETAILS_URL,
        apiQuery
      );
      console.log(response);
      processFetchRecords("get", response);
    } catch (error) {
      console.error("Error updating staff holidays:", error);
    }
  });

  const updateStaffHoliday = catchAsync(async (req, res) => {
    try {
      const response = await updateRecords(PUT_HOLIDAY_STAFF_MEMBER_URL, req);
      processFetchRecords("update", response);
    } catch (error) {
      console.error("Error updating staff holidays:", error);
    }
  });

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastSicknessScore(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
      setIsExemptClicked(false);
    } else if (method === "get" && data?.status === isSuccess) {
      const result = data?.data?.staffHolidayRequests?.map((item) => item);
      setUserData(result);
      setFilteredRows(result);
      setIsLoading(false);
    } else if (method === "update" && data?.status === isSuccess) {
      setIsToastSicknessScore(true);
      fetchAllRecords();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
      setIsExemptClicked(false);
    }
  };

  console.log(userData);

  const handleUpdateHolidayStatus = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        hldRqStartDate: deleteRecordRow?.hldRqStartDate,
        hldRqEndDate: deleteRecordRow?.hldRqEndDate,
        hldRqTotalDays: deleteRecordRow?.hldRqTotalDays,
        hldRqTotalHours: deleteRecordRow?.hldRqTotalHours,
        staffHolidayRequestid: deleteRecordRow?._id,
        hldRqStatus: deleteRecordStatus,
        approvedBy: ADMIN_ID,
        staffRequester: initialUserData?._id,
      };
      console.log(payload);
      updateStaffHoliday(payload);
    } catch (error) {
      console.error("Error in handleUpdateHolidayStatus:", error);
      setIsExemptClicked(false);
    }
  };

  const handleOpenDeleteModel = (e, status, row) => {
    e.preventDefault();
    setDeleteRecordStatus(status);
    setDeleteRecordRow(row);
    setIsExemptClicked(true);
    setIsShowModal(true);
  };

  const handleCloseDeleModel = () => {
    setDeleteRecordStatus(null);
    setDeleteRecordRow(null);
    setIsExemptClicked(false);
    setIsShowModal(false);
  };

  const handleSearchChange = (e) => {
    const searchVal = e?.target?.value;
    setSearch(searchVal);
    if (searchVal !== "") {
      const lowercaseSearch = searchVal?.toLowerCase();
      const filteredRecords = filterArrayBySearchTerm(
        userData,
        lowercaseSearch,
        (record) => record?.hldRequestType?.toLowerCase()
      );
      console.log(filteredRecords);
      setFilteredRows(filteredRecords);
    } else {
      setFilteredRows(userData);
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const columns = [
    {
      name: "Leave Period",
      selector: (row) => {
        return (
          formateDate(row?.hldRqStartDate, "m/d/y") +
          " - " +
          formateDate(row?.hldRqStartDate, "m/d/y")
        );
      },
      width: "30%",
    },
    {
      name: "Leave Type",
      selector: (row) => {
        return displayEnumProperty("hldRequestType", row?.hldRequestType);
      },
      width: "15%",
      center: true,
    },
    {
      name: "Days Taken",
      selector: (row) => {
        return row?.hldRqTotalDays;
      },
      width: "15%",
      center: true,
    },
    {
      name: "Reason",
      selector: (row) => {
        return row?.hldRqReason !== "" ? row?.hldRqReason : "Holiday";
      },
      width: "20%",
    },
    {
      name: "Action",
      selector: (row) => {
        return isExemptClicked && row?._id === deleteRecordRow?._id ? (
          <Button className="btn btn-primary btn-sm" disabled>
            Exempting...
          </Button>
        ) : (
          <StatusUpdatedExempt
            initialStatus={row?.hldRqStatus}
            onChangeFun={(e, statusValue) =>
              handleOpenDeleteModel(e, statusValue, row)
            }
          />
        );
      },
      width: "20%",
      center: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);

  const handleSelectedRowsChange = (state) => {
    console.log(state?.selectedCount);
    // setSelectedRows(state.selectedRows);

    // Call your function here with the selected rows if needed
    // Example: yourFunction(state.selectedRows);
  };

  return (
    <>
      <div className="dataTableWrapper">
        {isLoading ? (
          <ProgressComponent />
        ) : (
          <DataTable
            className="maxWidthTable"
            columns={filteredColumns}
            data={filteredRows}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="470px"
            highlightOnHover
            // selectableRows
            // onSelectedRowsChange={handleSelectedRowsChange}
            responsive={true}
            subHeader={true}
            subHeaderComponent={
              <>
                <div className="searchContainer">
                  <div className="searchTextBox">
                    <input
                      type="text"
                      placeholder="Type your search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="parentTableHeader">
                  <h3>Bradford Score</h3>
                  <div className="thresholdScore">
                    <p>
                      <span>Customize threshold score rang</span>
                      <strong>
                        {" "}
                        Level 01 &nbsp;&gt; 4 &nbsp; Level 02 &nbsp;&gt; 6
                        &nbsp; Level 03 &nbsp;&gt; 8
                      </strong>
                    </p>
                  </div>
                </div>
              </>
            }
            subHeaderAlign="left"
            subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
          />
        )}
      </div>
      {isExemptClicked && (
       
          <DeletePopup
            show={isShowModal}
            onHide={handleCloseDeleModel}
            title="Confirmation"
            content="Are you sure you want to Exempt this?"
            onConfirm={handleUpdateHolidayStatus}
            onCancel={handleCloseDeleModel}
          />
        
      )}
      {isToastSicknessScore && <ToastContainer />}
    </>
  );
}

export default SicknessScore;
