import React, { useState, useEffect } from "react";
import Authentication from "../components/layouts/Authentication";
import ftcLogo from "../assets/images/ftc_logo.svg";
import { useNavigate } from "react-router-dom";
import { POST_VERIFY_OTP_URL } from "../env";
import { Api, DeliveryDiningRounded, FloodTwoTone } from "@mui/icons-material";
import LoginForm from "../components/auth/LoginForm";
import ForgotPasswordForm from "../components/auth/LoginForm";
import VerifyOTPForm from "../components/auth/VerifyOtpForm";
import { ADMIN_LOGIN_URL } from "../env";
import { isSuccess } from "../helper/helper";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [errorStar, setErrorStar] = useState();
  const [loading, setLoading] = useState(false);
  const [isSuccessLogin, setIsSuccessLogin] = useState(false);
  const [isEmailExits, setIsEmailExits] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [isAlreadyHaveAccount, setIsAlreadyHaveAccount] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [otpCode, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(0);
  const [hideResendButton, setHideResendButton] = useState(false);

  const startTimer = () => {
    if (isEmailExits && timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      // Timer has ended, hide the "Resend" button
      setHideResendButton(true);
    }
  };

  useEffect(startTimer, [isEmailExits, timer]);

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    // if (username === "ftc@admin.com" && password === "ftcAdmin@66") {
    //   navigate("/staff-overview");
    // }
    // if (username !== "ftc@admin.com" || password !== "ftcAdmin@66") {
    //   setLoading(false);
    //   setIsSuccessLogin(true);
    //   setErrorMessage("Incorrect Email or Password");
    //   setErrorStar("*");
    // }

    try {
      setLoading(true); // Show loading overlay
      const response = await fetch(ADMIN_LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: username,
          password: password,
          active: true,
        }),
      });
      const data = await response.json();
      if (data?.status === isSuccess) {
        setLoading(false);
        setIsAlreadyHaveAccount(false);
        setIsForgetPassword(false);
        setIsResend(false);
        setIsEmailExits(true);
        setTimer(20);
        setHideResendButton(false);
      } else {
        setLoading(false); // Hide loading overlay on error
        setIsSuccessLogin(true);
        setErrorMessage("Incorrect Email or Password");
        setErrorStar("*");
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoading(false); // Hide loading overlay on error
      setIsSuccessLogin(true);
      setErrorMessage("Incorrect Email or Password");
      setErrorStar("*");
    }
  };

  const verifyOtp = async (event) => {
    // debugger
    event.preventDefault();
    if (otpCode.join("").length === 6) {
      try {
        setLoading(true);
        const response = await fetch(POST_VERIFY_OTP_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: username,
            verification_code: otpCode.join(""),
          }),

        });

        const data = await response.json();
        console.log("🚀 ~ verifyOtp ~ response:", data)
        if (data?.status === isSuccess) {
          // On successful login
          localStorage.setItem('staffRoles', JSON.stringify(data?.data?.staffRoles));
          localStorage.setItem('userId', data?.data?._id);
          localStorage.setItem('token', data?.token); // Store JWT
          localStorage.setItem('userLoggedInDetails', JSON.stringify(data?.data));
          setLoading(false);
          setIsAlreadyHaveAccount(false);
          setIsForgetPassword(false);
          setIsEmailExits(true);
          window.location.href = "/staff-overview";
          // navigate("/staff-overview");
        } else {
          setLoading(false);
          setIsSuccessLogin(false);
          setErrorStar("*");
        }
      } catch (error) {
        setLoading(false);
        console.error("OTP verification error:", error);
      }
    } else {
      alert("Please enter a valid 6-digit OTP.");
    }
  };

  const handleForgetPassword = (event) => {
    event.preventDefault();
    setIsForgetPassword(true);
    setIsEmailExits(false);
  };

  const handleAlreadyHaveAccount = (event) => {
    event.preventDefault();
    setIsForgetPassword(false);
    setIsAlreadyHaveAccount(true);
    setIsEmailExits(false);
  };

  const handleResendOTP = (event) => {
    event.preventDefault();
    setTimer(20);
    setIsResend(true);
    setIsEmailExits(true);
  };

  return (

    <Authentication>
      {isEmailExits ? (
        <VerifyOTPForm
          ftcLogo={ftcLogo}
          otpCode={otpCode}
          errorStar={errorStar}
          handleOtpChange={handleOtpChange}
          handleResendOTP={handleResendOTP}
          timer={timer}
          isEmailExits={isEmailExits}
          isResend={isResend}
          handleVerifyOtp={verifyOtp}
          loading={loading}
        />
      ) : isForgetPassword ? (
        <ForgotPasswordForm
          ftcLogo={ftcLogo}
          handleAlreadyHaveAccount={handleAlreadyHaveAccount}
        />
      ) : isAlreadyHaveAccount ? (
        <LoginForm
          ftcLogo={ftcLogo}
          errorStar={errorStar}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          errorMessage={errorMessage}
          loading={loading}
          handleLogin={handleLogin}
        />
      ) : (
        <LoginForm
          ftcLogo={ftcLogo}
          errorStar={errorStar}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          errorMessage={errorMessage}
          loading={loading}
          handleLogin={handleLogin}
        />
      )}
    </Authentication>
  );
}

export default Login;
