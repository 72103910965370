import React from "react";
import { getUserPermissions, hasPermission } from "../utils/advance_permissions";
import { Navigate } from "react-router-dom";

const PermissionRoutes = ({ title, action, children }) => {
    const userPermissions = getUserPermissions();

    // Check for the action's permission (e.g., create, update, delete, view)
    const isAllowed = userPermissions.some((role) =>
        hasPermission(role.permissions, title, action)
    );

    return isAllowed ? children : <Navigate to="/permission-error" replace />;
};

export default PermissionRoutes;
