import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addHealthPhysicalIntervention } from "../../../../../../schemas";
import { useFormik } from "formik";
import ReactDOMServer from 'react-dom/server';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_CARE_PLAN_URL, BASE_URL_PROD } from "../../../../../../env";
import ReactHtmlParser from 'html-react-parser';
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";
import { Form, Table } from "react-bootstrap";
import actionButtonDel from "../../../../../../assets/images/icons/actionButtonDel.svg";
import actionButtonTick from "../../../../../../assets/images/icons/actionButtonTick.svg";
import HealthCategories from "../../../../../health-issue/HealthCategories";
function EditPhysicalIntervention() {
  const location = useLocation();
  const carePlanDetail = location?.state ? location?.state?.editCarePlanDetail[0] : null;
  // console.log("🚀 ~ careplandetail ~  carePlanDetail:", carePlanDetail)
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(carePlanDetail?.addedby);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [parentSubCatValidationStatus, setParentSubCatValidationStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
  };


  const durationOfIncidentsRef = useRef(null);
  const staffDebriefFormNumberRef = useRef(null);
  const nameOfWitnessesRef = useRef(null);
  const placeOfIncidentRef = useRef(null);
  const priorToInterventionRef = useRef(null);
  const pbsFollowedRef = useRef(null);
  const reasonForPhysicalInterventionRef = useRef(null);
  const staffInvolvedInPIRef = useRef(null);
  const conditionOfSURef = useRef(null);
  const howSuCalmedRef = useRef(null);
  const pleaseExplainRef = useRef(null);
  const isParentContactedRef = useRef(null);
  const howParentContactedRef = useRef(null);
  const nameOfParentContactedRef = useRef(null);
  const parentContactedTimeRef = useRef(null);
  const parentCarersCommentsRef = useRef(null);
  const howFormWasSharedRef = useRef(null);
  const useOfForceNecessaryPoints1Ref = useRef(null);
  const useOfForceNecessaryPoints2Ref = useRef(null);
  const useOfForceNecessaryPoints3Ref = useRef(null);
  const useOfForceNecessaryPoints4Ref = useRef(null);
  const useOfForceNecessaryPoints5Ref = useRef(null);



  useEffect(() => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = carePlanDetail?.noteDetails || '';

    durationOfIncidentsRef.current.innerText = tempDiv.querySelector('#durationOfIncidentsData')?.innerText || '';
    staffDebriefFormNumberRef.current.innerText = tempDiv.querySelector('#staffDebriefFormNumberData')?.innerText || '';
    nameOfWitnessesRef.current.innerText = tempDiv.querySelector('#nameOfWitnessesData')?.innerText || '';
    placeOfIncidentRef.current.innerText = tempDiv.querySelector('#placeOfIncidentData')?.innerText || '';
    priorToInterventionRef.current.innerText = tempDiv.querySelector('#priorToInterventionData')?.innerText || '';
    pbsFollowedRef.current.innerText = tempDiv.querySelector('#pbsFollowedData')?.innerText || '';
    reasonForPhysicalInterventionRef.current.innerText = tempDiv.querySelector('#reasonForPhysicalInterventionData')?.innerText || '';
    staffInvolvedInPIRef.current.innerText = tempDiv.querySelector('#staffInvolvedInPIData')?.innerText || '';
    conditionOfSURef.current.innerText = tempDiv.querySelector('#conditionOfSUData')?.innerText || '';
    howSuCalmedRef.current.innerText = tempDiv.querySelector('#howSuCalmedData')?.innerText || '';
    pleaseExplainRef.current.innerText = tempDiv.querySelector('#pleaseExplainData')?.innerText || '';
    isParentContactedRef.current.innerText = tempDiv.querySelector('#isParentContactedData')?.innerText || '';
    howParentContactedRef.current.innerText = tempDiv.querySelector('#howParentContactedData')?.innerText || '';
    nameOfParentContactedRef.current.innerText = tempDiv.querySelector('#nameOfParentContactedData')?.innerText || '';
    parentContactedTimeRef.current.innerText = tempDiv.querySelector('#parentContactedTimeData')?.innerText || '';
    parentCarersCommentsRef.current.innerText = tempDiv.querySelector('#parentCarersCommentsData')?.innerText || '';
    howFormWasSharedRef.current.innerText = tempDiv.querySelector('#howFormWasSharedData')?.innerText || '';
    useOfForceNecessaryPoints1Ref.current.innerText = tempDiv.querySelector('#useOfForceNecessaryPoints1Data')?.innerText || '';
    useOfForceNecessaryPoints2Ref.current.innerText = tempDiv.querySelector('#useOfForceNecessaryPoints2Data')?.innerText || '';
    useOfForceNecessaryPoints3Ref.current.innerText = tempDiv.querySelector('#useOfForceNecessaryPoints3Data')?.innerText || '';
    useOfForceNecessaryPoints4Ref.current.innerText = tempDiv.querySelector('#useOfForceNecessaryPoints4Data')?.innerText || '';
    useOfForceNecessaryPoints5Ref.current.innerText = tempDiv.querySelector('#useOfForceNecessaryPoints5Data')?.innerText || '';


  }, [carePlanDetail?.noteDetails]);


  const [suFormData, setSuFormData] = useState({

    addedby: carePlanDetail?.addedby?._id,
    eventDateTime: carePlanDetail?.eventDateTime,
    durationOfIncidents: "",
    staffDebriefFormNumber: "",
    nameOfWitnesses: "",
    placeOfIncident: "",
    priorToIntervention: "",
    pbsFollowed: "",
    reasonForPhysicalIntervention: "",
    staffInvolvedInPI: "",
    conditionOfSU: "",
    howSuCalmed: "",
    pleaseExplain: "",
    isParentContacted: "",
    howParentContacted: "",
    nameOfParentContacted: "",
    parentContactedTime: moment().format('HH:mm'),
    parentCarersComments: "",
    howFormWasShared: "",
    useOfForceNecessaryPoints1: false,
    useOfForceNecessaryPoints2: false,
    useOfForceNecessaryPoints3: false,
    useOfForceNecessaryPoints4: false,
    useOfForceNecessaryPoints5: false,
    category: selectedCategoryId,
    subcategory: selectedSubCategoryId,
  });
  useEffect(() => {
    setValues(prevState => ({
      ...prevState,
      durationOfIncidents: durationOfIncidentsRef.current?.innerText || '',
      staffDebriefFormNumber: staffDebriefFormNumberRef.current?.innerText || '',
      nameOfWitnesses: nameOfWitnessesRef.current?.innerText || '',
      placeOfIncident: placeOfIncidentRef.current?.innerText || '',
      priorToIntervention: priorToInterventionRef.current?.innerText || '',
      pbsFollowed: pbsFollowedRef.current?.innerText || '',
      reasonForPhysicalIntervention: reasonForPhysicalInterventionRef.current?.innerText || '',
      staffInvolvedInPI: staffInvolvedInPIRef.current?.innerText || '',
      conditionOfSU: conditionOfSURef.current?.innerText || '',
      howSuCalmed: howSuCalmedRef.current?.innerText || '',
      pleaseExplain: pleaseExplainRef.current?.innerText || '',
      isParentContacted: isParentContactedRef.current?.innerText || '',
      howParentContacted: howParentContactedRef.current?.innerText || '',
      nameOfParentContacted: nameOfParentContactedRef.current?.innerText || '',
      parentContactedTime: parentContactedTimeRef.current?.innerText || '',
      parentCarersComments: parentCarersCommentsRef.current?.innerText || '',
      howFormWasShared: howFormWasSharedRef.current?.innerText || '',
      useOfForceNecessaryPoints1: useOfForceNecessaryPoints1Ref.current?.innerText === "Yes" ? true : false,
      useOfForceNecessaryPoints2: useOfForceNecessaryPoints2Ref.current?.innerText === "Yes" ? true : false,
      useOfForceNecessaryPoints3: useOfForceNecessaryPoints3Ref.current?.innerText === "Yes" ? true : false,
      useOfForceNecessaryPoints4: useOfForceNecessaryPoints4Ref.current?.innerText === "Yes" ? true : false,
      useOfForceNecessaryPoints5: useOfForceNecessaryPoints5Ref.current?.innerText === "Yes" ? true : false,
    }));
  }, [carePlanDetail?.noteDetails]);


  useEffect(() => {
    // debugger
    //testing
    if (carePlanDetail && carePlanDetail.healthIssueId) {
      const { category } = carePlanDetail.healthIssueId;
      if (category.parentCategory && category.parentCategory._id) {
        setFieldValue("category", carePlanDetail?.healthIssueId?.category?.parentCategory?.id);
        setFieldValue("subcategory", carePlanDetail?.healthIssueId?.category?._id);
        setSelectedCategoryId(carePlanDetail?.healthIssueId?.category?.parentCategory?.id)
        setSelectedSubCategoryId(carePlanDetail?.healthIssueId?.category?._id)
        setParentSubCatValidationStatus(true)

      } else {
        setFieldValue("category", carePlanDetail?.healthIssueId?.category?._id);
        setFieldValue("subcategory", null);
        setSelectedCategoryId(carePlanDetail?.healthIssueId?.category?._id)
        setParentSubCatValidationStatus(false)
        setSelectedSubCategoryId("")
      }

    }
  }, [carePlanDetail]);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addHealthPhysicalIntervention,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);

        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      carePlanid: carePlanDetail?._id,
      userId: carePlanDetail?.userId,
      addedby: selectedUserId,
      noteType: carePlanDetail?.noteType,
      isHTML: true,
      title: values?.priorToIntervention,
      flag: carePlanDetail?.flag,
      noteDetails: noteDetailsString,
      eventDateTime: moment(values.eventDateTime).valueOf(),
      healthIssueId: carePlanDetail?.healthIssueId?.id,
      category: values?.category,
      healthNote: values?.placeOfIncident,
      complaint: values?.priorToIntervention,
    }

    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(UPDTE_CARE_PLAN_URL, updateValues);
    // console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });


  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };

  useEffect(() => {
    console.log("🚀 ~ AddPhysicalIntervention ~ values:", values)
  }, [values])
  const [htmlData, setHtmlData] = useState("")

  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-6">
          <p><strong>Duration of incident (Mins)</strong>
            <span id="durationOfIncidentsData">{values?.durationOfIncidents}</span></p>

        </div>

        <div className="col-md-6">
          <p><strong>Staff Debrief Form Number</strong>
            <span id="staffDebriefFormNumberData">{values?.staffDebriefFormNumber}</span></p>

        </div>

        <div className="col-md-6">
          <p><strong>Name of witnesses/adults present</strong>
            <span id="nameOfWitnessesData">{values?.nameOfWitnesses}</span></p>

        </div>

        <div className="col-md-6">
          <p><strong>Place incident occured</strong>
            <span id="placeOfIncidentData">{values?.placeOfIncident}</span></p>

        </div>

        <div className="col-md-6">
          <p><strong>What was used prior to intervention to defuse/deescalae the situation?</strong>
            <span id="priorToInterventionData">{values?.priorToIntervention}</span></p>

        </div>

        <div className="col-md-6">
          <p><strong>Was the PBS followed and was it sufficient enough to manage this incident?</strong>
            <span id="pbsFollowedData">{values?.pbsFollowed}</span></p>

        </div>


        <div className="col-md-6">
          <p><strong>Reason for physical intervention</strong>
            <span id="reasonForPhysicalInterventionData">{values?.reasonForPhysicalIntervention}</span></p>

        </div>


        <div className="col-md-6">
          <p><strong>Staff involved in the physical intervention</strong>
            <span id="staffInvolvedInPIData">{values?.staffInvolvedInPI}</span></p>

        </div>



        <div className="col-md-6">
          <p><strong>Condition of service user following the incident, including breathing monitoring</strong>
            <span id="conditionOfSUData">{values?.conditionOfSU}</span></p>

        </div>


        <div className="col-md-6">
          <p><strong>How was the service user calmed?</strong>
            <span id="howSuCalmedData">{values?.howSuCalmed}</span></p>

        </div>

        <div className="col-md-12">
          <p>
            <strong>Why was the use of force necessary?</strong></p>
          <div className="keyChecksWrapper">
            <div className="keyPoints">
              <div className="table-responsive">
                <Table striped bordered hover className='dynamicRows'>
                  <tbody>
                    {useOfForceNecessary.map((row, index) => (
                      <tr key={row.id}>
                        <td>{row?.requirements}</td>
                        <td>
                          {
                            row?.fullfil ? (
                              <>
                                <span id={`useOfForceNecessaryPoints${row.id}Data`} className="displayNone">
                                  Yes
                                </span>
                                <img
                                  src={BASE_URL_PROD + actionButtonTick}
                                  alt="Tick Answer"
                                />
                              </>
                            ) : (
                              <>
                                <span id={`useOfForceNecessaryPoints${row.id}Data`} className="displayNone">
                                  No
                                </span>
                                <img
                                  src={BASE_URL_PROD + actionButtonDel}
                                  alt="Close"
                                />
                              </>
                            )
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <p><strong>If ticked "Other" please explain</strong>
            <span id="pleaseExplainData">{values?.pleaseExplain}</span></p>

        </div>

        <div className="col-md-12 mb-3">
          <p><strong>Parent Contacted</strong>
            <span id="isParentContactedData">{values?.isParentContacted}</span>
          </p>
        </div>

        {values?.isParentContacted === "Yes" && (
          <>
            <div className="col-md-6">
              <p><strong>Name of Parent Contacted</strong>
                <span id="nameOfParentContactedData">{values?.nameOfParentContacted}</span></p>

            </div>

            <div className="col-md-6">
              <p><strong>Contact time</strong>
                <span id="parentContactedTimeData">{values?.parentContactedTime}</span></p>

            </div>

            <div className="col-md-12">
              <p><strong>How parent was contacted</strong>
                <span id="howParentContactedData">{values?.howParentContacted}</span></p>
            </div>
          </>
        )}



        <div className="col-md-12 mt-5">
          <p><strong>Parent/carer’s comments</strong>
            <span id="parentCarersCommentsData">{values?.parentCarersComments}</span></p>

        </div>

        <div className="col-md-12 mb-3">
          <p><strong>How was this form shared with parents/carers?</strong>
            <span id="howFormWasSharedData">{values?.howFormWasShared}</span></p>
        </div >

      </>
    );
  }, [values]);



  // Decision Key Points
  const [useOfForceNecessary, setUseOfForceNecessary] = useState([
    {
      id: 1,
      requirements: "Service User was placing themselves at risk",
      fullfil: values?.useOfForceNecessaryPoints1
    },
    {
      id: 2,
      requirements: "Service User was placing others at risk",
      fullfil: values?.useOfForceNecessaryPoints2
    },
    {
      id: 3,
      requirements: "Significant Damage to property",
      fullfil: values?.useOfForceNecessaryPoints3
    },
    {
      id: 4,
      requirements: "Illegal offence was being carried out",
      fullfil: values?.useOfForceNecessaryPoints4
    },
    {
      id: 5,
      requirements: "Other",
      fullfil: values?.useOfForceNecessaryPoints5
    },
  ]);

  useEffect(() => {
    setUseOfForceNecessary([
      {
        id: 1,
        requirements: "Service User was placing themselves at risk",
        fullfil: values?.useOfForceNecessaryPoints1
      },
      {
        id: 2,
        requirements: "Service User was placing others at risk",
        fullfil: values?.useOfForceNecessaryPoints2
      },
      {
        id: 3,
        requirements: "Significant Damage to property",
        fullfil: values?.useOfForceNecessaryPoints3
      },
      {
        id: 4,
        requirements: "Illegal offence was being carried out",
        fullfil: values?.useOfForceNecessaryPoints4
      },
      {
        id: 5,
        requirements: "Other",
        fullfil: values?.useOfForceNecessaryPoints5
      },
    ]);
  }, [values])
  console.log("🚀 ~ EditPhysicalIntervention ~ useOfForceNecessary:", useOfForceNecessary)
  const handleKeyPointsInputChange = (keyPoints, id, field, value, setKeyPoints, valuesArray) => {
    const updatedRows = keyPoints.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setKeyPoints(updatedRows);
    setFieldValue(valuesArray, value)
  };



  // parent contact check boxes list
  const [isParentContacted, setIsParentContacted] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);

  // parent contact check boxes list
  const [howFormWasShared, setHowFormWasShared] = useState([
    { id: 1, values: "Paper", label: "Paper", checked: false },
    { id: 2, values: "Email", label: "Email", checked: false },
    { id: 2, values: "Other", label: "Other", checked: false },
  ]);

  // how parent contact check boxes list
  const [howParentContacted, setHowParentContacted] = useState([
    { id: 1, values: "Call", label: "Call", checked: false },
    { id: 2, values: "Email", label: "Email", checked: false },
    { id: 2, values: "Other", label: "Other", checked: false },
    { id: 2, values: "Upon collection/drop off", label: "Upon collection/drop off", checked: false },
  ]);


  const handleBodyPartSelect = async (catId) => {
    setSelectedCategoryId(catId);
    // setReceivedSubCategory("no-child");
    setSelectedSubCategoryId("no-child");
    setFieldValue("category", catId);
    setFieldValue("subcategory", "no-child");
  };

  const handleSubCatValidationStatus = (status) => {
    // console.log("Sub Cat Validation Status (Parent):", status);
    setParentSubCatValidationStatus(status);
  };

  useEffect(() => {
    // console.log("Parent Sub Cat Validation Status 01:", parentSubCatValidationStatus);
  }, [parentSubCatValidationStatus]);

  // useEffect(() => {
  //   // setFieldValue("category", selectedCategoryId);
  //   if (!parentSubCatValidationStatus) {
  //     setFieldValue("subcategory", "no-child");
  //   }
  //   // else {
  //   //   setFieldValue("subcategory", "");
  //   // }
  // }, [selectedCategoryId, parentSubCatValidationStatus]);
  // console.log("🚀 ~ EditInjuryHealthIssue ~ parentSubCatValidationStatus:", parentSubCatValidationStatus)
  // console.log("🚀 ~ file: AddHealthIssues.jsx:112 ~ useEffect ~ selectedCategoryId:", selectedCategoryId)

  // const [receivedSubCategory, setReceivedSubCategory] = useState(null);
  // console.log("🚀 ~asfsdfdsfsdfry:", receivedSubCategory)

  const handleSubCategoryChange = (subCategoryValue) => {
    setSelectedSubCategoryId(subCategoryValue);
    setFieldValue("category", subCategoryValue?.value);
    setFieldValue("subcategory", subCategoryValue?.value);
    setFieldTouched("subcategory", false);
    // setSelectedCategoryId(subCategoryValue?.value);
    // setSelectedCategoryId(carePlanDetail?.healthIssueId?.category?.parentCategory?.id)
    // setSelectedSubCategoryId(carePlanDetail?.healthIssueId?.category?._id)
  };

  return (
    <>

      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit Physical Intervention note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={values?.addedby}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date / Time"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">
                      <Input
                        type="number"
                        id="durationOfIncidents"
                        name="durationOfIncidents"
                        value={values?.durationOfIncidents}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Duration of incident (Mins)"
                        customError={touched?.durationOfIncidents && errors?.durationOfIncidents}
                        errorMessage={errors?.durationOfIncidents}
                        erroClass={
                          touched?.durationOfIncidents && errors?.durationOfIncidents
                            ? "validationError"
                            : ""
                        }
                      /*requiredStar={true} */
                      />

                    </div>

                    <div className="col-md-6">
                      <Input
                        type="text"
                        id="staffDebriefFormNumber"
                        name="staffDebriefFormNumber"
                        value={values?.staffDebriefFormNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Staff Debrief Form Number"
                        customError={touched?.staffDebriefFormNumber && errors?.staffDebriefFormNumber}
                        errorMessage={errors?.staffDebriefFormNumber}
                        erroClass={
                          touched?.staffDebriefFormNumber && errors?.staffDebriefFormNumber
                            ? "validationError"
                            : ""
                        }
                      /*requiredStar={true} */
                      />

                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="nameOfWitnesses"
                        name="nameOfWitnesses"
                        value={values?.nameOfWitnesses}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Name of witnesses/adults present"
                        rows="3"
                        className="mb-0"
                        customError={touched?.nameOfWitnesses && errors?.nameOfWitnesses}
                        errorMessage={errors?.nameOfWitnesses}
                        erroClass={
                          touched?.nameOfWitnesses && errors?.nameOfWitnesses
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="placeOfIncident"
                        name="placeOfIncident"
                        value={values?.placeOfIncident}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Place incident occured"
                        rows="3"
                        className="mb-0"
                        customError={touched?.placeOfIncident && errors?.placeOfIncident}
                        errorMessage={errors?.placeOfIncident}
                        erroClass={
                          touched?.placeOfIncident && errors?.placeOfIncident
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="priorToIntervention"
                        name="priorToIntervention"
                        value={values?.priorToIntervention}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="What was used prior to intervention to defuse/deescalae the situation?"
                        rows="3"
                        className="mb-0"
                        customError={touched?.priorToIntervention && errors?.priorToIntervention}
                        errorMessage={errors?.priorToIntervention}
                        erroClass={
                          touched?.priorToIntervention && errors?.priorToIntervention
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="pbsFollowed"
                        name="pbsFollowed"
                        value={values?.pbsFollowed}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Was the PBS followed and was it sufficient enough to manage this incident?"
                        rows="3"
                        className="mb-0"
                        customError={touched?.pbsFollowed && errors?.pbsFollowed}
                        errorMessage={errors?.pbsFollowed}
                        erroClass={
                          touched?.pbsFollowed && errors?.pbsFollowed
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>


                    <div className="col-md-6">
                      <TextArea
                        id="reasonForPhysicalIntervention"
                        name="reasonForPhysicalIntervention"
                        value={values?.reasonForPhysicalIntervention}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Reason for physical intervention"
                        rows="3"
                        className="mb-0"
                        customError={touched?.reasonForPhysicalIntervention && errors?.reasonForPhysicalIntervention}
                        errorMessage={errors?.reasonForPhysicalIntervention}
                        erroClass={
                          touched?.reasonForPhysicalIntervention && errors?.reasonForPhysicalIntervention
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>


                    <div className="col-md-6">
                      <TextArea
                        id="staffInvolvedInPI"
                        name="staffInvolvedInPI"
                        value={values?.staffInvolvedInPI}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Staff involved in the physical intervention"
                        rows="3"
                        className="mb-0"
                        customError={touched?.staffInvolvedInPI && errors?.staffInvolvedInPI}
                        errorMessage={errors?.staffInvolvedInPI}
                        erroClass={
                          touched?.staffInvolvedInPI && errors?.staffInvolvedInPI
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>



                    <div className="col-md-6">
                      <TextArea
                        id="conditionOfSU"
                        name="conditionOfSU"
                        value={values?.conditionOfSU}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Condition of service user following the incident, including breathing monitoring"
                        rows="3"
                        className="mb-0"
                        customError={touched?.conditionOfSU && errors?.conditionOfSU}
                        errorMessage={errors?.conditionOfSU}
                        erroClass={
                          touched?.conditionOfSU && errors?.conditionOfSU
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>


                    <div className="col-md-6">
                      <TextArea
                        id="howSuCalmed"
                        name="howSuCalmed"
                        value={values?.howSuCalmed}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="How was the service user calmed?"
                        rows="3"
                        className="mb-0"
                        customError={touched?.howSuCalmed && errors?.howSuCalmed}
                        errorMessage={errors?.howSuCalmed}
                        erroClass={
                          touched?.howSuCalmed && errors?.howSuCalmed
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">
                      <p>
                        <strong>Why was the use of force necessary?</strong></p>
                      <div className="keyChecksWrapper">
                        <div className="keyPoints">
                          <div className="table-responsive">
                            <Table striped bordered hover className='dynamicRows'>
                              <tbody>
                                {useOfForceNecessary.map((row, index) => (
                                  <tr key={row.id}>
                                    <td>{row?.requirements}</td>
                                    <td>
                                      <Form.Check
                                        id={`useOfForceNecessaryPoints${row.id}`}
                                        name={`useOfForceNecessaryPoints${row.id}`}
                                        checked={row.fullfil}
                                        onChange={(e) => handleKeyPointsInputChange(useOfForceNecessary, row.id, 'fullfil', e.target.checked, setUseOfForceNecessary, `useOfForceNecessaryPoints${row.id}`)}

                                      // checked={row.fullfil}
                                      // onChange={(e) => handleKeyPointsInputChange(decisionKeyPoints, row.id, 'fullfil', e.target.checked, setDecisionKeyPoints, `decisionKeyPoints${row.id}`)}
                                      />
                                    </td>
                                  </tr>
                                ))}

                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>

                      <TextArea
                        id="pleaseExplain"
                        name="pleaseExplain"
                        value={values?.pleaseExplain}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText='If ticked "Other" please explain'
                        rows="3"
                        className="mb-0"
                        customError={touched?.pleaseExplain && errors?.pleaseExplain}
                        errorMessage={errors?.pleaseExplain}
                        erroClass={
                          touched?.pleaseExplain && errors?.pleaseExplain
                            ? "validationError"
                            : ""
                        }
                      /*requiredStar={true} */
                      />
                    </div>

                    <div className="col-md-12">
                      {
                        selectedCategoryId !== null && selectedSubCategoryId !== null && (
                          <HealthCategories
                            name="category"
                            onSelect={handleBodyPartSelect}
                            onSelectSubCat={handleSubCategoryChange}
                            selectedSubCategory={selectedSubCategoryId}
                            selectedCategoryId={selectedCategoryId}
                            // value={values?.category}
                            defaultSelectedCatValue={values?.category}
                            defaultSelectedSubCatValue={values?.subcategory}
                            filterBy={""}
                            isDisabled={false}
                            label="Select Category-"
                            sublabel="Select Subcategory"
                            subCatValidationStatus={true}
                            setSubCatValidationStatus={handleSubCatValidationStatus}
                            erroClassCategory={touched.category && errors.category ? "validationError" : ""}
                            customErrorCategory={touched.category && errors.category}
                            errorMessageCategory={touched.category && errors.category ? errors.category : ""}
                            customErrorSubcategory={touched.subcategory && errors.subcategory}
                            errorMessageSubcategory={touched.subcategory && errors.subcategory ? errors.subcategory : ""}
                            erroClassSubcategory={touched.subcategory && errors.subcategory ? "validationError" : ""}

                          />
                        )
                      }

                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="general m-2">Parent Contacted</label>
                      <div className="checkboxList spaceInCheckList">
                        {isParentContacted.map((row, index) => (
                          <>
                            <Form.Check
                              key={row.id}
                              name={"isParentContacted"}
                              type="radio"
                              id={row?.label}
                              label={row?.label}
                              value={row.values}
                              checked={values?.isParentContacted === row?.values ? true : false}
                              onChange={handleInputChangePrevFields}
                              onBlur={handleBlur}
                            />
                          </>
                        ))}

                      </div>
                    </div>

                    {values?.isParentContacted === "Yes" && (
                      <>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="nameOfParentContacted"
                            name="nameOfParentContacted"
                            value={values?.nameOfParentContacted}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Name of Parent Contacted"
                            customError={touched?.nameOfParentContacted && errors?.nameOfParentContacted}
                            errorMessage={errors?.nameOfParentContacted}
                            erroClass={
                              touched?.nameOfParentContacted && errors?.nameOfParentContacted
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>

                        <div className="col-md-6">
                          <Input
                            type="time"
                            id="parentContactedTime"
                            name="parentContactedTime"
                            value={values?.parentContactedTime}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Contact time"
                            customError={touched?.parentContactedTime && errors?.parentContactedTime}
                            errorMessage={errors?.parentContactedTime}
                            erroClass={
                              touched?.parentContactedTime && errors?.parentContactedTime
                                ? "validationError"
                                : ""
                            }
                          />



                        </div>

                        <div className="col-md-12">
                          <label className="general m-2">How parent was contacted</label>
                          <div className="checkboxList spaceInCheckList">
                            {howParentContacted.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name="howParentContacted"
                                  type="radio"
                                  id={row.label}
                                  label={row.label}
                                  value={row.values}
                                  checked={values?.howParentContacted === row?.values ? true : false}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>
                        </div>
                      </>
                    )}



                    <div className="col-md-12 mt-5">
                      <TextArea
                        id="parentCarersComments"
                        name="parentCarersComments"
                        value={values?.parentCarersComments}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Parent/carer’s comments"
                        rows="3"
                        className="mb-0"
                        customError={touched?.parentCarersComments && errors?.parentCarersComments}
                        errorMessage={errors?.parentCarersComments}
                        erroClass={
                          touched?.parentCarersComments && errors?.parentCarersComments
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="general m-2">How was this form shared with parents/carers?</label>
                      <div className="checkboxList spaceInCheckList">
                        {howFormWasShared.map((row, index) => (
                          <>
                            <Form.Check
                              key={row.id}
                              name={"howFormWasShared"}
                              type="radio"
                              id={row?.label}
                              label={row?.label}
                              value={row.values}
                              checked={values?.howFormWasShared === row?.values ? true : false}
                              onChange={handleInputChangePrevFields}
                              onBlur={handleBlur}
                            />
                          </>
                        ))}

                      </div>
                    </div>
                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      // onClick={subModalAddCarePlans}
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>
              {/* {ReactHtmlParser(String(carePlanDetail?.noteDetails))} */}
              <div className="referenceDiv">

                <div ref={durationOfIncidentsRef}></div>
                <div ref={staffDebriefFormNumberRef}></div>
                <div ref={nameOfWitnessesRef}></div>
                <div ref={placeOfIncidentRef}></div>
                <div ref={priorToInterventionRef}></div>
                <div ref={pbsFollowedRef}></div>
                <div ref={reasonForPhysicalInterventionRef}></div>
                <div ref={staffInvolvedInPIRef}></div>
                <div ref={conditionOfSURef}></div>
                <div ref={howSuCalmedRef}></div>
                <div ref={pleaseExplainRef}></div>
                <div ref={isParentContactedRef}></div>
                <div ref={howParentContactedRef}></div>
                <div ref={nameOfParentContactedRef}></div>
                <div ref={parentContactedTimeRef}></div>
                <div ref={parentCarersCommentsRef}></div>
                <div ref={howFormWasSharedRef}></div>
                <div ref={useOfForceNecessaryPoints1Ref}></div>
                <div ref={useOfForceNecessaryPoints2Ref}></div>
                <div ref={useOfForceNecessaryPoints3Ref}></div>
                <div ref={useOfForceNecessaryPoints4Ref}></div>
                <div ref={useOfForceNecessaryPoints5Ref}></div>


                {/* <div ref={decisionKeyPoints1Ref}></div>
                <div ref={decisionKeyPoints2Ref}></div>
                <div ref={decisionKeyPoints3Ref}></div>
                <div ref={causativeNexusPoints1Ref}></div>
                <div ref={causativeNexusPoints2Ref}></div> */}
              </div>
              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EditPhysicalIntervention