import React, { useEffect, useState } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move
} from "drag-n-drop-grid";
import Layout from "../../components/layouts/Layout";

import {
  BASE_URL,
  GET_RECRUITMENT_CANDIDATES_URL,
  POST_UPDATE_RECRUITMENT_CANDIDATE_URL,
  ADMIN_ID

} from "../../env";
import {
  getRecords,
  updateRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  catchAsync,
  calculateAgeDifference,
} from "../../helper/helper";
import actionButtonDel from "../../assets/images/icons/actionButtonDel.svg";
import actionButtonTick from "../../assets/images/icons/actionButtonTick.svg";
import onBoard from "../../assets/images/icons/onBoard.svg";
import loadingGif from "../../assets/images/icons/loadingGif.gif";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../components/general/ProgressComponent";
import password_eye_icon_circle from "../../assets/images/icons/password_eye_icon_circle.svg";
import ApplicationProcessPopup from "./ApplicationProcessPopup";
import moment from "moment";
import InterviewProcessPopup from "./model/InterviewProcessPopup";
import TextArea from "../../components/forms-fields/TextArea";
import DocSubmitProcessPopup from "./model/DocSubmitProcessPopup";
import DBSCProcessPopup from "./model/DBSCProcessPopup";
import ComplianceProcessPopup from "./model/ComplianceProcessPopup";
import OnBoardingProcessPopup from "./model/OnBoardingProcessPopup";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';

function RecruitmentApplicationProcess() {
  const [interviewUserData, setInterviewUserData] = useState([]);
  const [documentSubmitUserData, setDocumentSubmitUserData] = useState([]);
  const [DSBCUserData, setDSBCUserData] = useState([]);
  const [complianceUserData, setComplianceUserData] = useState([]);
  const [onBoardingUserData, setOnBoardingUserData] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isRecrUserData, setIsRecrUserData] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [recCandidateDetail, setRecCandidateDetail] = useState();
  const [showCandidateDetail, setShowCandidateDetail] = useState(false);
  const [largestArrayName, setLargestArrayName] = useState(null);
  const [largestArrayLength, setLargestArrayLength] = useState(0);
  const [archieveForm, setArchieveForm] = useState(false);
  const [archievedCommentBox, setArchievedCommentBox] = useState("");
  // console.log("🚀 ~ RecruitmentApplicationProcess ~ archievedCommentBox:", archievedCommentBox)
  const subModalViewCarePlans = (details) => {
    setRecCandidateDetail(details);
    setShowCandidateDetail(!showCandidateDetail);
    fetchAllRecords();
  };

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: 0,
    offset: 0,
    // limit: 10,
    query: ""
  };

  const [items, setItems] = useState({
    interview: [],
    documentSubmit: [],
    DBSC: [],
    compliance: [],
    onBoarding: []
  });

  function onChange(sourceColumn, sourceIndex, targetIndex, targetColumn) {
    if (targetColumn) {
      const result = move(
        items[sourceColumn],
        items[targetColumn],
        sourceIndex,
        targetIndex
      );
      const updatedItems = {
        ...items,
        [sourceColumn]: result[0],
        [targetColumn]: result[1]
      };
      setItems(updatedItems);

      const updatedItem = result[1][targetIndex];
      let newProcessStatus = targetColumn;
      const processStatusData = {
        staffId: ADMIN_ID,
        recruitId: updatedItem?._id,
        processStatus: newProcessStatus
      };

      updateProcessStatus(processStatusData);
    } else {
      const result = swap(items[sourceColumn], sourceIndex, targetIndex);
      setItems({
        ...items,
        [sourceColumn]: result
      });
    }
  }

  const updateProcessStatus = catchAsync(async (processStatusData) => {
    const response = await updateRecords(POST_UPDATE_RECRUITMENT_CANDIDATE_URL, processStatusData);
    console.log("🚀 ~ updateProcessStatus ~ response:", response)
    console.log("🚀 ~ processStatusData ~ processStatusData:", processStatusData)
    processUpdateRecords("postUpdateRecordsFormData", response);
  });

  const processUpdateRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsRecrUserData(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      console.log("Sdfsdfsf ", data?.message)
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        setIsRecrUserData(false)
      }, 2000)

    } else if (method === "postUpdateRecordsFormData" && data?.status === isSuccess) {
      setIsRecrUserData(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      fetchAllRecords();
      setTimeout(() => {
        setIsRecrUserData(false)
      }, 2000)
    }
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) return;

    if (method === "get") {
      const recCandidateDataRecord = data?.data?.recruitment?.filter(
        item => item?.status !== "archive" && !item?.isDeleted
      );

      const categorize = status =>
        recCandidateDataRecord?.filter(item => item?.processStatus === status);

      setInterviewUserData(categorize("interview"));
      setDocumentSubmitUserData(categorize("documentSubmit"));
      setDSBCUserData(categorize("DBSC"));
      setComplianceUserData(categorize("compliance"));
      setOnBoardingUserData(categorize("onBoarding"));

      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_RECRUITMENT_CANDIDATES_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    setItems({
      interview: interviewUserData,
      documentSubmit: documentSubmitUserData,
      DBSC: DSBCUserData,
      compliance: complianceUserData,
      onBoarding: onBoardingUserData
    });
  }, [interviewUserData, documentSubmitUserData, DSBCUserData, complianceUserData, onBoardingUserData]);

  useEffect(() => {
    const arrays = [
      { name: 'interviewUserData', data: interviewUserData },
      { name: 'documentSubmitUserData', data: documentSubmitUserData },
      { name: 'DSBCUserData', data: DSBCUserData },
      { name: 'complianceUserData', data: complianceUserData },
      { name: 'onBoardingUserData', data: onBoardingUserData }
    ];

    let maxArrayName = null;
    let maxLength = -1;

    arrays.forEach(({ name, data }) => {
      if (Array.isArray(data)) {
        const length = data.length;
        if (length > maxLength) {
          maxLength = length;
          maxArrayName = name;
        }
      } else {
        console.warn(`${name} is not defined or is not an array.`);
      }
    });

    if (maxArrayName !== largestArrayName || maxLength !== largestArrayLength) {
      setLargestArrayName(maxArrayName);
      setLargestArrayLength(maxLength);
    }
  }, [interviewUserData, documentSubmitUserData, DSBCUserData, complianceUserData, onBoardingUserData, largestArrayName, largestArrayLength]);

  useEffect(() => {
    const filterCandidates = (candidates) => {
      return candidates.filter(candidate =>
        candidate.firstName.toLowerCase().includes(filterSearch.toLowerCase()) ||
        candidate.lastName.toLowerCase().includes(filterSearch.toLowerCase())
      );
    };

    setItems({
      interview: filterCandidates(interviewUserData),
      documentSubmit: filterCandidates(documentSubmitUserData),
      DBSC: filterCandidates(DSBCUserData),
      compliance: filterCandidates(complianceUserData),
      onBoarding: filterCandidates(onBoardingUserData)
    });
  }, [filterSearch, interviewUserData, documentSubmitUserData, DSBCUserData, complianceUserData, onBoardingUserData]);

  const displayArchieveForm = () => {
    setArchieveForm(!archieveForm)
  }

  function archiveOnBoardCandidate(recruitId, processStage) {
    const processStatusData = {
      recruitId: recruitId,
      status: "archive",
      notes: [
        ...recCandidateDetail?.notes,
        ...(archievedCommentBox !== "" ? [{
          staffId: ADMIN_ID,
          note: archievedCommentBox,
          process: processStage + " - Archived",
        }] : [])
      ],
    };
    console.log("🚀 ~ archiveOnBoardCandidate ~ processStatusData:", processStatusData)
    setIsSubmitButtonClicked(true);
    updateProcessStatus(processStatusData);
    setTimeout(() => {
      setIsSubmitButtonClicked(false);
      setArchieveForm(false)
      setShowCandidateDetail(!showCandidateDetail);
    }, 500)

  }


  function completeOnBoarding(recruitId, processStage) {
    const processOnBoarding = {
      recruitId: recruitId,
      status: "archive",
      processStatus: processStage
    };
    console.log("🚀 ~ completeOnBoarding ~ completeOnBoarding:", processOnBoarding)
    setIsSubmitButtonClicked(true);
    updateProcessStatus(processOnBoarding);
    setTimeout(() => {
      setIsSubmitButtonClicked(false);
      setArchieveForm(false)
      setShowCandidateDetail(false);
    }, 500)

  }

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Recruitment <small>Application Process</small>
          </>
        }
      >
        <div className="applicationProcessWrapper">
          <div className="mainContent rowClickUnset">
            <div className="dataTableWrapper">
              <div className="tableTitle">
                <h3>Application Process</h3>
                <div className="searchContainer">
                  <div className="searchTextBox">
                    <input
                      type="text"
                      placeholder="Search Candidate"
                      value={filterSearch}
                      onChange={(e) => setFilterSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {isLoading ? (
                <ProgressComponent />
              ) : (
                <>
                  <div className="table-responsive">
                    <GridContextProvider onChange={onChange}>
                      <div className="rowRecruitment">
                        <div className="recColumn">
                          <header>Interview</header>
                          <GridDropZone
                            className="dropzone interview"
                            style={{ minHeight: `${largestArrayLength * 180 + 20}px` }}
                            id="interview"
                            boxesPerRow={1}
                            rowHeight={180}
                          >
                            {items?.interview?.map(item => (
                              <GridItem key={item?._id}>
                                <div className="grid-item">
                                  <div className="grid-item-content">
                                    <header className="gridContent">
                                      <span className="dpImage"> <img src={`${BASE_URL + item?.dp}`} alt={`${item?.firstName}`} /></span>
                                      <span className="edit" onClick={() => {
                                        subModalViewCarePlans(item);
                                      }}>
                                        <img src={password_eye_icon_circle} alt="View" title="View" />
                                      </span>
                                    </header>

                                    <div className="userInfo">

                                      <span className="nameBlock">{item?.firstName}&nbsp;{item?.lastName}</span>
                                      <span className="infoBlock">
                                        {`${calculateAgeDifference(item?.dob)}`} Yrs |{" "}
                                        {`${item?.gender} | ${item?.phone}`}
                                      </span>
                                      <span className="emailBlock"><a href={`mailto:${item?.email}`}>{`${item?.email}`}</a></span>
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            ))}
                          </GridDropZone>
                        </div>
                        <div className="recColumn">
                          <header>Document Submit</header>
                          <GridDropZone
                            className="dropzone documentSubmit"
                            style={{ minHeight: `${largestArrayLength * 180 + 20}px` }}
                            id="documentSubmit"
                            boxesPerRow={1}
                            rowHeight={180}
                          >
                            {items?.documentSubmit?.map(item => (
                              <GridItem key={item?._id}>
                                <div className="grid-item">
                                  <div className="grid-item-content">
                                    <header className="gridContent">
                                      <span className="dpImage"> <img src={`${BASE_URL + item?.dp}`} alt={`${item?.firstName}`} /></span>
                                      <span className="edit" onClick={() => {
                                        subModalViewCarePlans(item);
                                      }}>
                                        <img src={password_eye_icon_circle} alt="View" title="View" />
                                      </span>
                                    </header>

                                    <div className="userInfo">

                                      <span className="nameBlock">{item?.firstName}&nbsp;{item?.lastName}</span>
                                      <span className="infoBlock">
                                        {`${calculateAgeDifference(item?.dob)}`} Yrs |{" "}
                                        {`${item?.gender} | ${item?.phone}`}
                                      </span>
                                      <span className="emailBlock"><a href={`mailto:${item?.email}`}>{`${item?.email}`}</a></span>
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            ))}
                          </GridDropZone>
                        </div>
                        <div className="recColumn">
                          <header>DBS</header>
                          <GridDropZone
                            className="dropzone DBSC"
                            style={{ minHeight: `${largestArrayLength * 180 + 20}px` }}
                            id="DBSC"
                            boxesPerRow={1}
                            rowHeight={180}
                          >
                            {items?.DBSC?.map(item => (
                              <GridItem key={item?._id}>
                                <div className="grid-item">
                                  <div className="grid-item-content">
                                    <header className="gridContent">
                                      <span className="dpImage"> <img src={`${BASE_URL + item?.dp}`} alt={`${item?.firstName}`} /></span>
                                      <span className="edit" onClick={() => {
                                        subModalViewCarePlans(item);
                                      }}>
                                        <img src={password_eye_icon_circle} alt="View" title="View" />
                                      </span>
                                    </header>

                                    <div className="userInfo">

                                      <span className="nameBlock">{item?.firstName}&nbsp;{item?.lastName}</span>
                                      <span className="infoBlock">
                                        {`${calculateAgeDifference(item?.dob)}`} Yrs |{" "}
                                        {`${item?.gender} | ${item?.phone}`}
                                      </span>
                                      <span className="emailBlock"><a href={`mailto:${item?.email}`}>{`${item?.email}`}</a></span>
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            ))}
                          </GridDropZone>
                        </div>
                        <div className="recColumn">
                          <header>Compliance</header>
                          <GridDropZone
                            className="dropzone compliance"
                            style={{ minHeight: `${largestArrayLength * 180 + 20}px` }}
                            id="compliance"
                            boxesPerRow={1}
                            rowHeight={180}
                          >
                            {items?.compliance?.map(item => (
                              <GridItem key={item?._id}>
                                <div className="grid-item">
                                  <div className="grid-item-content">
                                    <header className="gridContent">
                                      <span className="dpImage"> <img src={`${BASE_URL + item?.dp}`} alt={`${item?.firstName}`} /></span>
                                      <span className="edit" onClick={() => {
                                        subModalViewCarePlans(item);
                                      }}>
                                        <img src={password_eye_icon_circle} alt="View" title="View" />
                                      </span>
                                    </header>

                                    <div className="userInfo">

                                      <span className="nameBlock">{item?.firstName}&nbsp;{item?.lastName}</span>
                                      <span className="infoBlock">
                                        {`${calculateAgeDifference(item?.dob)}`} Yrs |{" "}
                                        {`${item?.gender} | ${item?.phone}`}
                                      </span>
                                      <span className="emailBlock"><a href={`mailto:${item?.email}`}>{`${item?.email}`}</a></span>
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            ))}
                          </GridDropZone>
                        </div>
                        <div className="recColumn">
                          <header>On Boarding</header>
                          <GridDropZone
                            className="dropzone onBoarding"
                            style={{ minHeight: `${largestArrayLength * 180 + 20}px` }}
                            id="onBoarding"
                            boxesPerRow={1}
                            rowHeight={180}
                          >
                            {items?.onBoarding?.map(item => (
                              <GridItem key={item?._id}>
                                <div className="grid-item">
                                  <div className="grid-item-content">
                                    <header className="gridContent">
                                      <span className="dpImage"> <img src={`${BASE_URL + item?.dp}`} alt={`${item?.firstName}`} /></span>
                                      <div className="actionIconsButtons">

                                        {item?.inductionDate && (
                                          <>

                                            {isSubmitButtonClicked ? (
                                              <span
                                                className="disabledOnClickButton"
                                              >
                                                <img src={onBoard} alt={onBoard} />
                                              </span>
                                            ) : (
                                              <span className="completeOnBoard" onClick={() => completeOnBoarding(item?._id, "onboardingComplete")}>
                                                {/* onboardingComplete */}
                                                <img src={onBoard} alt={onBoard} />
                                              </span>
                                            )}
                                          </>
                                        )}

                                        <span className="edit" onClick={() => {
                                          subModalViewCarePlans(item);
                                        }}>
                                          <img src={password_eye_icon_circle} alt="View" title="View" />
                                        </span>
                                      </div>
                                    </header>

                                    <div className="userInfo">

                                      <span className="nameBlock">{item?.firstName}&nbsp;{item?.lastName}</span>
                                      <span className="infoBlock">
                                        {`${calculateAgeDifference(item?.dob)}`} Yrs |{" "}
                                        {`${item?.gender} | ${item?.phone}`}
                                      </span>
                                      <span className="emailBlock"><a href={`mailto:${item?.email}`}>{`${item?.email}`}</a></span>
                                    </div>


                                  </div>
                                </div>
                              </GridItem>
                            ))}
                          </GridDropZone>
                        </div>
                      </div>
                    </GridContextProvider>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
      {isRecrUserData && <ToastContainer />}
      {/* Recruitment Application Process Detail Popup */}
      <Modal
        show={showCandidateDetail}
        onHide={subModalViewCarePlans}
        className="ftcModalPopup"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Candidate Detail - <span className="capitalizeTransform">{recCandidateDetail?.processStatus.replace(/([a-z])([A-Z])/g, '$1 $2')}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body className="candidateDetailPopup">

          <div className="row">
            <div className="col-md-12">
              <div className="text-right archiveTheUserButton">
                {isSubmitButtonClicked ? (
                  <Button
                    // onClick={subModalAddCarePlans}
                    className="btn-sm btn-danger"
                    variant="danger"
                    disabled
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button variant="danger" className="btn btn-danger btn-sm" onClick={displayArchieveForm}>
                    Archive the User
                  </Button>
                )}

                {
                  archieveForm && (
                    <>
                      <div className="archievedCommentBox">
                        <TextArea
                          name="archievedCommentBox"
                          value={archievedCommentBox}
                          onChange={(e) => { setArchievedCommentBox(e.target.value) }}
                          // onBlur=""
                          cols={5}
                          rows={5}
                          labelText="Notes"
                        />
                        <div className="formActionButtons">
                          {isSubmitButtonClicked ? (
                            <Button
                              onClick=""
                              className="btn-sm"
                              variant="primary"
                              disabled
                            >
                              Loading...
                            </Button>
                          ) : (
                            <Button className="btn-sm" variant="primary" type="submit" onClick={() => archiveOnBoardCandidate(recCandidateDetail?._id, recCandidateDetail?.processStatus)} >
                              Save
                            </Button>
                          )}
                          <Button className="btn-sm" variant="outline-primary" onClick={displayArchieveForm}>
                            Close
                          </Button>

                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>

          <header>
            <span className="dpImage"> <img src={`${BASE_URL + recCandidateDetail?.dp}`} alt={`${recCandidateDetail?.firstName}`} /></span>
            <div className="userInfo">
              <span className="nameBlock">
                {recCandidateDetail?.firstName}&nbsp;{recCandidateDetail?.lastName}
                <small className="text-danger">Process Status:&nbsp;
                  {recCandidateDetail?.processStatus.replace(/([a-z])([A-Z])/g, '$1 $2')}
                </small>
              </span>
              <span className="infoBlock">
                {`${calculateAgeDifference(recCandidateDetail?.dob)}`} Yrs |{" "}
                {`${recCandidateDetail?.gender} | ${recCandidateDetail?.phone}`}
              </span>
              <span className="emailBlock"><a href={`mailto:${recCandidateDetail?.email}`}>{`${recCandidateDetail?.email}`}</a></span>
            </div>
          </header>
          <main>
            {
              recCandidateDetail?.processStatus === 'onBoarding' && (
                recCandidateDetail?.inductionDate && (
                  <>
                    <div className="alert alert-success">
                      <div className="row">
                        <div className="col-md-12">
                          <p><strong>Induction Date:</strong> {moment(recCandidateDetail?.inductionDate).format("DD/MM/YYYY")}</p>
                          {
                            recCandidateDetail?.trainingCompletion && (
                              <>
                                <p className="text-success m-0">Confirmation of completion of online training</p>
                              </>
                            )
                          }
                        </div>

                        <div className="col-md-12 mt-3">
                          {isSubmitButtonClicked ? (
                            <Button
                              // onClick={subModalAddCarePlans}
                              variant="success" className="btn-sm"
                              disabled
                            >
                              Loading...
                            </Button>
                          ) : (
                            <Button variant="success" className="btn-sm" onClick={() => completeOnBoarding(recCandidateDetail?._id, "onboardingComplete")}>
                              {/* onboardingComplete */}
                              Click here to complete the onboarding
                            </Button>
                          )}
                        </div>

                      </div>

                    </div>

                  </>
                )
              )
            }
            <div className="row">
              <div className="col-md-6">
                <p>
                  <strong>Date Of Birth</strong>
                  <span>{moment(recCandidateDetail?.dob).format("DD/MM/YYYY")}</span>
                </p>
              </div>
              <div className="col-md-6"> <p>
                <strong>Phone</strong>
                <span>{recCandidateDetail?.phone}</span>
              </p></div>
              <div className="col-md-6"> <p>
                <strong>Gender</strong>
                <span>{recCandidateDetail?.gender}</span>
              </p></div>
              <div className="col-md-6"> <p>
                <strong>Status</strong>
                <span>{recCandidateDetail?.status}</span>
              </p></div>
              <div className="col-md-12"> <p>
                <strong>Other Details</strong>
                <span>{recCandidateDetail?.otherDetails}</span>
              </p></div>



            </div>


            {
              recCandidateDetail?.notes.length > 0 && (
                <>
                  <div className="notesWrapper">
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          <strong>Notes History</strong>
                        </p>
                      </div>
                    </div>
                    {
                      recCandidateDetail?.notes
                        ?.slice()
                        .sort((a, b) => (a.process > b.process ? 1 : -1))
                        .map((item, index) => (
                          <div className="notesContent">
                            <p>
                              <strong>Comment by: {item?.staffId?.name}</strong>
                              <span>Note Detail: {item?.note}</span>
                              <small>Process Status: {item?.process.replace(/([a-z])([A-Z])/g, '$1 $2')}</small>
                            </p>
                          </div>
                        ))
                    }
                    {
                      recCandidateDetail?.processStatus === 'DBSC' && (
                        <>
                          {
                            recCandidateDetail?.isDbscChecked && (
                              <>
                                <p className="text-success">All training has been sent to the candidate </p>
                              </>
                            )
                          }
                        </>
                      )

                    }
                    {
                      recCandidateDetail?.processStatus === 'compliance' && (
                        <>
                          {
                            recCandidateDetail?.trainingCompletedPercent && (
                              <>
                                <p className="text-success">50% of online training has been completed</p>
                              </>
                            )
                          }
                        </>
                      )

                    }
                  </div>
                </>
              )
            }


          </main>
          {
            !archieveForm && (

              // Interview stage
              recCandidateDetail?.processStatus === 'interview' ? (
                <InterviewProcessPopup recCandidateDetail={recCandidateDetail} subModalViewCarePlans={subModalViewCarePlans} />
              ) : recCandidateDetail?.processStatus === 'documentSubmit' ? (
                <DocSubmitProcessPopup recCandidateDetail={recCandidateDetail} subModalViewCarePlans={subModalViewCarePlans} />
              ) : recCandidateDetail?.processStatus === 'DBSC' ? (
                <DBSCProcessPopup recCandidateDetail={recCandidateDetail} subModalViewCarePlans={subModalViewCarePlans} />
              ) : recCandidateDetail?.processStatus === 'compliance' ? (
                <ComplianceProcessPopup recCandidateDetail={recCandidateDetail} subModalViewCarePlans={subModalViewCarePlans} />
              ) : recCandidateDetail?.processStatus === 'onBoarding' && (
                <OnBoardingProcessPopup recCandidateDetail={recCandidateDetail} subModalViewCarePlans={subModalViewCarePlans} />
              )




            )
          }

        </Modal.Body >
      </Modal >
      {isRecrUserData && <ToastContainer />}
    </>
  );
}

export default RecruitmentApplicationProcess;
