import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const isAuthenticated = !!localStorage.getItem('token'); // Check token in local storage
  const userLoggedInDetails = JSON.parse(localStorage.getItem('userLoggedInDetails'));
  const roles = userLoggedInDetails?.staffRoles || [];
  const hasRole = (role) => roles?.includes(role);
  if (roles.length === 0 || hasRole('staffuser') || hasRole('')) {
    console.log("User has the staffuser role.");
    return <Navigate to="/restricted-web-access" />
  }

  return (
    isAuthenticated ? element : <Navigate to="/" />

  );
};

export default PrivateRoute;
