import React, { useEffect } from "react";
import ButtonLoading from "../general/ButtonLoading"; // Import ButtonLoading
import { useNavigate } from "react-router-dom";
function LoginForm({
  ftcLogo,
  errorStar,
  username,
  setUsername,
  password,
  setPassword,
  errorMessage,
  loading,
  handleLogin,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    const userLoggedInDetails = localStorage.getItem("userLoggedInDetails");
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const staffRoles = localStorage.getItem("staffRoles");

    if (userLoggedInDetails && token && userId && staffRoles) {
      navigate("/staff-overview");
    }
  }, [navigate]);

  return (
    <div className="login-form">
      <img src={ftcLogo} alt="FTC Logo" className="FTCLogo" />
      <h3>Login</h3>
      <form action="/">
        <div className={`form-group ${errorStar ? "validationError" : null}`}>
          <input
            // style={{ color: errorStar ? "red" : "#3c4549" }}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor="username" className="floatingLabel">
            Enter username or email
          </label>
        </div>

        <div className={`form-group ${errorStar ? "validationError" : null}`}>
          <input
            // style={{ color: errorStar ? "red" : "#3c4549" }}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="password" className="floatingLabel">
            Enter your password
          </label>
        </div>
        {errorMessage ? (
          <div className="form-group">
            <div className="" style={{ width: "max-content", color: "red" }}>
              {errorMessage}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="form-group">
          <button className="button" onClick={(event) => handleLogin(event)}>
            {loading && <ButtonLoading />} Sign in
          </button>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
