import React, { useEffect, useState } from "react";
import { Tab, Nav, Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import editGrid from "../../assets/images/icons/editGrid.svg";
import Input from "../../components/forms-fields/Input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getRecords,
  updateRecords,
  isSuccess,
  catchAsync,
  formateDate,
  filterColumns,
  filterRowsByProperty,
  toastErrorMessages,
  toastSuccessMessages,
  updateFormFields,
} from "../../helper/helper";
import {
  BASE_URL,
  GET_STAFF_WORKLOADS_URL,
  PUT_STAFF_WORKLOADS_URL,
} from "../../env";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import useColumnsWithPermission from "../../permissions/useColumnsWithPermission";
import moment from "moment/moment";
import AddStaffWorkLoad from "./AddStaffWorkLoad";

function StaffWorkLoads() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [staffName, setStaffName] = useState(false);
  const [isToastStaffWorkLoad, setIsToastStaffWorkLoad] = useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      staffMemberFields: "staffName",
      usersFields: "name profile_picture_url",
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const validationSchema = Yup.object().shape({
    holidayAlwnNoOfDys: Yup.number().required("Allowed Days is required"),
    holidayAlwnNoOfHours: Yup.number().required("Allowed Hours is required"),
    holidayHoursRemaining: Yup.number().required("Remaining Hours is required"),
    carriedOverHours: Yup.number().required("Carried Over Hours is required"),
  });

  const formikFormData = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      staffWorkLoadid: "",
      holidayAlwnNoOfDys: "",
      holidayAlwnNoOfHours: "",
      holidayHoursRemaining: "",
      holidaysRemaining: "",
      carriedOverHours: "",
      isCurrentWrkLd: true,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log("🚀 ~ onSubmit: ~ values:", values)
        await updateWorkload(values);
        handleCloseUpdateModel();
        resetForm();
      } catch (error) {
        console.error("Submission error:", error);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
    resetForm,
  } = formikFormData;

  const handleUpdateButtonClick = (row) => {
    setValues({
      startDate: row?.startDate,
      endDate: row?.endDate,
      staffWorkLoadid: row?._id,
      holidayAlwnNoOfDys: row?.holidayAlwnNoOfDys,
      holidayAlwnNoOfHours: row?.holidayAlwnNoOfHours,
      holidayHoursRemaining: row?.holidayHoursRemaining,
      holidaysRemaining: row?.holidaysRemaining,
      carriedOverHours: row?.carriedOverHours,
      isCurrentWrkLd: true,
    });
    setStaffName(row?.staffMember?.user?.name);
    setShowUpdateModal(true);
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastStaffWorkLoad(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const userData = data?.data?.staffWorkLoads || [];
      setUserData(userData);
      setIsLoading(false);
    } else if (method === "update" && data?.status === isSuccess) {
      setIsToastStaffWorkLoad(true);
      fetchAllRecords();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    try {
      const response = await getRecords(GET_STAFF_WORKLOADS_URL, apiQuery);
      processFetchRecords("get", response);
    } catch (error) {
      console.error("Error fetching staff workloads:", error);
    }
  });

  const updateWorkload = catchAsync(async (req, res) => {
    try {
      const saveResult = await updateRecords(PUT_STAFF_WORKLOADS_URL, req);
      processFetchRecords("update", saveResult);
    } catch (error) {
      console.error("Error updating staff workload:", error);
    }
  });

  const handleShowUpdateModal = (e, row) => {
    e.preventDefault();
    handleUpdateButtonClick(row);
  };

  const handleCloseUpdateModel = () => {
    setStaffName(false);
    setShowUpdateModal(false);
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => <span>{index + 1}</span>,
      width: "5%",
      center: true,
    },
    {
      name: "Staff Name",
      selector: (row) => {
        return (
          <span className="usersNameInfo">
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.staffMember?.user?.profile_picture_url}`}
                alt={row?.staffMember?.name}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{row?.staffMember?.user?.name}</span>
            </span>
          </span>
        );
      },

    },
    {
      name: "Start Date",
      selector: (row) => {
        return <span>{formateDate(row?.startDate, "m/d/y")}</span>;
      },
      center: true,
      width: "12%",
    },
    {
      name: "End Date",
      selector: (row) => {
        return <span>{formateDate(row?.endDate, "m/d/y")}</span>;
      },
      center: true,
      width: "12%",
    },
    // {
    //   name: "Allowed Days",
    //   selector: (row) => {
    //     return <span>{row?.holidayAlwnNoOfDys}</span>;
    //   },
    //   center: true,
    //   width: "11%",
    // },
    {
      name: "Allowed Hours",
      selector: (row) => {
        return <span>{row?.holidayAlwnNoOfHours}</span>;
      },
      center: true,
      width: "10%",
    },
    {
      name: "Availed Hours",
      selector: (row) => {
        return <span>{row?.holidayHoursAvailed}</span>;
      },
      center: true,
      width: "10%",
    },
    {
      name: "Remain Hours",
      selector: (row) => {
        return <span>{row?.holidayHoursRemaining}</span>;
      },
      center: true,
      width: "10%",
    },
    {
      name: "Carried Hours",
      selector: (row) => {
        return <span>{row?.carriedOverHours}</span>;
      },
      center: true,
      width: "10%",
    },

  ];


  const filteredColumns = filterColumns(columns, (column) => column !== null);
  const filteredRows = filterRowsByProperty(
    userData,
    (row) => row?.staffMember?.user?.name
  );


  // Function to calculate the difference in days
  const calculateDaysDifference = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const diff = end.diff(start, 'days');
    // setFieldValue("numberOfDays", diff);
    return diff + 1; // Difference in days
  };

  useEffect(() => {
    if (values.startDate && values.endDate) {
      const daysDiff = calculateDaysDifference(
        values.startDate,
        values.endDate
      );
      setFieldValue("numberOfDays", daysDiff);
    }
  }, [values.startDate, values.endDate, setFieldValue]);


  useEffect(() => {
    if (userData[0]?.staffMember?.contractedHours?.contractedHours && values?.numberOfDays) {
      const holidayHours = userData[0]?.staffMember?.contractedHours?.contractedHours * 5.6 / 365 * values?.numberOfDays + values?.carriedOverHours;
      setFieldValue("holidayAlwnNoOfHours", holidayHours);
      setFieldValue("holidayHoursRemaining", holidayHours);
      // setFieldValue("holidayHoursRemaining", holidayHours);
    }

  }, [userData[0]?.staffMember?.contractedHours?.contractedHours, values?.numberOfDays, values?.carriedOverHours])

  // Modal Popup
  const [showAddWorkLoad, setShowAddWorkLoad] = useState(false);
  const subModalAddWorkLoad = () => {
    setShowAddWorkLoad(!showAddWorkLoad);
    fetchAllRecords();
  };

  return (
    <>
      <div className="mainContent rowClickUnset absenceLeave">
        <div className="staffWorkLoadHeader">
          <h3>Staff Workloads</h3>

        </div>
        <div className="dataTableWrapper">
          <DataTable
            className="maxWidthTable minWidthTable"
            columns={filteredColumns}
            data={filteredRows}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="470px"
            highlightOnHover
            responsive={true}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
          />
        </div>
      </div>
      {isToastStaffWorkLoad && <ToastContainer />}
    </>
  );
}

export default StaffWorkLoads;
